import React from "react";
import { Form, Row, Col, Input, Select } from "antd";

import Tip from "~/components/Tip";

const { Option } = Select;

export const Details = ({
    data,
    form
}) => {
    const { getFieldDecorator } = form;

    return (
        <>
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item label={<span>Nome <Tip>De um nome para este formulário, ele será utilizado para você identificar este formulário. Visitantes não verão este nome.</Tip></span>} required>
                        {getFieldDecorator("name", {
                        initialValue: data ? data.name : "",
                        rules: [{ required: true, message: "Por favor, informe um nome para o formulário!" }],
                        })(<Input placeholder="Nome do formulário" />)}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Tipo de formulário" required>
                        {getFieldDecorator("type", {
                        initialValue: data ? data.type : "form1",
                        rules: [{ required: true, message: "Por favor, informe o tipo do formulário!" }],
                        })(
                        <Select>
                            {/* <Option value="form1">Básico <Tip>Nome, Email e Whatsapp</Tip></Option>
                            <Option value="form2">Reunião <Tip>Nome, Email, Whatsapp e Data</Tip></Option> */}
                            <Option value="chatbot">Chatbot <Tip>Você pode definir os campos que deseja coletar.</Tip></Option>
                        </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default Details;