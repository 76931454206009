/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Layout,
  Button,
  Icon
} from "antd";
import { useSelector } from "react-redux";

import { Auth } from "./Auth";
import logo from "./images/icon.png";
import logo2 from "./images/contlabs_white.png";

import "./layout.css";
import { getSubdomain } from "~/helpers/getSubdomain";
import { ROUTES } from "~/constants/routes.constants";
import Version from './Version';
import OnboardingProgressBar from '~/_shared/components/OnboardingProgressBar';
import MainMenu from '~/_shared/components/MainMenu';
import TopMenu from '~/_shared/components/TopMenu';
import { useImpersonate } from '~/context/Impersonate';
import { FaWhatsapp } from 'react-icons/fa';

const { Header, Content, Sider } = Layout;

const Main = ({ children }) => {
  const location = useLocation();
  const { isImpersonating, getPreviousUserName, revertImpersonation } = useImpersonate();

  const {
    user,
  } = useSelector((state) => ({
    user: state.user,
  }));

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const isPremiumPlan = user.tenant.plan === 'premium';

  useEffect(() => {
    if (
      location.search.includes("?file=") &&
      (location.pathname === ROUTES["/"] ||
        location.pathname === ROUTES["/login"])
    ) {
      return (window.location.href = `http://${getSubdomain()}.${
        process.env.REACT_APP_CUSTOMER_DOMAIN
      }/cliente/${location.search}`);
    }
  }, []);

  function onCollapse(collapsed) {
    setIsCollapsed(collapsed);
  }

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <Auth>
      {user.permissions && (
        <Layout style={{ height: "100vh", overflowY: "auto" }}>
          <Sider collapsible collapsed={isCollapsed} onCollapse={onCollapse} trigger={null} width={250} style={{ overflow: "auto", height: "100vh", position: "fixed", left: 0, transition: "left 0.3s" }}>
            <center style={{ backgroundColor: "#092138" }}>
              {isCollapsed ? (
                <img src={logo} className="logo" alt="ContLabs" style={{ height: "30px", margin: "16px" }} />
              ) : (
                <div style={{ position: 'relative' }}>
                  <img src={logo2} className="logo" alt="ContLabs" style={{ height: "30px", margin: "15px" }} />
                </div>
              )}
            </center>
            <MainMenu location={location} isCollapsed={isCollapsed} />
            <div className="whatsapp-support-button-container">
              <Button type="primary" target="_blank" href="https://api.whatsapp.com/send?phone=5548991191788" className="whatsapp-support-button">
                <FaWhatsapp />{" "}
                {!isCollapsed && "Suporte"}
              </Button>
              <Version />
            </div>
          </Sider>
          <Layout style={{ marginLeft: `${isCollapsed ? "80px" : "250px"}`, transition: "margin-left 0.3s" }}>
          {isImpersonating() && (
            <div style={{ backgroundColor: 'red', color: 'white', textAlign: 'center', padding: '5px' }}>
              Você está personificando. <a onClick={revertImpersonation} style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>Voltar para {getPreviousUserName()}</a>
            </div>
          )}
            <Header id="header-main" style={{ background: "#fff", padding: 0, boxShadow: `0px 1px 8px 0px rgba(0,0,0,0.1)`, zIndex: `989`, transition: "height 0.3s", display: "flex", justifyContent: "space-between" }}>
              <div>
                <Icon className="trigger" type={isCollapsed ? "menu-unfold" : "menu-fold"} onClick={toggleCollapse} />
              </div>
              <TopMenu user={user} isDropdownVisible={isDropdownVisible} setIsDropdownVisible={setIsDropdownVisible} />
            </Header>
            <Content style={{ margin: `0px 16px` }}>
              <OnboardingProgressBar />
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
    </Auth>
  );
};

export default Main;
