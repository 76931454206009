import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import { table } from './tableColumns';
import { get } from 'lodash';
import * as actions from '../actions';
import { useDispatch } from 'react-redux';

export const LeadsTable = ({
    showDrawer,
    data,
    isLoading,
    columns,
}) => {
    if (!data) return null;

    const dispatch = useDispatch();

    const handleRevised = (value, data) => {
        dispatch(actions.update(get(data, 'id'), {
            ...data,
            additional_data: {
                ...get(data, 'additional_data', {}),
                is_revised: value,
            },
        }));
    }

    const tableColumns = table.getColumns({ columns, showDrawer, handleRevised });
    const columnsWidth = tableColumns.reduce((acc, column) => acc + column.width, 0);

    return (
        <div>
            <div className={`table-clabs table-clabs--less-padding`}>
                <Table 
                    loading={isLoading}
                    tableLayout="fixed"
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.customers
                    })}
                    bordered={true}
                    rowClassName={(record) => record.is_revised ? 'row-clabs row-green' : 'row-clabs'}
                    columns={tableColumns}
                    dataSource={data}
                    rowKey="id"
                    scroll={{ x: columnsWidth, y: `${window.innerHeight-175}px`}}
                />
            </div>
        </div>
    );
}

LeadsTable.propTypes = {
    showDrawer: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};