import moment from 'moment';

/**
 * Groups tasks into categories: delayed, pending and completed
 * @param {Array} tasks - Array of task objects
 * @returns {Object} Object containing grouped tasks
 */
export const groupTasksByStatus = (tasks) => {
  if (!tasks || !tasks.length) return { delayed: [], pending: [], completed: [] };
  
  return tasks.reduce((acc, task) => {
    const isDone = task.pendent_tasks_count === 0;
    const isDelayed = moment().diff(task.due_date, "days") > 0 && !isDone;
    
    if (isDone) {
      acc.completed.push(task);
    } else if (isDelayed) {
      acc.delayed.push(task);
    } else {
      acc.pending.push(task);
    }
    
    return acc;
  }, { delayed: [], pending: [], completed: [] });
};

/**
 * Configuration for each task group with its colors and titles
 */
export const taskGroupConfig = {
  delayed: {
    title: 'Atrasadas',
    color: '#f5222d',
    bgColor: '#fff1f0'
  },
  pending: {
    title: 'Pendentes',
    color: '#faad14',
    bgColor: '#fffbe6'
  },
  completed: {
    title: 'Concluídas',
    color: '#52c41a',
    bgColor: '#f6ffed'
  }
};
