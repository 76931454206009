import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip, Switch } from 'antd';
import { WhatsappIcon, phoneMask } from '~/icons/WhatsappIcon';
import moment from 'moment';
import { get } from 'lodash';

import { removeCountryCodeFromPhone } from '~/utils/formatters';
import { copyToClipBoard } from '~/utils/copy-to-clipboard';
import { cadenceOptions, statusOptions } from '../Drawer/Cadence/helpers';

const WhatsappLink = styled.a`
  > img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

const CadenceStep = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 3px;
  background-color: ${({ status }) => {
    if (status === 'done') return 'green';
    if (status === 'canceled') return 'red';
    return 'gray';
  }};
  border-radius: 5%;
`;

const CadenceRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

const renderCreatedAt = (created_at) => moment(created_at).format('DD/MM/YYYY');

const renderPhone = (phone, customer) => {
  if (!phone) return '';
  const p = typeof phone === 'string' ? removeCountryCodeFromPhone(phone).replace(/\D/g, '') : ''; 
  return (
    <div style={{textAlign: `center`}}>
      {p && (
        <>
          <Tooltip title="Abrir no Whatsapp">
            <WhatsappLink
              href={`https://api.whatsapp.com/send?phone=55${p}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsappIcon />
            </WhatsappLink> 
          </Tooltip>{' '}
          <Tooltip title={'Clique para copiar'}>
            <span onClick={() => copyToClipBoard(p)} style={{cursor: 'pointer'}}>
              {phoneMask(p)}
            </span>
          </Tooltip>
        </>
      )}
    </div>
  );
};

const renderColumnId = (column_id, columns) => {
  const column = columns.find(c => c.id === column_id);
  return column ? (
    <span style={{ color: column.style.color }}>
      {column.name}
    </span>
  ) : '';
};

export const renderCadence = (cadences) => {
  if (!cadences) return '';
  return cadences.map((row, key) => (
    <CadenceRow key={key}>
      {row.data.map((step) => (
        <Tooltip
          key={step.uid}
          title={`Data: ${step.date},\n Tipo: ${cadenceOptions[step.type]},\n Status: ${statusOptions[step.status]}`}
        >
          <CadenceStep 
            status={step.status} 
          />
        </Tooltip>
      ))}
    </CadenceRow>
  ));
};

const getColumns = ({ columns, showDrawer, handleRevised }) => {
  const filters = columns && columns.length > 0
    ? columns.map(column => ({ text: column.name, value: column.id }))
    : [];

  return [
    {
      title: 'Revisado',
      dataIndex: 'additional_data.is_revised',
      key: 'additional_data.is_revised',
      className: 'text-center',
      width: 100,
      fixed: 'left',
      render: (id, data) => (
        <center>
          {data.isLoading ? (
            <Icon type="loading" />
          ) : (
            <Switch 
              checked={get(data, 'additional_data.is_revised', false)}
              onChange={(checked) => handleRevised(checked, data)}
            />
          )}
        </center>
      ),
    },
    {
      title: 'Cadastro',
      dataIndex: 'created_at',
      key: 'created_at',
      className: 'text-center',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: renderCreatedAt,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Lead',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      ellipsis: true,
    },
    {
      title: 'Empresa',
      dataIndex: 'additional_data.company_name',
      key: 'additional_data.company_name',
      width: 300,
      ellipsis: true,
    },
    {
      title: 'Whatsapp',
      dataIndex: 'additional_data.phone',
      key: 'additional_data.phone',
      width: 200,
      ellipsis: true,
      align: 'center',
      render: renderPhone,
    },
    {
      title: 'Cadência',
      dataIndex: 'additional_data.cadences',
      key: 'cadences',
      width: 300,
      ellipsis: true,
      render: renderCadence,
    },
    {
      title: 'Etapa',
      dataIndex: 'column_id',
      key: 'column_id',
      width: 200,
      ellipsis: true,
      render: (column_id) => renderColumnId(column_id, columns),
      filters: filters,
      onFilter: (value, record) => record.column_id === value,
    },
    {
      title: '',
      width: 80,
      key: 'id',
      className: 'text-center',
      render: (id, data) => (
        <center>
          <Button type="default" onClick={() => showDrawer(data)}>
            <Icon type="eye" />
          </Button>
        </center>
      ),
      fixed: 'right',
    }
  ];
};

getColumns.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    style: PropTypes.shape({
      color: PropTypes.string,
    }),
  })).isRequired,
  showDrawer: PropTypes.func.isRequired,
  handleRevised: PropTypes.func.isRequired,
};

export const table = {
  getColumns,
};