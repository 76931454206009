import React, { useState } from 'react';
import { Input, List, Button, Select, Checkbox } from 'antd';
import { FiFilter } from 'react-icons/fi';
import styled from 'styled-components';

const StyledListItem = styled(List.Item)`
  cursor: pointer;
  background: ${props => (props.selected ? 'var(--primaryColorLight)' : 'inherit')};
  &:hover {
    background: var(--primaryColorLight);
  }
`;

const StyledInputContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FilterContainer = styled.div`
  padding: 8px;
  background-color: #f9f9f9;
  border: 1px solid #d9d9d9;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CustomerItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CustomBadge = styled.div`
  background-color: ${props => (props.count === 0 ? '#d9d9d9' : 'var(--primaryColor)')};
  color: white;
  padding: 0 6px;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
`;

const StyledFilterIcon = styled(FiFilter)`
  cursor: pointer;
  color: ${props => (props.filtersApplied ? 'var(--primaryColor)' : 'inherit')};
`;

const SelectedFilters = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: var(--primaryColor);
`;

const StyledButton = styled(Button)`
	background-color: var(--primaryColor) !important;
	border-color: var(--primaryColor) !important;
	&:hover {
		background-color: var(--primaryColorHover) !important;
		border-color: var(--primaryColorHover) !important;
	}
`;

const FilterLabel = styled.span`
  background-color: var(--primaryColorLight);
  color: var(--primaryColor);
  padding: 2px 6px;
  border-radius: 12px;
  margin-right: 4px;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 2px;
`;

const CustomerList = ({ customers, searchQuery, onSearchChange, selectedCustomer, onCustomerSelect, customerTasks }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [regimeTributario, setRegimeTributario] = useState(null);
  const [hasEmployees, setHasEmployees] = useState(null);
  const [atividade, setAtividade] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({
    regimeTributario: null,
    hasEmployees: null,
    atividade: null,
  });

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilterChange = () => {
    setAppliedFilters({
      regimeTributario,
      hasEmployees,
      atividade,
    });
    setShowFilters(false);
  };

  const removeFilter = (filter) => {
    setAppliedFilters({
      ...appliedFilters,
      [filter]: null,
    });
  };

  const filtersApplied = appliedFilters.regimeTributario || appliedFilters.hasEmployees || appliedFilters.atividade;

  return (
    <div>
      <StyledInputContainer>
        <Input 
          placeholder="Buscar clientes" 
          value={searchQuery} 
          onChange={onSearchChange} 
          allowClear 
          suffix={<StyledFilterIcon onClick={toggleFilters} filtersApplied={filtersApplied} />}
        />
        {filtersApplied && (
          <SelectedFilters>
            {appliedFilters.regimeTributario && <FilterLabel onClick={() => removeFilter('regimeTributario')}>Regime: {appliedFilters.regimeTributario}</FilterLabel>}
            {appliedFilters.hasEmployees && <FilterLabel onClick={() => removeFilter('hasEmployees')}>Funcionários: {appliedFilters.hasEmployees === 'sim' ? 'Sim' : 'Não'}</FilterLabel>}
            {appliedFilters.atividade && <FilterLabel onClick={() => removeFilter('atividade')}>Atividade: {appliedFilters.atividade}</FilterLabel>}
          </SelectedFilters>
        )}
        {showFilters && (
          <FilterContainer>
            <div>
              <label>Regime tributário:</label>
              <Select 
                style={{ width: '100%' }} 
                onChange={value => setRegimeTributario(value)} 
                value={regimeTributario}
              >
                <Select.Option value="simples">Simples</Select.Option>
                <Select.Option value="lucroPresumido">Lucro Presumido</Select.Option>
                <Select.Option value="lucroReal">Lucro Real</Select.Option>
              </Select>
            </div>
            <div>
              <label>Funcionários:</label>
              <Select 
                style={{ width: '100%' }} 
                onChange={value => setHasEmployees(value)} 
                value={hasEmployees}
              >
                <Select.Option value="sim">Sim</Select.Option>
                <Select.Option value="nao">Não</Select.Option>
              </Select>
            </div>
            <div>
              <label>Atividade:</label>
              <Select 
                style={{ width: '100%' }} 
                onChange={value => setAtividade(value)} 
                value={atividade}
              >
                <Select.Option value="comercio">Comércio</Select.Option>
                <Select.Option value="servico">Serviço</Select.Option>
              </Select>
            </div>
            <StyledButton type="primary" onClick={handleFilterChange}>Aplicar Filtros</StyledButton>
          </FilterContainer>
        )}
      </StyledInputContainer>
      <List
        dataSource={
          [...customers]
            .filter(customer =>
              customer.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
              (!appliedFilters.regimeTributario || customer.regimeTributario === appliedFilters.regimeTributario) &&
              (!appliedFilters.hasEmployees || customer.hasEmployees === (appliedFilters.hasEmployees === 'sim')) &&
              (!appliedFilters.atividade || customer.atividade === appliedFilters.atividade)
            )
            .sort((a, b) => a.name.localeCompare(b.name))
        }
        renderItem={customer => (
          <StyledListItem 
            key={customer.id} 
            onClick={() => onCustomerSelect(customer)}  
            selected={(selectedCustomer && selectedCustomer.id) === customer.id}
          >
            <CustomerItem>
              <span>{customer.name}</span>
              <CustomBadge count={customerTasks[customer.id] ? customerTasks[customer.id].length : 0}>
                {customerTasks[customer.id] ? customerTasks[customer.id].length : 0}
              </CustomBadge>
            </CustomerItem>
          </StyledListItem>
        )}
      />
    </div>
  );
};

export default CustomerList;
