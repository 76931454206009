export const getSignupData = () => {
    return JSON.parse(localStorage.getItem('signupData')) || {
        name: '',
        email: '',
        company_name: '',
        whatsapp: '',
    };
};

export const setSignupData = (data) => {
    localStorage.setItem('signupData', JSON.stringify(data));
};

export const clearSignupData = () => {
    localStorage.removeItem('signupData');
};
