import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ConfettiGenerator from "confetti-js";
import moment from "moment";

import DrawerLeads from "~/containers/DrawerLeads";
import * as drawerLeadsActions from "~/containers/DrawerLeads/actions";
import * as leadsActions from "~/containers/Leads/actions";
import { LeadCalendarDrawer } from "../LeadCalendarDrawer";
import { Header } from "./Header";
import { LeadMetricsModal } from "./LeadMetricsModal";
import { ConfettiContainer } from "./styles";
import { LeadCalendar } from "./LeadCalendar";
import { LeadColumns } from "./LeadColumns";
import { LeadsTable } from "../LeadsTable";

moment.updateLocale("pt-br", {
  weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
});

const Leads = ({
  columns,
  showDrawer,
  fetchQtddByMonth,
  loadMoreLeads,
  saveMetrics,
  currentMetrics,
  currentSummary,
  moveLead,
  isLoading,
  isLeadsDrawerVisible,
  finishCurrentOnboardingStep,
  user,
  hideLeads,
}) => {
  const [displayMode, setDisplayMode] = useState("funnel"); // table, calendar, funnel
  const [date, setDate] = useState(moment().format("MM-YYYY"));
  const [isMetricsModalVisible, setIsMetricsModalVisible] = useState(false);
  const [isShowingConffeti, setIsShowingConffeti] = useState(false);
  const [calendarDrawer, setCalendarDrawer] = useState({
    isOpen: false,
    followUps: [],
    meetings: [],
  });

  // table mode
  const [period, setPeriod] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);

  const conffetiRef = useRef(null);

  useEffect(() => {
    if (isShowingConffeti) {
      const confettiSettings = {
        target: "my-canvas",
        max: "100",
        size: "1",
        animate: true,
        props: ["circle", "square", "triangle", "line"],
        colors: [
          [165, 104, 246],
          [230, 61, 135],
          [0, 199, 228],
          [253, 214, 126],
        ],
        clock: "25",
        rotate: false,
        width: window.screen.width,
        height: window.screen.height,
        start_from_edge: true,
        respawn: false,
      };

      const confetti = new ConfettiGenerator(confettiSettings);
      conffetiRef.current = confetti;
      conffetiRef.current.render();
    }
  }, [isShowingConffeti]);

  const startShowingConfetti = () => {
    setIsShowingConffeti(true);
  };

  const stopShowingConfetti = () => {
    conffetiRef.current.clear();
    setIsShowingConffeti(false);
  };

  const closeCalendarDrawer = () => {
    setCalendarDrawer({
      isOpen: false,
      followUps: [],
      meetings: [],
    });
  };

  const openCalendarDrawer = ({ followUps, meetings }) => {
    setCalendarDrawer({
      isOpen: true,
      followUps,
      meetings,
    });
  };

  const closeMetricsModal = () => {
    setIsMetricsModalVisible(false);
  };

  return (
    <div>
      {isShowingConffeti && (
        <ConfettiContainer onClick={stopShowingConfetti}>
          <canvas id="my-canvas" />
        </ConfettiContainer>
      )}
      <Header
        date={date}
        setDate={setDate}
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        setIsMetricsModalVisible={setIsMetricsModalVisible}
        showDrawer={showDrawer}
        currentSummary={currentSummary}
        fetchQtddByMonth={fetchQtddByMonth}
        isLeadsDrawerVisible={isLeadsDrawerVisible}
        finishCurrentOnboardingStep={finishCurrentOnboardingStep}
        user={user}
        selectedRowsKeys={selectedRowsKeys}
        selectedRows={selectedRows}
        columns={columns}
        setPeriod={setPeriod}
      />
      {displayMode === "table" && (
        <LeadsTable 
          columns={columns}
          showDrawer={showDrawer}
          setSelectedRows={setSelectedRows}
          setSelectedRowsKeys={setSelectedRowsKeys}
          period={period}
        />
      )}
      {displayMode === "calendar" && (
        <LeadCalendar
          columns={columns}
          openCalendarDrawer={openCalendarDrawer}
          setDate={setDate}
        />
      )}
      {displayMode === "funnel" && (
        <LeadColumns
          columns={columns}
          moveLead={moveLead}
          startShowingConfetti={startShowingConfetti}
          date={date}
          isLoading={isLoading}
          showDrawer={showDrawer}
          fetchQtddByMonth={fetchQtddByMonth}
          loadMoreLeads={loadMoreLeads}
          user={user}
          hideLeads={hideLeads}
        />
      )}
      <LeadMetricsModal
        isVisible={isMetricsModalVisible}
        closeModal={closeMetricsModal}
        selectedDate={date}
        onSaveMetrics={saveMetrics}
        currentMetrics={currentMetrics}
      />
      <DrawerLeads />
      <LeadCalendarDrawer
        isVisible={calendarDrawer.isOpen}
        onClose={closeCalendarDrawer}
        followUps={calendarDrawer.followUps}
        meetings={calendarDrawer.meetings}
        onOpenDrawerLeads={showDrawer}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  columns: state.leads.columns,
  hideLeads: state.leads.hideLeads,
  currentMetrics: state.leads.currentMetrics,
  currentSummary: state.leads.currentSummary,
  isLoading: state.leads.isLoading,
  isLeadsDrawerVisible: state.drawerLeads.show,
  user: state.user
});

const mapDispatchProps = (dispatch) => ({
  showDrawer: (data = null) => dispatch(drawerLeadsActions.show(data)),
  loadMoreLeads: ({ columnId, currentCount, moreCount }) => {
    dispatch(leadsActions.loadMoreLeads({ columnId, currentCount, moreCount }))
  },
  fetchQtddByMonth: (date) => dispatch(leadsActions.fetchQtddByMonth({ date })),
  moveLead: (...props) => dispatch(leadsActions.move(props)),
  saveMetrics: (data) => dispatch(leadsActions.saveMetrics(data)),
});

export default connect(mapStateToProps, mapDispatchProps)(Leads);
