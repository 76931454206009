import axios from 'axios';

export const signup = async (signupData) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API}/auth/signup`,
            signupData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('There was an error signing up!', error);
        throw error;
    }
};
