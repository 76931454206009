import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import RegisterPage from './_domains/cadastro';
import Onboarding from './_domains/onboarding';
import ForgotPasswordPage from './_domains/forgotPassword';
import DrawerBulk from './_domains/tasks/drawerBulk';

import { AppContext } from './AppContext';
import { ImpersonateProvider } from './context/Impersonate';

import LoginPage from './_domains/login';
import ScreensRecoverPassword from './screens/Recover';
import ScreensInvited from './_domains/invited';
import ScreensUsers from './screens/Users';
import CalendarTask from './_domains/calendarTasks';
import ScreensLogout from './screens/Logout';
import ScreensAccount from './_domains/account';
import ScreeesNoMatch from './screens/NoMatch';
import ScreensPermissions from './screens/Permissions';
import { ScreensLinks } from './screens/Links';
import { ScreensDocuments } from './screens/Documents';
import { ScreensMalhaFina } from './containers/MalhaFina';
import { ScreensProcurations } from './screens/Procurations';
import { ScreensIRPF } from './containers/IRPF';
import { ScreensCertificates } from './screens/Certificates';
import { ScreensSocietario } from './screens/Societario/Index';
import { ScreensRefunds } from './containers/Refunds';
import { ScreensBillsToReceive } from './screens/BillsToReceive';
import { ScreensMonthlyPayment } from './screens/Financial/MonthlyPayment';
import { ScreensConventions } from './screens/Conventions';
import { ScreensRegisterTasks } from './screens/RegisterTasks';
import { ScreensDashboard } from './screens/Dashboard';
import { ScreensCustomers } from './screens/Customers';
import { ScreensLeads } from './screens/Leads';
import { ScreensRT } from './screens/RT';
import { ScreensChecklist } from './screens/Checklist';
import { ScreensLeadForms } from './screens/LeadForms';
import FormsIndication from './screens/FormsIndication';
import { ScreensPlugins } from './screens/Plugins';
import { ScreensDocumentsDeadlines } from './screens/DocumentsDeadlines';
import { route } from './_shared/components/MainMenu/routes';
import { ScreensEmailsLogs } from './screens/EmailsLogs';
import { ScreensFeedbacks } from './screens/Feedbacks';
import { ScreensHonoraries } from './screens/Honoraries';
import { ScreensKnowledgeBase } from './screens/KnowledgeBase';
import { ScreensBoards } from './screens/Boards/index';
import { ScreensPluginsForms } from './screens/PluginsForms';
import ScreenDefaultersReports from './screens/Reports/Defaulters';
import ScreensSolicitacoes from './_domains/solicitations';
import ReferralProgram from './_domains/plugins/referral-program';
import Prospecting from './_domains/prospecting';
import SocialSelling from './_domains/socialSelling';
import Leadsv2 from './_domains/leadsv2';

const ScreensRoot = () => {
  const currentSubdomain = window.location.hostname.split('.')[0];
  const appDomain = process.env.REACT_APP_DOMAIN;

  if (currentSubdomain === 'ajcontabil' && window.location.pathname === '/cliente') {
    window.location.href = `https://cliente.${appDomain}`;
    return null;
  }

  // New redirect rule for /f/
  if (currentSubdomain === 'ajcontabil' && window.location.pathname.startsWith('/f/')) {
    const path = window.location.pathname.replace('/f/', '');
    window.location.href = `http://app.${appDomain}/f/ajcontabil/${path}`;
    return null;
  }

  // New redirect rule for /leads/forms/
  if (currentSubdomain === 'ajcontabil' && window.location.pathname.startsWith('/leads/forms/')) {
    const path = window.location.pathname.replace('/leads/forms/', '');
    window.location.href = `http://app.${appDomain}/leads/forms/ajcontabil/${path}`;
    return null;
  }

  if (currentSubdomain !== 'app') {
    window.location.href = `http://app.${appDomain}`;
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route path="/cliente/login" render={() => { window.location.href = "https://cliente.contlabs.com.br"; return null; }} />
        <Route path={route.cadastro} component={RegisterPage} exact/>
        <Route path={route.login} component={LoginPage} />
        <Route path={route.esqueciASenha} component={ForgotPasswordPage} exact/>
        <Route path={route.esqueciASenhaToken} component={ForgotPasswordPage} />
        <Route path={route.convidado} component={ScreensInvited} />
        <Route path={route.leadsForms} component={ScreensLeadForms} />
        <Route path={route.pluginsForms} component={ScreensPluginsForms} />
        <Route path={route.formsIndication} component={FormsIndication} />

        {/* Routas que necessitam de autenticação */}
        <AppContext>
          <ImpersonateProvider>
            <Route path={route.onboarding} component={Onboarding}/>
            <Route path={route.configuracoes.usuarios} component={ScreensUsers} />
            <Route path={route.permissoes} component={ScreensPermissions} />
            <Route path={route.customers} component={ScreensCustomers} strict/>
            <Route path={route.checklist} component={ScreensChecklist} strict/>
            <Route path={route.societario.certificados} component={ScreensCertificates} strict/>
            <Route path={route.societario.lembretes} component={ScreensProcurations} strict/>
            <Route path={route.convencoes} component={ScreensConventions} strict/>
            <Route path={route.societario.painel} component={ScreensSocietario} strict/>
            <Route path={route.configuracoes.pessoais} component={ScreensAccount} strict/>
            <Route path={route.tarefas.cadastro} component={ScreensRegisterTasks} strict/>
            <Route path={route.solicitacoes} component={ScreensSolicitacoes} strict/>
            <Route path={route.itensEnviados.documentos} component={ScreensDocuments} strict/>
            <Route path={route.calendario.geral} component={CalendarTask} strict/>
            <Route path={route.financeiro.extras} component={ScreensBillsToReceive} strict/>
            <Route path={route.financeiro.mensalidades} component={ScreensMonthlyPayment} strict/>
            <Route path={route.financeiro.comissoes} component={ScreensRefunds} strict/>
            <Route path={route.crm.leads} component={ScreensLeads} strict/>
            <Route path={route.v2LeadsInssObra} component={Leadsv2} strict/>
            <Route path={route.recuperacaoTributaria} component={ScreensRT} strict/>
            <Route path={route.crm.prospeccao} component={Prospecting} strict/>
            <Route path={route.crm.socialSelling} component={SocialSelling} strict/>
            <Route path={route.crm.referralProgram} component={ReferralProgram} strict />
            <Route path={route.links} component={ScreensLinks} strict />
            <Route path={route.irpf} component={ScreensIRPF} strict/>
            <Route path={route.malhaFina.geral} component={ScreensMalhaFina} strict/>
            <Route path={route.relatorios.inadimplentes} component={ScreenDefaultersReports} strict />
            <Route path={route.relatorios.prazos} component={ScreensDocumentsDeadlines} strict />
            <Route path={route.relatorios.honorarios} component={ScreensHonoraries} strict />
            <Route path={route.itensEnviados.emails} component={ScreensEmailsLogs} strict />
            <Route path={route.relatorios.feedbacks} component={ScreensFeedbacks} strict />
            <Route path={route.boards} component={ScreensBoards} strict/>
            <Route path={route.plugins.forms} component={ScreensPlugins} strict />
            <Route path={route.plugins.geral} component={ScreensPlugins} strict />
            <Route path={route.baseDeConhecimento} component={ScreensKnowledgeBase} strict />
            <Route path={route.sair} component={ScreensLogout} />
            <Route path={route.dashboard} component={ScreensDashboard} exact/>
          </ImpersonateProvider>
        </AppContext>

        {/* Rota 404 */}
        <Route path="*" component={ScreeesNoMatch}/>
      </Switch>
    </Router>
  )
};

export default ScreensRoot;