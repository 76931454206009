import React, { useState, useMemo, useCallback } from "react";
import { Button, Dropdown, Icon, Menu, Modal, Table, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FaUserPlus } from "react-icons/fa";

import { AppUserContainer } from "../styles";
import { UserModal } from "./UserModal";
import { CustomerPasswordModal } from "~/components/Customers/Modals/CustomerPasswordModal";
import * as customersActions from "~/containers/Customer/actions";
import { UserInviteModal } from "./UserInviteModal";
import { getDefaultPaginationConfig } from "~/utils/get-default-pagination-config";
import { STORAGE } from "~/constants/storage.constants";

export const AppUsers = ({ customer }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState(customer.app_users || []);
  const [userModal, setUserModal] = useState({ isVisible: false, data: null });
  const [userPasswordModal, setUserPasswordModal] = useState({ isVisible: false, data: null });
  const [userInviteModal, setUserInviteModal] = useState({ isVisible: false });

  const showModal = useCallback((setter, data = null) => setter({ isVisible: true, data }), []);
  const hideModal = useCallback((setter) => setter({ isVisible: false, data: null }), []);

  const deleteAppUser = useCallback(
    (params) => {
      Modal.confirm({
        title: "Você realmente deseja remover este usuário?",
        content: "Esta ação não poderá ser desfeita.",
        okText: "Sim",
        okType: "danger",
        cancelText: "Cancelar",
        onOk: async () => {
          await dispatch(customersActions.deleteAppUser(params));
          setUsers((prevUsers) => prevUsers.filter((user) => user.id !== params.appUserId));
        },
      });
    },
    [dispatch]
  );

  const resendInvite = useCallback(
    (params) => {
      Modal.confirm({
        title: "Você realmente deseja reenviar o convite para este usuário?",
        content: "O usuário receberá um novo e-mail de convite.",
        okText: "Sim",
        cancelText: "Cancelar",
        onOk: async () => {
          await dispatch(customersActions.resendInvite(params));
          Modal.success({ title: "Convite reenviado com sucesso!" });
        },
      });
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        title: "Nome",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "E-mail",
        key: "email",
        dataIndex: "email",
      },
      {
        title: "Último acesso",
        key: "last_access_at",
        dataIndex: "last_access_at",
        render: (lastAccessAt) =>
          lastAccessAt ? (
            <Tooltip title={moment(lastAccessAt).format("DD/MM/YYYY LTS")}>
              {moment(lastAccessAt).fromNow()}
            </Tooltip>
          ) : null,
      },
      {
        align: "center",
        key: "actions",
        width: 80,
        render: (_, appUser) => (
          <Dropdown
            overlay={
              <Menu>
                {appUser.last_access_at === null && (
                  <Menu.Item
                    key="4"
                    onClick={() =>
                      resendInvite({ customerId: customer.id, appUserId: appUser.id })
                    }
                  >
                    <Icon type="mail" /> Reenviar convite
                  </Menu.Item>
                )}
                <Menu.Item key="1" onClick={() => showModal(setUserModal, appUser)}>
                  <Icon type="edit" /> Editar
                </Menu.Item>
                <Menu.Item key="2" onClick={() => showModal(setUserPasswordModal, appUser)}>
                  <Icon type="lock" /> Gerar nova senha
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="3"
                  onClick={() =>
                    deleteAppUser({ customerId: customer.id, appUserId: appUser.id })
                  }
                >
                  <Icon type="delete" /> Excluir
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button type="link">
              Ações <Icon type="down" />
            </Button>
          </Dropdown>
        ),
      },
    ],
    [showModal, deleteAppUser, resendInvite, customer.id]
  );

  return (
    <AppUserContainer>
      <Button type="primary" className="mb-20" onClick={() => showModal(setUserInviteModal)}>
        <FaUserPlus style={{ marginRight: 8 }} /> Convidar usuário
      </Button>
      <Table
        dataSource={users}
        columns={columns}
        size="small"
        rowKey="id"
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.customersAppUsers,
        })}
      />
      <UserModal
        isVisible={userModal.isVisible}
        data={userModal.data}
        hideModal={() => hideModal(setUserModal)}
        setUsers={setUsers}
        customer={customer}
        users={users}
      />
      <UserInviteModal
        isVisible={userInviteModal.isVisible}
        hideModal={() => hideModal(setUserInviteModal)}
        setUsers={setUsers}
        customer={customer}
      />
      <CustomerPasswordModal
        isOpen={userPasswordModal.isVisible}
        onClose={() => hideModal(setUserPasswordModal)}
        appUser={userPasswordModal.data}
        customer={customer}
      />
    </AppUserContainer>
  );
};
