import { Popover, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Chart from "react-apexcharts";

import { getNearestDate } from '~/helpers/getNearestDate';
import {
  BottomContent,
  CentralContent,
  ChartContent,
  ChartDueDate,
  ChartDueDateTitle,
  ChartItem,
  ChartPercentTitle,
  ChartTitle,
  ChatProgress,
  LeftArrow,
  PopoverContent,
  RightArrow,
  TopContent,
} from "./styles";

export function TaskChart({ title, data }) {
  if (!data) return null;

  const { due_list: dueList } = data;

  const [dataIndex, setDataIndex] = useState(getNearestIndex());

  function getNearestIndex() {
    const dates =  dueList.map(({ due_date }) => due_date);
    const nearestDate = getNearestDate({ dates, targetDate: moment().format('YYYY-MM-DD') });
    const nearestIndex = dueList.findIndex(({ due_date }) => due_date === nearestDate);
    if (nearestIndex === -1) return 0;
    return nearestIndex;
  }

  function handlePreviousDueDate() {
    if (dataIndex === 0) return;
    setDataIndex(dataIndex - 1);
  }

  function handleNextDueDate() {
    if (dataIndex === dueList.length - 1) return;
    setDataIndex(dataIndex + 1);
  }

  const currentData = dueList[dataIndex];

  const isExpired = currentData
    ? currentData.total_count === 0
      ? false
      : moment(currentData.due_date).diff(moment(), "days") < 0
    : false;

  return (
    <ChartItem>
      <Popover
        content={
          currentData ? (
            <PopoverContent>
              {currentData.tasks.map((task) => (
                <span key={task.id}>
                  <strong>{task.name}</strong>: {task.executed_count} /{" "}
                  {task.total_count}
                </span>
              ))}
            </PopoverContent>
          ) : (
            "Não existem tarefas para este departamento"
          )
        }
        title={
          currentData
            ? `${currentData.executed_count} / ${currentData.total_count} (${currentData.percent}%)`
            : "Sem tarefas"
        }
        align={{ offset: [0, 36] }}
      >
        <Chart
          type="radialBar"
          height={300}
          series={currentData ? [currentData.percent] : [0]}
          options={{
            chart: {
              height: 300,
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "70%",
                },
                dataLabels: {
                  show: false,
                },
                track: {
                  background: isExpired ? '#f5222d' : '#f2f2f2'
                }
              },
            },
            responsive: [
              {
                breakpoint: 1600,
                options: {
                  chart: {
                    height: 250,
                  },
                },
              },
            ],
            colors: ["#52c41a"],
          }}
        />
      </Popover>
      <ChartContent>
        <TopContent>
          <LeftArrow
            visible={dataIndex > 0 ? "true" : "false"}
            onClick={handlePreviousDueDate}
          />
          <CentralContent>
            <ChartTitle>{title}</ChartTitle>
            {currentData && (
              <>
                <ChartDueDateTitle>Vencimento</ChartDueDateTitle>
                <ChartDueDate expired={isExpired ? "true" : "false"}>
                  {moment(currentData.due_date).format("DD/MM")}
                </ChartDueDate>
              </>
            )}
          </CentralContent>
          <RightArrow
            visible={dataIndex < dueList.length - 1 ? "true" : "false"}
            onClick={handleNextDueDate}
          />
        </TopContent>
        <BottomContent>
          <ChartPercentTitle>Percentual Mensal</ChartPercentTitle>
          <Tooltip
            title={`${data.total_executed_count} / ${data.total_count} (${data.total_percent}%)`}
          >
            <ChatProgress percent={data.total_percent} />
          </Tooltip>
        </BottomContent>
      </ChartContent>
    </ChartItem>
  );
}
