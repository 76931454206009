import React from 'react';
import { Input, Switch, Table, Button, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const TaskTable = ({
	taskSearchQuery,
	onTaskSearchChange,
	selectedCategory,
	onCategoryChange,
	filteredTasks,
	taskColumns,
	allSelected,
	onToggleAll,
	selectedCustomer,
	customerTasks
}) => (
	<>
		<div style={{ 
			position: 'sticky', 
			top: 0, 
			zIndex: 1, 
			backgroundColor: '#fff', // changed to white
			padding: '8px', 
			borderRadius: '4px', 
			marginBottom: '16px',
			boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' // added shadow
		}}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
					<Input 
						placeholder="Buscar tarefas" 
						value={taskSearchQuery} 
						onChange={onTaskSearchChange} 
						style={{ width: taskSearchQuery && taskSearchQuery.length > 0 ? '80%' : '40%' }}
						allowClear 
					/>
					<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
						<Switch checked={allSelected} onChange={onToggleAll} />
						<span>Selecionar Todos</span>
					</div>
				</div>
				<Select
					defaultValue={0}
					style={{ width: 200 }}
					onChange={onCategoryChange}
				>
					<Option value={0}>Todas</Option>
					<Option value={1}>Societário</Option>
					<Option value={2}>Administrativo</Option>
					<Option value={3}>Contábil</Option>
					<Option value={4}>Fiscal</Option>
					<Option value={5}>Pessoal</Option>
					<Option value={6}>Sucesso do Cliente</Option>
				</Select>
			</div>
		</div>
		<Table
			rowKey="id"
			columns={taskColumns}
			dataSource={filteredTasks}
			pagination={false}
			showHeader={false}
			rowClassName={(record) => 
				selectedCustomer && customerTasks[selectedCustomer.id] && customerTasks[selectedCustomer.id].includes(record.id) 
				? 'selected-task' 
				: ''
			}
		/>
	</>
);

export default TaskTable;
