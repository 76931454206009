import React, { createContext, useContext } from 'react';
import { request } from '~/_shared/instance';
import { AccountStorage } from '~/storages/account';
import { useSelector } from 'react-redux';

const ImpersonateContext = createContext();

export const ImpersonateProvider = ({ children }) => {
    const user = useSelector(state => state.user);

    const impersonateUser = async (id) => {
        try {
            const currentAccount = AccountStorage.get();
            const response = await request('post', `/user/${id}/impersonate`);
            const { api_token, user: impersonatedUser } = response.data;
            AccountStorage.set({ token: api_token, user: { ...impersonatedUser, originalUser: {...currentAccount} } });
            localStorage.setItem('impersonatedUser', JSON.stringify({ ...response.data, originalUser: {...currentAccount} }));
            window.location.href = '/';
        } catch (error) {
            console.error('Error impersonating user', error);
        }
    };

    const revertImpersonation = async () => {
        const originalUser = localStorage.getItem('impersonatedUser') ? JSON.parse(localStorage.getItem('impersonatedUser')).originalUser : null;
        if (originalUser) {
            AccountStorage.set({ ...originalUser });
            localStorage.removeItem('impersonatedUser');
            window.location.reload();
        }
    };

    const getPreviousUserName = () => {
        const originalUser = localStorage.getItem('impersonatedUser') ? JSON.parse(localStorage.getItem('impersonatedUser')).originalUser : null;
        return originalUser ? originalUser.user.name.split(' ')[0] : null;
    };

    const isImpersonating = () => {
        return localStorage.getItem('impersonatedUser') !== null;
    };

    const impersonatedUser = localStorage.getItem('impersonatedUser') ? JSON.parse(localStorage.getItem('impersonatedUser')) : null;

    return (
        <ImpersonateContext.Provider value={{ impersonatedUser, impersonateUser, revertImpersonation, getPreviousUserName, isImpersonating }}>
            {children}
        </ImpersonateContext.Provider>
    );
};

export const useImpersonate = () => useContext(ImpersonateContext);
