import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
`;

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
`;

const TypingText = styled.div`
    margin-top: 13px;
    font-size: 16px;
    color: #222;
    animation: ${fadeIn} 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.8;
    overflow: hidden;
    border-right: 0.15em solid #7f00ff;
    max-width: 600px;

    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
    }

    @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: #7f00ff }
    }
`;

const FeatureList = styled.ul`
    text-align: left;
    max-width: 600px;
`;

const FeatureItem = styled.li`
    margin-bottom: 10px;
    font-size: 16px;
    color: #7f00ff;
    animation: ${props => props.fadeOut ? fadeOut : fadeIn} 1s ease-in-out;
    text-align: center;

    strong {
        color: #7f00ff;
    }
`;

const features = [
    "WhatsApp Integrado: Com nossa extensão para WhatsApp você poderá acompanhar e cadastrar leads diretamente pelo seu whatsapp web.",
    "CRM: Controle de Propostas: Cadastre leads através do Whatsapp com 1 clique. Converta oportunidades em clientes utilizando nossa ferramenta de funil de vendas.",
    "Base de Conhecimento: Concentre toda a sua base de conhecimento como script de vendas ou dúvidas em geral e disponibilize para seu time comercial.",
    "Departamento Societário: Controle os processos de abertura, troca, encerramento ou tarefas esporádicas.",
    "Clientes: Cadastro completo dos dados dos seus clientes. Importe ou exporte as informações com facilidade.",
    "Checklists: Cadastre tarefas que devem ser executadas toda vez que sejam realizadas: abertura de empresa, troca de contabilidade ou encerramento de empresa.",
    "Cadastro de Tarefas: Tarefas que se repetem mensalmente como DAS, Carnê Leão e E-SOCIAL. Tarefas que se repetem anualmente como 13º SALÁRIO, DIRF, TFE, TFA E TRSS. Ou tarefas esporádicas e customizadas.",
    "Relatórios: Gere relatórios detalhados sobre o desempenho da sua contabilidade.",
    "Integrações: Integre com outras ferramentas e plataformas para otimizar seu fluxo de trabalho.",
    "Suporte: Atendimento ao cliente para resolver suas dúvidas e problemas.",
    "Segurança: Proteja os dados da sua empresa com nossas medidas de segurança avançadas.",
    "Automação: Automatize processos repetitivos para ganhar eficiência.",
    "Customização: Personalize o sistema de acordo com as necessidades da sua empresa.",
    "Escalabilidade: Escale seu negócio com nossa plataforma robusta e flexível.",
    "Análises: Obtenha insights valiosos com nossas ferramentas de análise de dados."
];

const Loading = () => {
    const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);
    const [showLoadingText, setShowLoadingText] = useState(true);
    const [displayText, setDisplayText] = useState("");
    const [showFeatureList, setShowFeatureList] = useState(false);

    const fullText = "Enquanto estamos criando sua conta, acompanhe tudo que o Contlabs pode fazer pela sua contabilidade.";

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeOut(true);
            setTimeout(() => {
                setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
                setFadeOut(false);
            }, 1000);
        }, 3000);

        let charIndex = 0;
        const typingInterval = setInterval(() => {
            if (charIndex < fullText.length) {
                setDisplayText(prev => prev + fullText[charIndex]);
                charIndex++;
            } else {
                clearInterval(typingInterval);
                setTimeout(() => {
                    setShowLoadingText(false);
                    setShowFeatureList(true);
                }, 1000);
            }
        }, 50);

        return () => {
            clearInterval(interval);
            clearInterval(typingInterval);
        };
    }, []);

    return (
        <LoadingWrapper>
            {showLoadingText && (
                <TypingText>
                    <div>{displayText.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}</div>
                </TypingText>
            )}
            {showFeatureList && (
                <FeatureList>
                    <FeatureItem fadeOut={fadeOut}>
                        <strong>{features[currentFeatureIndex].split(': ')[0]}:</strong> {features[currentFeatureIndex].split(': ')[1]}
                    </FeatureItem>
                </FeatureList>
            )}
        </LoadingWrapper>
    );
};

export default Loading;
