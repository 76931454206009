import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Drawer, Form, Icon, Input, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { compare } from 'natural-orderby';

import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import { DocumentUpload, Footer, UploadWrapper } from './styles';
import { DocumentViewerModal } from '~/_domains/calendarTasks/List/Modals/DocumentViewerModal';
import * as documentActions from '~/containers/Documents/actions';
import { DeliveryMethodsButtonsModal } from '~/containers/Documents/Modals/DeliveryMethodsButtonsModal';

const { Option } = Select;
const { TextArea } = Input;
const { MonthPicker } = DatePicker;

const SporadictDocumentUploadComponent = ({
  isOpen,
  onClose,
  form,
  customers,
  createDocument,
  selectedDepartmentId,
  documentTypes
}) => {
  const { getFieldDecorator } = form;

  const [selectedFileList, setSelectedFileList] = useState([]);
  const [documentViewerModal, setDocumentViewerModal] = useState({
    isOpen: false,
    data: null,
  });
  const [deliveryMethodsModal, setDeliveryMethodsModal] = useState({
    isVisible: false,
    data: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentDepartmentId, setCurrentDepartmentId] = useState(selectedDepartmentId);

  useEffect(() => {
    if (isOpen) {
      setCurrentDepartmentId(selectedDepartmentId);
    }
  }, [isOpen]);

  const documentTypesOfCurrentDepartment = currentDepartmentId
    ? documentTypes.filter((type) => Number(type.department) === Number(currentDepartmentId))
    : documentTypes;

  function openDocumentViewerModal(data) {
    setDocumentViewerModal({
      isOpen: true,
      data,
    });
  }

  function closeDocumentViewerModal() {
    setDocumentViewerModal({
      isOpen: false,
      data: null,
    });
  }

  function openDeliveryMethodsModal(data = null) {
    setDeliveryMethodsModal({
      isVisible: true,
      data,
    });
  }

  function closeDeliveryMethodsModal() {
    onClose();
    setDeliveryMethodsModal({
      isVisible: false,
      data: null,
    });
  }


  function closeDrawer() {
    setSelectedFileList([]);
    onClose();
  }

  function handleSubmit() {
    form.validateFields((errors, values) => {
      if (errors) return;

      const formData = new FormData();

      const {
        customer_id,
        department,
        description,
        amount,
        competency_at,
        due_at,
        type,
        file,
      } = values;

      formData.append('customer_id', customer_id);
      formData.append('department', department);
      formData.append('description', description);
      formData.append('amount', amount);
      formData.append(
        'competency_at',
        moment(competency_at).format('YYYY-MM-DD')
      );
      formData.append('due_at', moment(due_at).format('YYYY-MM-DD'));
      formData.append('type', type);
      formData.append('file', file.file);

      setIsLoading(true);

      createDocument(formData)
        .then(({ payload }) => {
          openDeliveryMethodsModal(payload.data.file);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }

  if (!customers) return null;

  return (
    <Drawer
      destroyOnClose
      title="Adicionar documento"
      placement="right"
      onClose={closeDrawer}
      visible={isOpen}
      width={400}
    >
      <Form.Item label="Cliente">
        {getFieldDecorator('customer_id', {
          rules: [
            {
              required: true,
              message: 'Por favor, informe o cliente',
            },
          ],
        })(
          <Select
            placeholder="Selecione um cliente"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {customers.map((customer) => (
              <Option
                key={customer.id}
                value={customer.id}
              >{`${customer.cod} - ${customer.name}`}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Departamento">
        {getFieldDecorator('department', {
          rules: [
            {
              required: true,
              message: 'Por favor, informe o departamento',
            },
          ],
          initialValue: currentDepartmentId
        })(
          <Select placeholder="Selecione um departamento" onChange={(department) => setCurrentDepartmentId(department)}>
            <Option value="1">Legalização</Option>
            <Option value="2">Administrativo</Option>
            <Option value="3">Contábil</Option>
            <Option value="4">Fiscal</Option>
            <Option value="5">Pessoal</Option>
            <Option value="6">Sucesso do Cliente (CS)</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Tipos de documentos">
        {getFieldDecorator('type', {
          rules: [
            {
              required: true,
              message: 'Por favor, informe o tipo',
            },
          ],
          initialValue: documentTypesOfCurrentDepartment && documentTypesOfCurrentDepartment.length === 1 ?
            documentTypesOfCurrentDepartment[0].description :
            undefined
        })(
          <Select placeholder="Selecione um tipo">
            {documentTypesOfCurrentDepartment && documentTypesOfCurrentDepartment.map((type) => (
              <Option key={type.id} value={type.description}>{type.description}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Descrição do documento">
        {getFieldDecorator('description', {
          rules: [
            {
              required: true,
              message: 'Por favor, informe a descrição do documento',
            },
          ],
        })(<TextArea placeholder="Descrição do documento" />)}
      </Form.Item>
      <Form.Item label="Mês de competência">
        {getFieldDecorator('competency_at', {
          rules: [
            {
              required: true,
              message: 'Por favor, informe o mês de competência',
            },
          ],
        })(<MonthPicker format="MM/YYYY" />)}
      </Form.Item>
      <Form.Item label="Data de vencimento">
        {getFieldDecorator('due_at', {
          rules: [
            {
              required: true,
              message: 'Por favor, informe a data de vencimento',
            },
          ],
        })(<DatePicker format="DD/MM/YYYY" />)}
      </Form.Item>
      <Form.Item label="Valor">
        {getFieldDecorator('amount', {
          normalize: (value) => currency2Float(value),
        })(<InputCurrency />)}
      </Form.Item>
      <Form.Item label="Selecione um arquivo">
        {getFieldDecorator('file', {
          rules: [
            {
              required: true,
              message: 'Por favor, selecione um arquivo',
            },
          ],
        })(
          <DocumentUpload
            fileList={selectedFileList}
            onChange={({ fileList, file }) => {
              setSelectedFileList([file]);
              if (fileList.length > 1) {
                fileList.shift();
              }
            }}
            beforeUpload={() => false}
          >
            <UploadWrapper>
              <Button>
                <Icon type="upload" /> Selecionar arquivo
              </Button>
              {selectedFileList.length > 0 && (
                <Button
                  type="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    openDocumentViewerModal(selectedFileList[0]);
                  }}
                >
                  Visualizar
                </Button>
              )}
            </UploadWrapper>
          </DocumentUpload>
        )}
      </Form.Item>
      <Footer>
        <Button onClick={onClose}>Voltar</Button>
        <Button onClick={handleSubmit} type="primary" loading={isLoading}>
          Salvar
        </Button>
      </Footer>
      <DocumentViewerModal
        isOpen={documentViewerModal.isOpen}
        closeModal={closeDocumentViewerModal}
        data={documentViewerModal.data}
      />
      <DeliveryMethodsButtonsModal
        isVisible={deliveryMethodsModal.isVisible}
        onClose={closeDeliveryMethodsModal}
        document={deliveryMethodsModal.data}
      />
    </Drawer>
  );
};

const mapPropsToState = (state) => ({
  customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer === true).sort((a,b) => compare()(a.cod, b.cod)),
  documentTypes: state.documents.types
});

const mapDispatchToState = (dispatch) => ({
  createDocument: (data) => dispatch(documentActions.createDocument(data)),
});

export const SporadictDocumentUpload = connect(
  mapPropsToState,
  mapDispatchToState
)(Form.create()(SporadictDocumentUploadComponent));
