import update from 'immutability-helper';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import {
    SAVE,
    FETCH,
} from './actions';

const DEFAULT_CACHE_KEY = CACHE_ACTIONS.customerTasks;
const defaultState = {
    data: {},
    loading: false,
};

const ACTION_HANDLERS = {
    [DEFAULT_CACHE_KEY]: (state, action) => {
        return action.payload;
    },
    // [SAVE.SUCCESS]: (state, action) => update(state, {
    //     data: { $set: action.payload.data },
    //     loading: { $set: false },
    // }),
    [FETCH.SUCCESS]: (state, action) => update(state, {
        data: { $set: action.payload.data },
        loading: { $set: false },
    }),
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if (!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        SAVE.SUCCESS,
        FETCH.SUCCESS,
    ].includes(action.type);

    if (updateCache) {
        LocalCacheHandler.save({
            pathname: DEFAULT_CACHE_KEY,
            data: newState,
        });
    }

    return newState;
};

export default reducer;