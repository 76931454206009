import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../../AppContext';
import StartHereGraphic from '~/_domains/onboarding/StartHereGraphic';

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(61, 25, 115, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 10px 10px rgba(61, 25, 115, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(61, 25, 115, 0);
  }
`;

const ProgressBarContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgb(114, 47, 209);
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 50px;
  animation: ${shake} 0.5s ease-in-out, ${pulse} 1.5s infinite;
  color: white;
  cursor: pointer;
  max-width: fit-content;
  z-index: 2147483647;
`;

const OnboardingProgressBar = () => {
  const history = useHistory();
  const context = useContext(DataContext);
  const data = (context && context.onboardingData) || [];
  const currentTask = data.find(item => item.status === 'pending');
  const done = data.filter(item => item.status === 'done').length;
  const total = data.length;
  const progress = Math.round((done / total) * 100);

  const handleClick = () => {
    history.push('/onboarding');
  };

  if (currentTask && currentTask.route === history.location.pathname) {
    return null;
  }

  // Do not show the progress bar if the current route is /onboarding
  if (history.location.pathname === '/onboarding') {
    return null;
  }

  if(!currentTask) {
    return null;
  }

  return (
    <ProgressBarContainer onClick={handleClick}>
      <span>{currentTask ? currentTask.title : ''}</span>
      <StartHereGraphic progress={progress} />
    </ProgressBarContainer>
  );
};

export default OnboardingProgressBar;
