import { createAsyncActions } from '~/utils/store';

export const UPDATE_ONBOARDING_STEP = createAsyncActions('@@ONBOARDING/UPDATE_ONBOARDING_STEP');

export const updateOnboardingStep = (status) => ({
    type: UPDATE_ONBOARDING_STEP.INIT,
    payload: {
        request: {
            method: 'PATCH',
            url: '/user/onboarding/finish-step',
            data: { status },
        }
    }
});
