import { Progress } from "antd";
import styled from "styled-components";

export const CustomProgressWrapper = styled.div`
  display: flex;
  gap: 14px;
`;

export const CustomProgress = styled(Progress).attrs(props => ({
  strokeColor: props.status === "success" ? "#52c41a" : "#52c41a",
  strokeLinecap: "square",
  showInfo: false,
}))`
  .ant-progress-inner {
    background-color: ${({ expired, status }) => 
      status === "success" 
        ? "#d9f7be" 
        : (expired === "true" ? "#f5222d" : "#fadb14")};
  }
`;
