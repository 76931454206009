import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col, Icon, Skeleton, Empty } from 'antd';
import styled from 'styled-components';

import { TasksInList } from '../TasksInList';
import { BatchTasksDrawer } from '../List/Drawers/BatchTasksDrawer';
import { CustomSkeleton } from '../styles';
import { loadTaskList as loadTaskListAction } from '../actions';
import TaskGroup from './TaskGroup';
import { TasksSection, EmptyContainer, TasksScrollContainer } from './styles';
import { groupTasksByStatus, taskGroupConfig } from '../utils/taskGrouping';

const Tasks = ({
    filteredTasks,
    date,
    match,
    openList,
    openGrid,
    isTasksContextLoading,
    isListOpen,
    visualizationMode,
    updateTaskStats,
    calendarTasks
}) => {
    const [tasksInBatchDrawer, setTasksInBatchDrawer] = useState({
        isVisible: false,
        parentTask: null,
        customers: [],
        isLoading: false
    });
    
    // State to track which sections are expanded
    const [expandedSections, setExpandedSections] = useState({
        delayed: true,
        pending: true,
        completed: true
    });

    // Toggle section expanded/collapsed state
    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const selectedDepartmentId = useMemo(() => Number(match.params.id), [match.params.id]);
    const formattedDate = date.format('YYYY/MM');
    const isCalendarTasksLoading = (calendarTasks[selectedDepartmentId] && 
                                  calendarTasks[selectedDepartmentId][formattedDate] && 
                                  calendarTasks[selectedDepartmentId][formattedDate].loading) || false;

    // Group tasks by status using our utility function
    const groupedTasks = useMemo(() => 
        groupTasksByStatus(filteredTasks), 
    [filteredTasks]);

    const closeTasksInBatchDrawer = useCallback(async (tasksHaveBeenSent) => {
        const { parentTask } = tasksInBatchDrawer;

        setTasksInBatchDrawer({
            isVisible: false,
            parentTask: null,
            customers: [],
            isLoading: false
        });

        if (tasksHaveBeenSent) {
            const updatedCustomers = await loadTaskListAction(parentTask.id, date);
            
            // Calculate statistics and update the task
            const executedCount = updatedCustomers.filter(customer => customer.executed_at).length;
            const pendingCount = updatedCustomers.length - executedCount;
            const priorityCount = updatedCustomers.filter(customer => customer.priority).length;
            
            updateTaskStats(
                selectedDepartmentId,
                date,
                parentTask.id,
                {
                    executed_tasks_count: executedCount,
                    pendent_tasks_count: pendingCount,
                    priority_tasks_count: priorityCount,
                    tasks_count: updatedCustomers.length
                }
            );
        }
    }, [tasksInBatchDrawer, date, selectedDepartmentId, updateTaskStats]);

    const openTasksInBatchDrawer = useCallback(async ({ parentTask }) => {
        setTasksInBatchDrawer({
            isVisible: true,
            parentTask,
            customers: [],
            isLoading: true
        });
        const customers = await loadTaskListAction(parentTask.id, date);
        setTasksInBatchDrawer({
            isVisible: true,
            parentTask,
            customers,
            isLoading: false
        });
    }, [date]);

    const renderSkeletons = useCallback(() => (
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            {Array(6).fill(1).map((_, index) => (
                <Col span={6} key={index}>
                    <CustomSkeleton>
                        <Skeleton
                            active
                            title={false}
                            paragraph={{ width: '100%', rows: 5 }}
                        />
                    </CustomSkeleton>
                </Col>
            ))}
        </Row>
    ), []);

    const renderNoTasksMessage = useCallback(() => (
        <EmptyContainer>
            <Icon type="folder-open" style={{ fontSize: '48px', color: '#bfbfbf', marginBottom: '16px' }} />
            <h2>Nenhuma tarefa encontrada</h2>
            <p>Não há tarefas disponíveis para os filtros selecionados.</p>
        </EmptyContainer>
    ), []);

    if (isTasksContextLoading || isCalendarTasksLoading) {
        return renderSkeletons();
    }

    if (filteredTasks.length === 0) {
        return renderNoTasksMessage();
    }

    // Function to render a specific task group
    const renderTaskGroup = (groupKey) => {
        const tasks = groupedTasks[groupKey];
        if (!tasks || tasks.length === 0) return null;
        
        const config = taskGroupConfig[groupKey];
        
        return (
            <TasksSection key={groupKey}>
                <TaskGroup
                    title={config.title}
                    color={config.color}
                    bgColor={config.bgColor}
                    count={tasks.length}
                    tasks={tasks}
                    isExpanded={expandedSections[groupKey]}
                    onToggle={() => toggleSection(groupKey)}
                    openList={openList}
                    openGrid={openGrid}
                    openTasksInBatchDrawer={openTasksInBatchDrawer}
                />
            </TasksSection>
        );
    };

    return (
        <TasksScrollContainer>
            {visualizationMode === 'box' ? (
                <>
                    {renderTaskGroup('delayed')}
                    {renderTaskGroup('pending')}
                    {renderTaskGroup('completed')}
                </>
            ) : (
                <TasksInList
                    openList={openList}
                    openGrid={openGrid}
                    filteredTasks={filteredTasks}
                    openTasksInBatchDrawer={openTasksInBatchDrawer}
                    isListOpen={isListOpen}
                />
            )}
            <BatchTasksDrawer
                isVisible={tasksInBatchDrawer.isVisible}
                closeDrawer={closeTasksInBatchDrawer}
                departmentId={selectedDepartmentId}
                parentTask={tasksInBatchDrawer.parentTask}
                customersOfParentTask={tasksInBatchDrawer.customers}
                isLoading={tasksInBatchDrawer.isLoading}
            />
        </TasksScrollContainer>
    );
};

export default Tasks;
