import { SAVE_PHONES_BY_DEPARTMENT } from '~/_domains/account/WhatsappByDepartment/actions';
import { AccountStorage } from '~/storages/account';
import { getHoursBetweenTimestamps } from '~/utils/get-hours-between-timestamps';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { UPDATE_ONBOARDING_STEP } from '~/_domains/onboarding/actions';

const defaultState = {
    name: null,
    tenant: {
        name: null
    },
    onboarding_status: []
}

const ACTION_HANDLERS = {
    'LOAD_USER': (state, action) => {
        const { created_at, user } = AccountStorage.get();

        if (!!created_at && !!user) {
            const differenceInHours = getHoursBetweenTimestamps(created_at, Date.now());
            if (differenceInHours <= 8) {
                return user;
            }
        }

        return defaultState;
    },

    'LOAD_USER_SUCCESS': (state, action) => {
        const loadedUser = action.payload.data;

        const { token } = AccountStorage.get();
        AccountStorage.set({ token, user: loadedUser });

        const onboardingStatus = Array.isArray(loadedUser.onboarding_status) ? loadedUser.onboarding_status : [];

        return {
            ...loadedUser,
            onboarding_status: onboardingStatus
        };
    },

    'UPDATE_USER': (state, action) => {
        const updatedUser = {
            ...state,
            ...action.payload
        }

        const { token, created_at } = AccountStorage.get();
        AccountStorage.set({ token, created_at, user: updatedUser });

        return updatedUser;
    },

    'UPDATE_COMPANY': (state, action) => {
        return {
            ...state,
            tenant: {
                ...state.tenant,
                ...action.payload
            }
        }
    },

    'CHANGE_PASSWORD': (state, action) => {
        return {
            ...state
        }
    },

    'SAVE_CONFIG_SUCCESS': (state, action) => {
        return {
            ...state,
            tenant: action.payload.data.tenant
        }
    },

    [SAVE_PHONES_BY_DEPARTMENT.SUCCESS]: (state, action) => {
        const {
            legalization,
            administrative,
            accounting,
            fiscal,
            personal,
            audit
        } = action.meta.previousAction.payload.request.data;

        return {
            ...state,
            tenant: {
                ...state.tenant,
                legalization_phone: legalization,
                administrative_phone: administrative,
                accounting_phone: accounting,
                fiscal_phone: fiscal,
                personal_phone: personal,
                audit_phone: audit,
            }
        }
    },

    [UPDATE_ONBOARDING_STEP.SUCCESS]: (state, action) => {
        const { token } = AccountStorage.get();
        AccountStorage.set({ token, user: { ...state, onboarding_status: action.payload.data.data } });
        return {
            ...state,
            onboarding_status: action.payload.data.data
        };
    }
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if (!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_USER_SUCCESS',
        'UPDATE_USER',
        'UPDATE_COMPANY',
        'SAVE_CONFIG_SUCCESS',
        SAVE_PHONES_BY_DEPARTMENT.SUCCESS,
        UPDATE_ONBOARDING_STEP.SUCCESS
    ].includes(action.type);

    if (updateCache) {
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.loggedUser,
            data: newState
        });
    }

    return newState;
}

export default reducer;