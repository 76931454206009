/* eslint-disable default-case */
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Badge, message } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FileDownload from 'js-file-download';
import moment from 'moment';
import Scrollbar from 'react-scrollbars-custom';
import 'moment/locale/pt-br';
import * as drawerSocietario from '~/containers/DrawerSocietario/actions';
import { request } from '~/components/Instance';
import { LoadingSkeleton, LoadingSkeletonContainer } from './styles';
import { PendingDashTasksDrawer } from './components/PendingDashTasksDrawer';
import ItemCard from './components/ItemCard';
import SocietarioHeader from './components/SocietarioHeader';

import ArchivedTasksTable from './components/ArchivedTasksTable';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getListStyle = (isDraggingOver, listStyle) => ({
  background: listStyle.background,
  padding: 8,
  width: `100%`
});

const Dashboard = (props) => {
  const { list, getCustomer, getList, showDrawer, user, isLoading } = props;

  const [screen, setScreen] = useState('active'); //active & archived
  const [pendingDashTasksDrawer, setPendingDashTasksDrawer] = useState({
    isVisible: false,
  });
  const [search, setSearch] = useState("");

  const getListFiltered = (idList) => {
    return getList(idList).filter((item) => {
      if (search === "") {
        return true;
      }

      const customer = getCustomer(item);
      const customer_name = typeof customer.name === 'string' ? customer.name.toLowerCase() : '';
      const customer_cod = typeof customer.cod === 'string' ? customer.cod.toLowerCase() : '';

      if (customer_name.includes(search.toLowerCase()) || customer_cod.includes(search.toLowerCase())) {
        return true;
      }

      return false;
    });
  };

  const lists = {
    not_started: { name: 'Não Iniciado', style: { background: '#f5f5f5', color: '#1890ff' } },
    in_process: { name: 'Em Processo', style: { background: '#f5f5f5', color: '#faad14' } },
    done: { name: 'Concluido', style: { background: '#f5f5f5', color: '#52c41a' } },
  };

  const openPendingDashTasksDrawer = useCallback(() => {
    setPendingDashTasksDrawer({
      isVisible: true,
    });
  }, []);

  const closePendingDashTasksDrawer = useCallback(() => {
    setPendingDashTasksDrawer({
      isVisible: false,
    });
  }, []);

  const onDragEnd = useCallback((result) => {
    const { updateList, getList } = props;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getListFiltered(source.droppableId),
        source.index,
        destination.index
      );

      updateList(source.droppableId, items);
    } else {
      const result = move(
        getListFiltered(source.droppableId),
        getListFiltered(destination.droppableId),
        source,
        destination
      );

      props.updateTask(getListFiltered(source.droppableId)[source.index].id, destination.droppableId);

      updateList(source.droppableId, result[source.droppableId]);
      updateList(destination.droppableId, result[destination.droppableId]);
    }
  }, [props]);

  const hasContract = useCallback((customer) => customer.contract_files.length > 0 || customer.rescission_files.length > 0, []);

  const handleExportToExcel = useCallback(async () => {
    try {
      const response = await request('GET', `/dashtasks/export/excel`, null, true, {
        responseType: 'blob',
      });
      FileDownload(response.data, `Societário_${moment().format('DD-MM-YYYY_HH-mm-ss')}.xlsx`);
    } catch (error) {
      message.error('Não foi possível realizar a exportação!');
    }
  }, []);

  return (
    <div>
      <SocietarioHeader
        user={user}
        openPendingDashTasksDrawer={openPendingDashTasksDrawer}
        showDrawer={showDrawer}
        handleExportToExcel={handleExportToExcel}
        list={list}
        isSocietarioDrawerVisible={props.isSocietarioDrawerVisible}
        screen={screen}
        setScreen={setScreen}
        search={search}
        setSearch={setSearch}
      />

      {screen === 'archived' && (
        <ArchivedTasksTable 
          data={list.archived} 
          getCustomer={getCustomer} 
          showDrawer={showDrawer} 
        />
      )}

      {screen === 'active' && (
      <Row gutter={16}>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(lists).map((idList) => (
            <Col span={8} key={idList}>
              <Droppable droppableId={idList}>
                {(provided, snapshot) => (
                  <Card
                    title={lists[idList].name}
                    bodyStyle={{ padding: 0 }}
                    headStyle={lists[idList].style}
                    extra={
                      <Badge
                        count={list[idList].length}
                        style={{
                          backgroundColor: '#fff',
                          color: '#999',
                          boxShadow: '0 0 0 1px #d9d9d9 inset',
                        }}
                      />
                    }
                  >
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver, lists[idList].style)}
                    >
                      <Scrollbar style={{ height: window.innerHeight - 240 }}>
                        {isLoading
                          ? Array(5)
                              .fill(1)
                              .map((_, index) => (
                                <LoadingSkeletonContainer key={index}>
                                  <LoadingSkeleton />
                                </LoadingSkeletonContainer>
                              ))
                          : getListFiltered(idList).map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <ItemCard
                                    item={item}
                                    provided={provided}
                                    snapshot={snapshot}
                                    getCustomer={getCustomer}
                                    showDrawer={showDrawer}
                                    hasContract={hasContract}
                                  />
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </Scrollbar>
                    </div>
                  </Card>
                )}
              </Droppable>
            </Col>
          ))}
        </DragDropContext>
      </Row>
      )}
      <PendingDashTasksDrawer
        isVisible={pendingDashTasksDrawer.isVisible}
        onClose={closePendingDashTasksDrawer}
        pendingDashTasks={props.list.pending}
        showDrawer={props.showDrawer}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.dashTasks,
  user: state.user,
  isSocietarioDrawerVisible: state.drawerSocietario.drawer.show,
  isLoading: state.dashTasks.isLoading,
  getList: (list) =>
    state.dashTasks[list].sort(function (a, b) {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }),
  getCustomer: (item) => {
    if (item.deleted_customer) {
      return {
        name: item.deleted_customer.name,
      };
    }

    try {
      const customer = state.customersById[item.customer_id];
      if (customer) {
        return customer;
      }
    } catch (error) {
      console.log(error);
    }

    return {
      name: '-    -    -',
    };
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateList: (list, data) =>
    dispatch({
      type: 'UPDATE_DASHTASK_LIST',
      payload: {
        list,
        data,
      },
    }),
  showDrawer: (category, data) => dispatch(drawerSocietario.show(category, data)),
  updateTask: (id, list) =>
    dispatch({
      type: 'UPDATE_DASHTASKS_TASK',
      payload: {
        request: {
          method: 'put',
          url: `/dashtasks/${id}/status/${list}`,
        },
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
