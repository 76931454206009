import React, { useState } from "react";
import { Button, Input, Popover, Radio } from "antd";
import { useSelector } from "react-redux";

import Layout from "../components/UI/Layout/Layout";
import UsersList from "../components/Users/UsersList";
import UsersModalForm from "../components/Users/UsersModalForm";

const { Search } = Input;

const ScreensUsers = () => {
  const [modal, setModal] = useState({
    isVisible: false,
    data: null,
  });
  const [filter, setFilter] = useState("");
  const [showActive, setShowActive] = useState(true);

  function showModal(data) {
    setModal({
      isVisible: true,
      data,
    });
  }

  function hideModal() {
    setModal({
      isVisible: false,
      data: null,
    });
  }

  return (
    <div>
      <Layout>
        <div
          style={{
            margin: `0px -16px`,
            padding: "15px",
            background: "#fff",
            marginBottom: `10px`,
          }}
        >
          <h1 style={{ fontSize: `20px` }}>
            Usuários{" "}
            <Search
              placeholder="Pesquisar usuários"
              onChange={(e) => setFilter(e.target.value)}
              style={{ width: `30%` }}
            />
            <Radio.Group
              value={showActive}
              style={{ marginLeft: `10px` }}
              onChange={(e) => setShowActive(e.target.value)}
            >
              <Radio.Button
                value={true}
                style={
                  showActive
                    ? { borderColor: `#a0d911`, color: `#a0d911` }
                    : undefined
                }
              >
                Ativos
              </Radio.Button>
              <Radio.Button
                value={false}
                style={
                  !showActive
                    ? {
                        borderColor: `#f5222d`,
                        color: `#f5222d`,
                        boxShadow: `-1px 0 0 0 #f5222d`,
                      }
                    : undefined
                }
              >
                Inativos
              </Radio.Button>
            </Radio.Group>
            {' '}
            <Button
              type="primary"
              onClick={() => showModal()}
              style={{ float: `right` }}
            >
              Convidar Usuário
            </Button>
          </h1>
        </div>
        <UsersList showModal={showModal} filter={filter} actived={showActive} />
      </Layout>
      <UsersModalForm
        visible={modal.isVisible}
        onCancel={hideModal}
        data={modal.data}
      />
    </div>
  );
};

export default ScreensUsers;
