import { route } from '~/_shared/components/MainMenu/routes';

export const data = [
    {
        id: 'g7h8i9',
        title: 'Cadastre os dados da sua empresa',
        description: 'Estes dados serão utilizados nos PDFs gerados pelo sistema.',
        time: '3min',
        icon: 'bank',
        url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        status: 'pending',
        route: route.configuracoes.empresa,
        clicked: false,
    },
    {
        id: 'j0k1l2',
        title: 'Importe a logo da sua empresa',
        description: 'Importe a logo da sua empresa para personalizar os PDFs gerados.',
        time: '1min',
        icon: 'file-image',
        status: 'pending',
        clicked: false,
        url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        route: route.configuracoes.empresa,
        unlockMenus: [route.customers],
    },
    {
        id: 'm3n4o5',
        title: 'Importe seus clientes',
        description: 'Importe ou cadastre manualmente os clientes da sua empresa.',
        time: '8min',
        icon: 'usergroup-add',
        status: 'pending',
        clicked: false,
        route: route.customers,
        unlockMenus: [route.tarefas.cadastro, route.tarefas.start],
    },
    {
        id: 'p6q7r8',
        title: 'Configure as tarefas',
        description: 'Habilite as tarefas rotineiras que seu escritório precisa realizar.',
        url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        time: '8min',
        icon: 'schedule',
        status: 'pending',
        clicked: false,
        route: route.tarefas.start,
        unlockMenus: [
            route.calendario.administrativo,
            route.calendario.contabil,
            route.calendario.fiscal,
            route.calendario.pessoal,
            route.calendario.auditoria,
        ],
    },
    {
        id: 's9t0u1',
        title: 'Envie o acesso ao painel do cliente',
        description: 'Envie o acesso ao painel do cliente para que seus clientes possam acompanhar os documentos enviados.',
        // url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        time: '3min',
        icon: 'user',
        status: 'pending',
        clicked: false,
        route: route.customers,
    },
    {
        id: 'v2w3x4',
        title: 'Execute a primeira tarefa',
        description: 'Execute a primeira tarefa e veja como é fácil.',
        // url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        time: '2min',
        icon: 'schedule',
        status: 'pending',
        clicked: false,
        route: route.calendario.fiscal,
        unlockMenus: [
            route.itensEnviados.documentos,
            route.itensEnviados.emails,
        ],
    },
    {
        id: 'y5z6a7',
        title: 'Verifique o documento enviado',
        description: 'Verifique o documento enviado e veja como é fácil.',
        // url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        time: '2min',
        icon: 'file-done',
        status: 'pending',
        clicked: false,
        route: route.itensEnviados.documentos,
    },
    {
        id: 'b8c9d0',
        title: 'Convide sua equipe',
        description: 'Adicione os membros da sua equipe para colaborar no sistema.',
        // url: "https://www.youtube.com/embed/8oDEa_uhY-0",
        time: '2min',
        icon: 'team',
        status: 'pending',
        clicked: false,
        route: route.configuracoes.usuarios,
    },
    // {
    //     id: 'e1f2g3',
    //     title: 'Instale a extensão do Whatsapp',
    //     description: 'Integre o Whatsapp com o Contlabs instalando nossa extensão no Google Chrome.',
    //     time: '2min',
    //     icon: 'api',
    //     status: 'pending',
    //     clicked: false,
    // },
    // {
    //     id: 'h4i5j6',
    //     title: 'Cadastre o primeiro lead pelo Whatsapp',
    //     description: 'Cadastre um lead pelo Whatsapp e veja como é fácil.',
    //     time: '2min',
    //     icon: 'message',
    //     status: 'pending',
    //     clicked: false,
    // },
    // {
    //     id: 'k7l8m9',
    //     title: 'Mova um lead para follow-up',
    //     description: 'Mova um lead para follow-up e veja como é fácil.',
    //     time: '1min',
    //     icon: 'funnel-plot',
    //     status: 'pending',
    //     clicked: false,
    // },
    // {
    //     id: 'n0o1p2',
    //     title: 'Mova um lead para Positivo',
    //     description: 'Mova um lead para Positivo e veja como é fácil.',
    //     time: '1min',
    //     icon: 'funnel-plot',
    //     status: 'pending',
    //     clicked: false,
    // },
    // {
    //     id: 'q3r4s5',
    //     title: 'De andamento ao lead no Dep. Societário',
    //     description: 'De andamento ao lead no Dep. Societário e veja como é fácil.',
    //     time: '2min',
    //     icon: 'pull-request',
    //     status: 'pending',
    //     clicked: false,
    // },
];