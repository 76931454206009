import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Row, Col, Input, Select, Switch, DatePicker, Button, Tabs, Icon, Divider, Tag, message } from 'antd';
import InputCurrency, { currency2Float } from '~/components/UI/InputCurrency';
import moment from 'moment';
import { methods as paymentMethods } from '../../helpers/paymentMethods'
import { PERMISSIONS } from '~/constants/permissions.constants';
import DrawerFooter from '~/components/UI/Drawer';
import Additionals from './Additionals';
import Tip from '~/components/Tip';

import * as monthlyPaymentAction from '~/containers/DrawerMonthlyPayment/actions';

const { TabPane } = Tabs;
const { Option } = Select;

const DrawerMonthlyPayment = ({
    data,
    setData,
    changeAmount,
    changePaid,
    form,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [additionals, setAdditionals] = useState([]);
    const { getFieldDecorator, getFieldValue } = form;
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    useEffect(() => {
      if (data && data.additionals) {
        setAdditionals(data.additionals);
      }else{
        setAdditionals([]);
      }
    }, [data]);

    const save = (e) => {
      e.preventDefault();
  
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
      
        if ((false && !values.payoff)) {
          message.warning('É necessário definir esta mensalidade como Quitado para continuar')
          return;
        }
  
        setIsSaving(true);
        dispatch(monthlyPaymentAction.save({
          id: data.id,
          paid: values.paid,
          amount: values.amount,
          discount: values.discount,
          interest: values.interest,
          justification: values.justification,
          paid13: values.paid13,
          amount13: values.amount13,
          payoff: values.payoff,
          method: values.method,
          paid_at: values.paid_at,
          additionals: additionals
        }))
          .then(response => {
            form.resetFields();
            setIsSaving(false);
            setData({ drawerPay: null });
          }
        );
      });
    };

    if (!data || !data.customer) {
        return null;
    }

    return(
    <Drawer
      title={`${data.customer.name} (${moment(data.due_at).format('DD/MM/YYYY')})`}
      width={700}
      closable={true}
      onClose={() => setData({ drawerPay: null })}
      visible={(data !== null)}
      >
        <Form layout="vertical">
          <Tabs type="card">
            <TabPane tab="Mensalidade" key="1">
              <Row gutter={16}>
                <Col span={12} key={'amount'}>
                  <Form.Item label={'Mensalidade'}>
                    {getFieldDecorator('amount', {
                      initialValue: data.amount,
                      rules: [{ required: true, message: 'Informe o valor da mensalidade!' }],
                      normalize: (value) => currency2Float(value),
                      onChange: changeAmount
                    })(<InputCurrency disabled={!user.permissions.includes(PERMISSIONS.financial.monthlyPayments.edit)} />)}
                  </Form.Item>
                </Col>
                <Col span={12} key={'Adicionais'}>
                  <Form.Item label={<span>Adicionais <Tip>Soma dos itens adicionais da aba "adicionais".</Tip></span>}>
                    <InputCurrency
                      allowNegative={true}
                      value={additionals.reduce((acc, cur) => acc + cur.amount, 0)}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} key={'paid'}>
                  <Form.Item label={'Valor Pago'}>
                    {getFieldDecorator('paid', {
                      initialValue: data.paid,
                      // rules: [{ required: true, message: 'Informe o valor pago!' }],
                      normalize: (value) => currency2Float(value),
                      onChange: changePaid
                    })(<InputCurrency />)}
                  </Form.Item>
                </Col>
                <Col span={12} key={'method'}>
                  <Form.Item label="Forma PGTO">
                    {getFieldDecorator(`method`, {
                      initialValue: data.method,
                    })(
                      <Select>
                        {paymentMethods.map(({ name, value }) => <Option key={value} value={value}>{name}</Option>)}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} key={'paid_at'}>
                  <Form.Item label={'Data de Pagamento?'}>
                    {getFieldDecorator('paid_at', {
                      rules: [{ required: getFieldValue('payoff'), message: 'Informe a data de pagamento!' }],
                      initialValue: data.paid_at ? moment(data.paid_at) : null
                    })(
                      <DatePicker format="DD/MM/YYYY"/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} key={'payoff'}>
                  <Form.Item label={'Quitado?'}>
                    {getFieldDecorator('payoff', {
                      valuePropName: 'checked',
                      initialValue: data.status === 'Pago',
                    })(
                      <Switch />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {data.paid ? (
                <div>
                  <Divider orientation="left">Juros</Divider>
                  <Row gutter={16}>
                    <Col span={12} key={'interest'}>
                      <Form.Item label={
                        <span>
                          Juros recebido&nbsp;
                            <Icon type="plus-circle" />
                        </span>
                      }>
                        {getFieldDecorator('interest', {
                          initialValue: data.interest,
                          normalize: (value) => currency2Float(value)
                        })(<InputCurrency disabled={1} />)}
                      </Form.Item>
                    </Col>
                    <Col span={12} key={'discount'}>
                      <Form.Item label={
                        <span>
                          Desconto cedido&nbsp;
                            <Icon type="minus-circle" />
                        </span>
                      }>
                        {getFieldDecorator('discount', {
                          initialValue: data.discount,
                          normalize: (value) => currency2Float(value)
                        })(<InputCurrency disabled={1} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : ''}
              <Divider orientation="left">Outros</Divider>
              <Row gutter={16}>
                <Col span={24} key={'justification'}>
                  <Form.Item label={
                    <span>
                      Justificativa&nbsp;
                        <Icon type="info-circle" />
                    </span>
                  }>
                    {getFieldDecorator('justification', {
                      initialValue: data.justification,
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={
              <span> Adicionais <Tag color="purple">novo</Tag></span>
            } key="3">
              <Additionals
                data={data}
                additionals={additionals}
                setAdditionals={setAdditionals}
              />
            </TabPane>
            <TabPane tab="13º Salário" key="2">
              <Row gutter={16}>
                <Col span={24} key={'amount13'}>
                  <Form.Item label={'13º Salário'}>
                    {getFieldDecorator('amount13', {
                      initialValue: data.amount13,
                      rules: [{ required: true, message: 'Por favor informe o valor da mensalidade!' }],
                      normalize: (value) => currency2Float(value)
                    })(<InputCurrency disabled={!user.permissions.includes(PERMISSIONS.financial.monthlyPayments.edit)} />)}
                  </Form.Item>
                </Col>
                <Col span={24} key={'paid13'}>
                  <Form.Item label={'Valor Pago'}>
                    {getFieldDecorator('paid13', {
                      initialValue: data.paid13,
                      rules: [{ required: true, message: 'Por favor informe o valor pago!' }],
                      normalize: (value) => currency2Float(value)
                    })(<InputCurrency />)}  
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
        <DrawerFooter>
          <Button 
            type="primary" 
            block
            disabled={isSaving}
            onClick={save}>
              {isSaving ? 'Salvando...' : 'Salvar'}
          </Button>
        </DrawerFooter>
    </Drawer>
    );
};

export default Form.create()(DrawerMonthlyPayment);