/* eslint-disable default-case */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal } from 'antd';
import { PermissionsList } from '~/containers/Permissions/styles';
import { getParentPermission, verifyIfAllPermissionsOfAParentAreChecked } from '../Permissions/handlers';
import * as usersActions from '~/components/Users/actions'
import uid from 'uid';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import axios from 'axios';
import { useImpersonate } from '~/context/Impersonate';

const { Column } = Table;

const getColorByStatus = (status) => {
  switch(status){
    case 'Ativo': return 'green';
    case 'Convidado': return 'purple';
    case 'Desativado': return 'red';
    default: return 'blue';
  }
}

const UsersList = ({ user, users, filter, actived, loadUsers, showModal, disableUser, activeUser, deleteUser }) => {

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const { impersonateUser } = useImpersonate();

  const onAction = (e, user) => {
    switch(e.key){
      case '0': 
        showModal(user);
        break;
      case '1': 
        Modal.confirm({
          title: 'Você realmente deseja desabilitar este usuário?',
          content: 'Você poderá reverter esta ação no futuro.',
          okText: 'Sim',
          okType: 'warning',
          cancelText: 'Cancelar',
          onOk: () => disableUser(user.id)
        });
        break;
      case '1.change': 
        activeUser(user.id);
        break;
      case '2':
        impersonateUser(user.id);
        break;
      case '3': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este usuário?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => deleteUser(user.id)
        });
        break;
    }
  }

  const renderPermissions = (currentPermissions) => {
    const parentPermissions = currentPermissions
      .map((item) => getParentPermission(item))
      .filter((item) => !!item)
      .sort((a, b) => a.order - b.order);

    const uniqueParentPermissions = [
      ...new Set(parentPermissions.map((item) => item.key)),
    ];
    
    return (
      <PermissionsList>
        {uniqueParentPermissions.map((currentParent) => {
          const allPermissionsIsChecked = verifyIfAllPermissionsOfAParentAreChecked(
            currentPermissions,
            currentParent
          );
          return (
            <Tag color="purple" key={uid()} style={{ marginRight: 0 }}>
              {currentParent}{' '}
              <Icon type={allPermissionsIsChecked ? 'folder' : 'folder-open'} />
            </Tag>
          );
        })}
      </PermissionsList>
    );
  };

  const filteredUsers = users.filter((user) => {
    const { name, email } = user;

    if (name.includes(filter) || email.includes(filter)) {
      return true;
    }

    return false;
  });

  let activeUsers = filteredUsers
    .filter(({ status }) => ['Ativo', 'Convidado']
    .includes(status));

  let inactiveUsers = filteredUsers
    .filter(({ status }) => ['Deletado', 'Desativado']
    .includes(status));

  // remove user if email = suporte@contlabs.com.br or name = Suporte
  activeUsers = activeUsers.filter((user) => user.email !== 'suporte@contlabs.com.br' && user.name !== 'Suporte');
  inactiveUsers = inactiveUsers.filter((user) => user.email !== 'suporte@contlabs.com.br' && user.name !== 'Suporte');

  return (  
    <div className="table-clabs">
      <Table 
        rowKey="id"
        dataSource={actived ? activeUsers : inactiveUsers} 
        pagination={getDefaultPaginationConfig({
          storageKey: STORAGE.defaultPageSize.users
        })}
        bordered={true}
        rowClassName="row-clabs"
      >
        <Column
          title="Nome"
          dataIndex="name"
          key="name"
          render={(text, record, index) => (
            <span>
              {(record.id === user.id)? <b>{record.name}</b> : record.name} {(record.id === user.id && user.tenant.owner==user.id) && (<Tag color="blue">Proprietário</Tag>)}
            </span>
          )}
        />
        <Column
          title="Email"
          dataIndex="email"
          key="email"
        />
        <Column
          title="Permissões"
          dataIndex="permissions"
          key="permissions"
          render={(permissions, record) => {
            return record.permissions_group_id ? (
              <Tag color="purple">
                {record.permissions_group_name}
              </Tag>
            ) : renderPermissions(permissions)
          }}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={status => (
              <center>
                <Tag color={getColorByStatus(status)} key={status}>{status}</Tag>
              </center>
          )}
        />
        {(users.length > 1) && (
        <Column
          dataIndex="actions"
          key="actions"
          render={(text, record, index) => (
            (record.id !== user.id) && (
            <center key={index}>
                <Dropdown overlay={
                  <Menu onClick={(e) => onAction(e, record)}>
                    <Menu.Item key="0">
                      <Icon type="form"></Icon> Permissões
                    </Menu.Item>
                    {(record.status !== 'Desativado')?
                    <Menu.Item key="1">
                      <Icon type="stop"></Icon> Desabilitar
                    </Menu.Item>
                    :
                    <Menu.Item key="1.change">
                      <Icon type="check"></Icon> Ativar
                    </Menu.Item>
                    }
                    <Menu.Item key="2">
                      <Icon type="user"></Icon> Personificar este usuário
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="3"><Icon type="delete"></Icon> Excluir</Menu.Item>
                  </Menu>
                } trigger={['click']}>
                  <a className="ant-dropdown-link" href="#">
                    Ações <Icon type="down" />
                  </a>
                </Dropdown>
            </center>
            )
          )}
        />
        )}
      </Table>
    </div>
  )
}

const mapStateToProps = state => {
  return{
    user: state.user,
    users: state.users
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadUsers: () => dispatch(usersActions.loadUsers()),
    disableUser: (id) => dispatch({
      type: 'DISABLE_USER',
      payload: {
        request:{
          method: 'patch',
          url:'/user/'+id+'/disable',
        }
      },
      id: id
    }),
    activeUser: (id) => dispatch({
      type: 'ACTIVE_USER',
      payload: {
        request:{
          method: 'patch',
          url:'/user/'+id+'/active',
        }
      },
      id: id
    }),
    deleteUser: (id) => dispatch({
      type: 'DELETE_USER',
      payload: {
        request:{
          method: 'delete',
          url:'/user/'+id,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(UsersList);