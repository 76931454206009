import update from "immutability-helper";

import { LOAD_EMAILS_LOGS } from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

const DEFAULT_STATE = {
  list: [],
  isLoading: false,
  failed: null,
};

const ACTION_HANDLERS = {
  [CACHE_ACTIONS.emailsLogs]: (state, action) => {
    return action.payload;
  },
  
  [LOAD_EMAILS_LOGS.INIT]: (state, action) => {
    return update(state, {
      isLoading: { $set: true },
    });
  },

  [LOAD_EMAILS_LOGS.SUCCESS]: (state, action) => {
    return update(state, {
      $set: {
        list: action.payload.data,
        isLoading: false,
        failed: action.payload.data ? action.payload.data.filter((log) => log.event === 'failed' || log.event === 'complained' || log.event === 'unsubscribed').length : 0,
      },
    });
  }
};

const reducer = (state = DEFAULT_STATE, action) => {
  const handler = ACTION_HANDLERS[action.type];
  if (!handler) return state;

  const newState = handler(state, action);

  const updateCache = [
    LOAD_EMAILS_LOGS.SUCCESS,
  ].includes(action.type);

  if (updateCache) {
    LocalCacheHandler.save({
      pathname: CACHE_ACTIONS.emailsLogs,
      data: newState
    });
  }

  return newState;
};

export default reducer;