import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Tree, Radio, Select } from 'antd';
import { permissionsTree } from '../Permissions/permissions-tree';
import { useAppContext } from '~/AppContext';

const FormItem = Form.Item;
const { TreeNode } = Tree;

const UsersModalForm = (props) => {
  const [permissionsType, setPermissionsType] = useState('group');
  const [isSaving, setIsSaving] = useState(false);
  const { setOnboardingTaskAsDone } = useAppContext();

  const { visible, data, form, permissionsGroups, onCancel, registerUser, savePermissions } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    if (data) {
      setPermissionsType(data.permissions_group_id ? 'group' : 'custom');
    }
  }, [visible, data]);

  const onSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const { permissions } = values;

      if (permissions) {
        values.permissions = permissions.filter(
          (item) => !item.includes('_PARENT')
        );
      }

      setIsSaving(true);

      try {
        if (data) {
          const { email, ...permissionsData } = values;
          await savePermissions(data.id, permissionsData);
        } else {
          await registerUser(values);
        }

        setOnboardingTaskAsDone('b8c9d0');

        form.resetFields();
        onCancel();
      } catch (error) {
        console.log(error);
      } finally {
        setIsSaving(false);
      }
    });
  };

  const renderTreeNodes = (permissions) => {
    return permissions.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });
  };

  const isPermissionsGroup = permissionsType === 'group';

  return (
    <Modal
      visible={visible}
      title={data ? 'Editar Permissões do Usuário' : 'Convidar novo usuário'}
      okText={data ? 'Salvar' : 'Convidar'}
      onCancel={onCancel}
      onOk={onSave}
      okButtonProps={{ loading: isSaving }}
      destroyOnClose
    >
      <Form layout="vertical">
        <FormItem label="Email">
          {getFieldDecorator('email', {
            initialValue: data ? data.email : undefined,
            rules: [
              { required: true, message: 'Por favor informe o email!' },
              { type: "email", message: 'Por favor, informe um e-mail válido!' },
            ],
          })(<Input disabled={!!data} />)}
        </FormItem>
        <FormItem
          label="Permissões"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group
            value={permissionsType}
            buttonStyle="solid"
            size="small"
            onChange={(event) => setPermissionsType(event.target.value)}
          >
            <Radio.Button value="group">Grupos</Radio.Button>
            <Radio.Button value="custom">Customizado</Radio.Button>
          </Radio.Group>
          {isPermissionsGroup
            ? getFieldDecorator('permissions_group_id', {
                initialValue: data ? data.permissions_group_name : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Selecione um grupo de permissões!',
                  },
                ],
              })(
                <Select
                  style={{ marginTop: 14 }}
                  placeholder="Selecione um grupo de permissões"
                >
                  {permissionsGroups.map((permission) => (
                    <Select.Option key={permission.id} value={permission.id}>
                      {permission.name}
                    </Select.Option>
                  ))}
                </Select>
              )
            : getFieldDecorator('permissions', {
                initialValue: data ? data.permissions : undefined,
                rules: [
                  {
                    required: true,
                    message:
                      'O usuário precisa ter pelo menos uma permissão!',
                  },
                ],
                valuePropName: 'checkedKeys',
                validateTrigger: 'onCheck',
              })(
                <Tree style={{ marginTop: 7 }} checkable>
                  {renderTreeNodes(permissionsTree)}
                </Tree>
              )}
        </FormItem>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    permissionsGroups: state.permissions.list,
    user: state.user
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    registerUser: (data) =>
      dispatch({
        type: 'REGISTER_USER',
        payload: {
          request: {
            method: 'post',
            url: '/user',
            data,
          },
        },
      }),
    savePermissions: (id, data) =>
      dispatch({
        type: 'SAVE_PERMISSIONS_USER',
        payload: {
          request: {
            method: 'patch',
            url: `/user/${id}/permissions`,
            data,
          },
        },
        id: id,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Form.create()(UsersModalForm));
