import { Button, Table, Tag, Badge } from "antd";
import moment from "moment";
import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Container, Header, Main, SearchInput } from "./styles";
import { getFormattedEvent } from "~/containers/Documents/helpers";
import { EmailsLogsModal } from "./components/EmailsLogsModal";
import { EmailBodyPreviewModal } from "./components/EmailBodyPreviewModal";
import { ModuleHeader } from '~/components/ModuleHeader';
import { STORAGE } from "~/constants/storage.constants";
import { Subtitle } from '~/components/ModuleHeader/styles';
import { normalizeToFilter } from '../KnowledgeBase/utils';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';

export const EmailsLogs = ({ refresh, setSelectedDocument }) => {
  const { pathname } = useLocation();
  const { emailsLogs, isLoading, customersById } = useSelector((state) => ({
    emailsLogs: state.emailsLogs.list,
    isLoading: state.emailsLogs.isLoading,
    customersById: state.customersById,
  }));
  const [filter, setFilter] = useState('');
  const [emailsLogsModal, setEmailsLogsModal] = useState({
    isOpen: false,
    logs: [],
  });
  const [emailBodyPreviewModal, setEmailBodyPreviewModal] = useState({
    isOpen: false,
    data: null,
    initialLogId: [],
  });
  const [isBtnErrorsSelected, setIsBtnErrorsSelected] = useState(pathname.includes('erros') ? true : false);

  const openEmailsLogsModal = useCallback((logs) => {
    setEmailsLogsModal({
      isOpen: true,
      logs,
    });
  }, []);

  const closeEmailsLogsModal = useCallback(() => {
    setEmailsLogsModal({
      isOpen: false,
      logs: [],
    });
  }, []);

  const openEmailBodyPreviewModal = useCallback(({ initialLogId, data }) => {
    setEmailBodyPreviewModal({
      isOpen: true,
      data,
      initialLogId,
    });
  }, []);

  const closeEmailBodyPreviewModal = useCallback(() => {
    setEmailBodyPreviewModal({
      isOpen: false,
      data: null,
      initialLogId: null,
    });
  }, []);

  console.log(emailsLogs);

  const filteredEmailsLogs = useMemo(() => {
    return (emailsLogs || []).map((log) => ({
      ...log,
      customer_name: (customersById[log.customer_id] && customersById[log.customer_id].name) || log.customer_name,
    })).filter((log) => {
      const customerNameMatches = normalizeToFilter(log.customer_name).includes(
        filter
      );

      const emailMatches = normalizeToFilter(log.email).includes(filter);

      return customerNameMatches || emailMatches;
    });
  }, [emailsLogs, customersById, filter]);

  const failedEmails = useMemo(() => {
    return filteredEmailsLogs.filter(
      (log) =>
        log.event === "failed" ||
        log.event === "complained" ||
        log.event === "unsubscribed"
    );
  }, [filteredEmailsLogs]);

  const formattedEmailsLogs = useMemo(() => {
    return filteredEmailsLogs.map((log) => ({
      ...log,
      formatted_sent_at: moment(log.datetime).format("DD/MM/YYYY HH:mm:ss"),
      email_status: getFormattedEvent(log.event),
    }));
  }, [filteredEmailsLogs]);

  const columns = useMemo(() => [
    {
      title: "Título",
      key: "title",
      dataIndex: "subject",
      ellipsis: true,
      width: 400,
    },
    {
      title: "Cliente",
      key: "customer",
      dataIndex: "customer_name",
      ellipsis: true,
      width: 300,
    },
    {
      title: "E-mail",
      key: "email",
      dataIndex: "email_to",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Data de envio",
      key: "formatted_sent_at",
      defaultSortOrder: "ascend",
      sorter: (a, b) => (b.sent_at || '').localeCompare(a.sent_at || ''),
      width: 200,
      dataIndex: "formatted_sent_at",
      ellipsis: true,
    },
    {
      title: "Status do e-mail",
      key: "email_status",
      dataIndex: "email_status",
      filters: [
        { text: 'Rascunho', value: 'draft' },
        { text: 'Enviado', value: 'delivered' },
        { text: 'Aberto', value: 'opened' },
        { text: 'Clicado', value: 'clicked' },
        { text: 'Marcado como spam', value: 'complained' },
        { text: 'Falhou', value: 'failed' },
        { text: 'Inscrição cancelada', value: 'unsubscribed' },
        { text: 'Envio solicitado', value: 'pendent' },
        { text: 'Blacklist', value: 'blacklist' },
      ],
      onFilter: (value, record) => record.event === value,
      width: 150,
      ellipsis: true,
      render: (status, { related_logs, customer_file_id }) => {
        if (!status) return null;
        return (
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => customer_file_id ? setSelectedDocument(customer_file_id) : openEmailsLogsModal(related_logs)}
            color={status.color}
          >
            {status.text}
          </Tag>
        );
      },
    },
    {
      title: "Prévia",
      key: "id",
      align: "center",
      width: 80,
      dataIndex: "id",
      ellipsis: true,
      render: (_, record) => {
        return (
          <Button
            icon="eye"
            onClick={() => openEmailBodyPreviewModal({ initialLogId: record.id, data: record })}
          />
        );
      },
    },
  ], [setSelectedDocument, openEmailsLogsModal]);

  const scrollX = useMemo(() => columns.reduce((total, col) => total + (col.width || 0), 0), [columns]);

  return (
    <Container>
      <Header>
        <ModuleHeader
          breadcrumbs={['Itens Enviados', 'E-mails Enviados']}
          title={
            <span>
              E-mails enviados <Subtitle>| nos últimos 7 dias</Subtitle>
            </span>
          }
          search={
            <>
              <SearchInput
                placeholder="Pesquisar pelo nome do cliente ou e-mail"
                onChange={({ target }) => setFilter(normalizeToFilter(target.value))}
              />
              <Button
                icon="sync"
                style={{ paddingLeft: 10, paddingRight: 25 }}
                onClick={refresh}
              />
            </>
          }
          actions={failedEmails.length > 0 && (
            <Badge count={failedEmails.length}>
              <Button 
                type={isBtnErrorsSelected ? 'danger' : 'default'}
                style={{ marginLeft: 10, color: isBtnErrorsSelected ? 'white' : 'red' }}
                onClick={() => setIsBtnErrorsSelected(!isBtnErrorsSelected)}>
                E-mails não enviados
              </Button>
            </Badge>
          )}
        />
      </Header>
      <Main>
        <Table
          loading={isLoading}
          rowKey="id"
          bordered
          dataSource={formattedEmailsLogs}
          scroll={{ y: "calc(100vh - 300px)", x: scrollX }}
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.emailsLogs
          })}
          columns={columns}
        />
      </Main>
      <EmailsLogsModal
        isOpen={emailsLogsModal.isOpen}
        onClose={closeEmailsLogsModal}
        logs={emailsLogsModal.logs}
      />
      <EmailBodyPreviewModal
        isOpen={emailBodyPreviewModal.isOpen}
        onClose={closeEmailBodyPreviewModal}
        initialLogId={emailBodyPreviewModal.initialLogId}
        data={emailBodyPreviewModal.data}
      />
    </Container>
  );
};
