import React from 'react';
import { Badge, Button, Dropdown, Icon, Menu, Popover, Tooltip, Input } from 'antd';
import { PERMISSIONS } from '~/constants/permissions.constants';

import { ModuleHeader, ModuleHeaderWrapper, HeaderLeftContent, HeaderRightContent } from '~/components/ModuleHeader';

const ButtonGroup = Button.Group;
const { Search } = Input;

export const SocietarioHeader = ({
    user,
    list,
    showDrawer,
    handleExportToExcel,
    openPendingDashTasksDrawer,
    screen,
    setScreen,
    setSearch
}) => {
    return (
        <div style={{
            margin: `0px -16px`,
            padding: '15px 15px',
            background: '#fff',
            marginBottom: `10px`
            }}>
            <ModuleHeader
                breadcrumbs={['Dep. Societário', 'Painel']}
                title={
                    <span>Departamento Societário <Tooltip title="Abertura, Alteração, Encerramento e Tarefas esporádicas">
                        <small><Icon type="info-circle" /></small>
                    </Tooltip>
                    </span>
                }
                search={
                    <Search
                        placeholder="Pesquisar por cód ou cliente"
                        onChange={e => setSearch(e.target.value)}
                        style={{ maxWidth: 300 }}
                    />
                }
                actions={
                    <>
                        <ButtonGroup>
                            <Tooltip title="Os tickets arquivados aparecem aqui">
                                <Button
                                    type={screen === 'archived' ? 'primary' : 'default'}
                                    style={screen === 'archived' ? { color: '#fff' } : {color: '#000'}}
                                    onClick={() => screen === 'archived' ? setScreen('active') : setScreen('archived')}
                                >
                                <Icon type="file-done" />
                                Arquivados
                                </Button>
                            </Tooltip>
                            <Tooltip title="Os leads positivos aparecem aqui como pendentes">
                                <Badge count={list.pending.length}>
                                    <Button
                                        type="primary"
                                        disabled={list.pending.length === 0}
                                        onClick={openPendingDashTasksDrawer}
                                    >
                                    <Icon type="clock-circle" />
                                    Pendentes
                                    </Button>
                                </Badge>
                            </Tooltip>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Tooltip title="Exportar para Excel">
                                <Button
                                    type="primary"
                                    onClick={handleExportToExcel}>
                                    <Icon type="download" />
                                </Button>
                            </Tooltip>
                            {user.permissions.includes(PERMISSIONS.corporate.create) && (
                                <Tooltip title="Clique para criar um novo processo">
                                    <Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu onClick={(event) => {
                                            showDrawer(parseInt(event.key), {})
                                        }}>
                                            <Menu.Item key="1">Abertura de Empresa</Menu.Item>
                                            <Menu.Item key="5">Troca de Contabilidade (entrada)</Menu.Item>
                                            <Menu.Item key="7">Troca de Contabilidade (saída)</Menu.Item>
                                            <Menu.Item key="2">Alteração Contratual</Menu.Item>
                                            <Menu.Item key="3">Encerramento de Empresa</Menu.Item>
                                            <Menu.Item key="4">Tarefa</Menu.Item>
                                        </Menu>
                                    }
                                    >
                                    <Button
                                        type="primary"
                                        style={{
                                            zIndex: 'auto'
                                        }}
                                    >
                                        <Icon type="folder-add" />
                                    </Button>
                                </Dropdown>
                            </Tooltip>
                        )}
                        </ButtonGroup>
                    </>
                }
            />
        </div>
    );
}

export default SocietarioHeader;