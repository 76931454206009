import React, { cloneElement } from 'react';
import { Icon, Tooltip } from 'antd';
import { FaWhatsapp } from 'react-icons/fa';

export const renderSourceIcon = (source) => {
  const sources = {
    form: {
      title: "Cadastro por formulário no site",
      icon: <Icon type="idcard" />,
    },
    panel: {
      title: "Cadastrado manualmente pelo painel do Contlabs",
      icon: <Icon type="experiment" />,
    },
    extension: {
      title: "Cadastrado pela extensão do WhatsApp",
      icon: <FaWhatsapp />,
    },
  };

  if (!source) return null;

  return (
    <Tooltip title={sources[source].title}>
      {cloneElement(sources[source].icon, {
        style: { fontSize: 15, color: "white" },
      })}
    </Tooltip>
  );
};
