import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { request } from '~/components/Instance';

const SettingsBasicForm = ({ form, updateUser, user }) => {
  const { getFieldDecorator } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        request('patch', '/user', values).then(response => {
          updateUser({ name: values.fullname });
        });
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 4,
      },
    },
  };

  return (
    <Form layout="horizontal" onSubmit={handleSubmit}>
      <Form.Item {...formItemLayout} label="E-mail">
        <Input value={user.email} disabled />
      </Form.Item>
      <Form.Item {...formItemLayout} label="Nome Completo">
        {getFieldDecorator('fullname', {
          initialValue: user.name,
          rules: [{ required: true, message: 'Por favor informe seu nome completo!', whitespace: true }],
        })(<Input />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">Salvar</Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (data) => dispatch({ type: 'UPDATE_USER', payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsBasicForm));