const createRequestPayload = (method, url, data = null, params = {}) => ({
  request: {
    method,
    url,
    data,
    ...params,
  },
});

export const loadDocumentsByFilter = ({ filterBy, from, to, min = 0, max = 0, ...params }) => ({
  type: "LOAD_DOCUMENTS",
  payload: createRequestPayload(
    "get",
    `/documents?filter_by=${filterBy}&from=${from}&to=${to}&min=${min}&max=${max}`,
    null,
    params
  ),
});

export const impersonateAccess = (id, data) => ({
  type: "IMPERSONATE_ACCESS",
  payload: createRequestPayload("post", `/documents/${id}/impersonateaccess/generate`, data),
});

export const changeRevised = (id, data) => ({
  type: "CHANGE_DOCUMENT_REVISED",
  payload: createRequestPayload("patch", `/documents/${id}/change-revised`, data),
  id,
});

export const generateAccessToFile = (id) => ({
  type: "GENERATE_ACCESS_TO_FILE",
  payload: createRequestPayload("get", `/documents/${id}/generate-jwt`),
});

export const archiveDocument = (id, data) => ({
  type: "ARCHIVE_DOCUMENT",
  payload: createRequestPayload("patch", `/documents/${id}/archive`, data),
});

export const createDocument = (data) => ({
  type: "CREATE_DOCUMENT",
  payload: createRequestPayload("post", "/documents", data),
});

export const createDocumentInBatch = (data) => ({
  type: "CREATE_DOCUMENT_IN_BATCH",
  payload: createRequestPayload("POST", `/documents/batch`, data),
});

export const loadDocumentTypes = (showLoading = true) => ({
  type: "LOAD_DOCUMENT_TYPES",
  payload: createRequestPayload("GET", "/documents/types", null, { showLoading }),
});

export const saveDocumentTypes = (data) => ({
  type: "SAVE_DOCUMENT_TYPES",
  payload: createRequestPayload("PUT", "/documents/types", data),
});

export const loadDocumentLogs = (id) => ({
  type: "LOAD_DOCUMENT_LOGS",
  payload: createRequestPayload("GET", `/documents/${id}/emails/logs`),
});

export const resendEmail = ({ documentId, email, copyEmails }) => ({
  type: "RESEND_DOCUMENT_EMAIL",
  payload: createRequestPayload("POST", `/documents/${documentId}/resend-email`, { email, copyEmails }),
});

export const sendEmails = (id) => ({
  type: "SEND_DOCUMENT_EMAILS",
  payload: createRequestPayload("POST", `/documents/${id}/send-emails`),
  id,
});

export const loadPendingDocuments = () => ({
  type: "LOAD_PENDING_DOCUMENTS",
  payload: createRequestPayload("GET", "/documents/pending"),
});

export const sendToWhatsapp = ({ customerFileId }) => ({
  type: "GENERATE_ACCESS_TO_FILES",
  payload: createRequestPayload("GET", `/documents/${customerFileId}/send-to-whatsapp`),
});

export const sendEmailWithLink = ({ customerFileId }) => ({
  type: "SEND_EMAIL_WITH_LINK",
  payload: createRequestPayload("POST", `/documents/${customerFileId}/send-email-with-link`),
});

export const sendEmailWithAttachment = ({ customerFileId }) => ({
  type: "SEND_EMAIL_WITH_ATTACHMENT",
  payload: createRequestPayload("POST", `/documents/${customerFileId}/send-email-with-attachment`),
});