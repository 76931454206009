import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Dropdown, Menu, Modal, Button, Input, Radio, Tooltip, Upload, message, Tag, Popover } from 'antd';
import FileDownload from 'js-file-download';
import Tour from 'reactour';

import * as customersActions from '~containers/Customer/actions';

import moment from 'moment';
import { request } from '../Instance';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { ModuleHeader } from '../ModuleHeader';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import Tip from '~/components/Tip';
import { RadioButtonWrapper } from '~/components/UI/RadioButtonWrapper';
import ImportModal from './Import.jsx';
import { getCustomersTableColumns } from './CustomersTableColumns';
import { useAppContext } from '~/AppContext';

const Search = Input.Search;

const CustomersList = (props) => {
  const [search, setSearch] = useState('');
  const [actived, setActived] = useState(1);
  const [importCSV, setImportCSV] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [steps, setSteps] = useState([
    {
      selector: '[data-tour="cadastrar-cliente"]',
      content: 'Clique aqui para cadastrar clientes, você pode cadastrar manualmente ou importar via Excel.',
    },
  ]);
  const { onboardingData, setOnboardingData } = useAppContext();

  useEffect(() => {
    const importCustomersStep = onboardingData.find(step => step.id === 'm3n4o5');
    if (importCustomersStep && importCustomersStep.status === 'pending') {
      setIsTourOpen(true);
    }
  }, [onboardingData]);

  const onAction = (e, customer) => {
    switch(e.key){
      case 'details': 
        props.showDetails(customer);
        break;
      case 'inactivate': 
        props.inactivateCustomer(customer.id);
        break;
      case 'activate': 
        props.activateCustomer(customer.id);
        break;
      case 'force_activate':
        Modal.confirm({
          title: 'Forçar ativação',
          content: <div>Este cliente será <strong>automaticamente ativado após a conclusão do processo no Dep. Societário</strong>. Você tem certeza que deseja forçar a ativação? <br />*Esta ação não poderá ser desfeita. <br/>*O cliente ativo será listado em tarefas e no módulo financeiro.</div>,
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => props.forceActivateCustomer(customer.id)
        });
        break;
      case 'edit':
        props.editCustomer(customer);
        break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este cliente?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => props.deleteCustomer(customer.id)
        });
        break;
      default:
        return;
    }
  };

  const handleCancel = () => setImportCSV(false);

  const exportToExcel = async () => {
    try {
      const response = await request(
        'POST',
        '/customers/export',
        {
          status: actived ? 'actived' : 'inactived'
        },
        true,
        {
          responseType: 'blob'
        }
      );
  
      const now = moment().format('DD_MM_YYYY_HH-mm-ss');
      FileDownload(response.data, `Clientes_${actived ? 'Ativos' : 'Inativos'}_${now}.xlsx`);
    } catch (error) {
      message.error('Não foi possível exportar os clientes para Excel. Tente novamente mais tarde.');
    }
  };

  const hasContract = (customer) => {
    return customer.contract_files.length > 0;
  };

  const hasRescission = (customer) => customer.rescission_files.length > 0;

  const styleCompanyName = (customer) => {
    if(hasRescission(customer)){
      return {textDecoration: 'line-through', color: 'red'};
    }
    if(hasContract(customer)){
      return {fontWeight: 'bold', color: '#52c41a'};
    }
    return {};
  };

  const handleCopyToClipBoard = () => {
    const domain = process.env.REACT_APP_CUSTOMER_DOMAIN;
    const link = `http://${domain}`;
    navigator.clipboard.writeText(link);
    message.success("Link da área do cliente copiado para a área de transferência!");
  };

  const {
    customers,
    user,
    isLoading,
    companyTypes
  } = props;

  let filterCompanyType = [];
  companyTypes.map(item => filterCompanyType.push({text: item.value, value: item.id}));

  const formattedCustomers = customers
    ? customers.map((customer) => {
        const { doc_delivery_method, generated_app_user, app_users } = customer;

        if (
          doc_delivery_method !== 2 ||
          !generated_app_user ||
          app_users.length === 0
        ) {
          return {
            ...customer,
            most_recent_app_user: null,
          };
        }

        return {
          ...customer,
          most_recent_app_user: app_users[0]
        };
      })
    : [];

  let filteredCustomers = formattedCustomers.filter((str) => {

    if((actived === 1 || actived === 0) && !str.enabled){
      return false;
    }

    if(actived === 2 && str.enabled){
      return false;
    }

    if((str.inactived_at === null && !actived) || (str.inactived_at !== null && actived)){
      return false;
    }

    if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    if(str.email !== null && str.email.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    if(str.identification_number !== null && str.identification_number.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    if(str.cnpj_formated !== null && str.cnpj_formated.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    if(str.cod !== null && str.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    return false;
  });

  const canCreateCustomers = user.permissions.includes(PERMISSIONS.customers.create);
  const canEditCustomers = user.permissions.includes(PERMISSIONS.customers.edit);
  const canDeleteCustomers = user.permissions.includes(PERMISSIONS.customers.delete);

  const customerWithoutHonoraries = filteredCustomers.find(({ honorary_history }) => honorary_history.length === 0);

  const onAfterOpen = (target) => {
    if (target.dataset.tour === 'import-excel') {
      const dropdownButton = document.querySelector('[data-tour="cadastrar-cliente"] .ant-dropdown-trigger');
      if (dropdownButton) {
        dropdownButton.click();
        setTimeout(() => {
          const dropdownMenu = document.querySelector('.ant-dropdown-menu');
          if (dropdownMenu) {
            dropdownMenu.style.display = 'block';
          }
        }, 100);
      }
    }
  };

  const handleTourClick = (e) => {
    if (e.target.closest('[data-tour="cadastrar-cliente"]')) {
      setIsTourOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleTourClick);
    return () => {
      document.removeEventListener('click', handleTourClick);
    };
  }, []);

  return (  
    <div>
      <ImportModal
        visible={importCSV}
        onCancel={handleCancel}
      />
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor="#722fd1"
        onAfterOpen={onAfterOpen}
      />
      <div style={{ 
        margin: `0px -16px`, 
        padding: '15px', 
        background: '#fff', 
        marginBottom: `10px` }}>
          <ModuleHeader 
            breadcrumbs={['Clientes']} 
            search={
              <Search 
                placeholder="Pesquisar clientes" 
                onChange={e => setSearch(e.target.value)}
              />
            }
            center={
              <>
                <Radio.Group 
                  value={actived}
                  style={{ display: 'flex' }}
                  onChange={(e) => setActived(e.target.value)}>
                  <Radio.Button value={1} style={actived === 1?{borderColor: `#a0d911`, color: `#a0d911`}:{}}>
                    <RadioButtonWrapper>
                      Ativos
                      <Tip>Clientes ativos.</Tip>
                    </RadioButtonWrapper>
                  </Radio.Button>
                  <Radio.Button value={2} style={actived === 2?{borderColor: `#722ed1`, color: `#722ed1`, boxShadow: `-1px 0 0 0 #722ed1`}: {}}>
                    <RadioButtonWrapper>
                        Pendentes
                        <Tip>Clientes em processo no Dep. Societário aparecem aqui. Após serem movidos para concluído serão automaticamente listados em "ativos".</Tip>
                    </RadioButtonWrapper>
                  </Radio.Button>
                  <Radio.Button value={0} style={actived === 0 ?{borderColor: `#f5222d`, color: `#f5222d`, boxShadow: `-1px 0 0 0 #f5222d`}:{}}>
                    <RadioButtonWrapper>
                      Inativos
                      <Tip>Clientes inativos, são aqueles que não são mais seus clientes, portanto não aparecem nas listagens de tarefas etc..</Tip>
                    </RadioButtonWrapper>
                    </Radio.Button>
                  </Radio.Group>
              </>
            }
            actions={
              <>
                <Button
                  type="dashed"
                  icon="copy"
                  onClick={handleCopyToClipBoard}
                >
                  Aŕea do cliente
                </Button>
                {canCreateCustomers && (
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu>
                          <Menu.Item
                            key="1"
                            onClick={() => props.editCustomer({})}
                            disabled={false}
                          >
                            Cadastro Manual
                          </Menu.Item>
                          <Menu.Item key="2" onClick={() => setImportCSV(true)} data-tour="import-excel">
                              Importar via Excel
                          </Menu.Item>
                          <Menu.Item
                            key="3"
                            onClick={exportToExcel}
                            disabled={false}
                          >
                            Exportar para Excel
                          </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button type="primary" data-tour="cadastrar-cliente">
                      Cadastrar Cliente <Icon type="down" />
                    </Button>
                </Dropdown>
                )}
              </>
            }
          />
      </div>
      <div className={`table-clabs${false ? ' onboarding-active' : ''}`}>
        <Table 
          rowKey="id"
          dataSource={filteredCustomers}
          loading={isLoading} 
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.customers
          })}
          bordered={true}
          rowClassName="row-clabs"
          scroll={{ x: actived ? 6270 : 6470, y: `${window.innerHeight - 175}px`}}
          columns={getCustomersTableColumns({
            actived,
            styleCompanyName,
            onAction,
            canEditCustomers,
            canDeleteCustomers,
            findUser: props.findUser,
            filterCompanyType,
          })}
        />
      </div>
    </div>
    )
}

const mapStateToProps = state => {
  return{
    customers: state.customers.list.filter(row => row.is_customer === true),
    isLoading: state.customers.isLoading,
    modalInactivate: state.customersnew.modalInactivate,
    findUser: (id) => state.users.filter(r=>r.id===id)[0],
    user: state.user,
    companyTypes: state.settings['leads.company_type'],
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadCustomers: () => dispatch(customersActions.loadCustomers()),
    deleteCustomer: (id) => dispatch({
      type: 'DELETE_CUSTOMER',
      payload: {
        request:{
          method: 'delete',
          url:'/customer/'+id,
        }
      },
      id: id
    }),
    inactivateCustomer: (id) => dispatch({
      type: 'INACTIVATE_CUSTOMER',
      payload: {
        request:{
          method: 'put',
          url:'/customer/'+id+'/inactivate',
        }
      },
      id: id
    }),
    activateCustomer: (id) => dispatch({
      type: 'ACTIVATE_CUSTOMER',
      payload: {
        request:{
          method: 'put',
          url:'/customer/'+id+'/activate',
        }
      },
      id: id
    }),
    forceActivateCustomer: (id) => dispatch({
      type: 'FORCE_ACTIVATE_CUSTOMER',
      payload: {
        request:{
          method: 'put',
          url:'/customer/'+id+'/force-activate',
        }
      },
      id: id
    }),
    editCustomer: (data) => dispatch(customersActions.showDrawerCustomer(data)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(CustomersList);