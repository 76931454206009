import React, { useState, useEffect } from 'react';
import { getSignupData, setSignupData, clearSignupData } from './utils/localStorage';
import { signup } from './api';

import { 
    GlobalStyle, 
    Topbar,
} from './styled';

import UserForm from './components/User';
import PasswordForm from './components/Password';
import Loading from './components/Loading';
import ErrorMessage from './components/ErrorMessage';
import MobileMessage from './components/MobileMessage';

const initialSignupData = getSignupData();

const Onboarding = () => {
    const [signupData, setSignupDataState] = useState(initialSignupData);
    const [progress, setProgress] = useState(30);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileMessage, setShowMobileMessage] = useState(false);

    const updateSignupData = (data) => {
        setSignupData(data);
        setSignupDataState(data);
    };

    useEffect(() => {
        if (progress === 100) {
            handleSignup();
        }
    }, [progress]);

    useEffect(() => {
        setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    }, []);

    const handleSignup = async () => {
        try {
            const response = await signup(signupData);
            console.log('Signup successful:', response);
            if (isMobile) {
                setShowMobileMessage(true);
            } else {
                window.location.href = response.redirect_to;
            }
        } catch (error) {
            console.error('There was an error signing up!', error);
            const errorMessage = (error.response && error.response.data && error.response.data.message) || 'Não foi possível completar o cadastro. Por favor, tente novamente mais tarde.';
            setError(errorMessage);
        }
    };

    const handleRetry = () => {
        setError(null);
        setProgress(30);
    };

    return (
        <>
            <GlobalStyle />
            <Topbar progress={progress} />
            {error && <ErrorMessage message={error} onRetry={handleRetry} />}
            {!error && !showMobileMessage && progress === 30 && (
                <UserForm 
                    setSignupData={updateSignupData} 
                    setProgress={setProgress}
                />
            )}
            {!error && !showMobileMessage && progress === 80 && (
                <PasswordForm
                    signupData={signupData}
                    setSignupData={updateSignupData} 
                    setProgress={setProgress}
                />
            )}
            {!error && !showMobileMessage && progress === 100 && <Loading />}
            {!error && showMobileMessage && <MobileMessage />}
        </>
    );
};

export default Onboarding;
