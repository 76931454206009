import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
});

export const loginWithAuth = async (auth) => {
    const tenant = auth.split(':')[0];
    try {
        const response = await instance.post('/auth/login', { auth }, {
            headers: {
                'X-Tenant': tenant,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('Email ou senha inválido!', error);
        } else {
            console.error('There was an error logging in with auth!', error);
        }
        throw error;
    }
};

export const checkEmail = async (email) => {
    try {
        const response = await instance.get('/check-email?email=' + email);
        return response.data;
    } catch (error) {
        console.error('There was an error checking the email!', error);
        throw error;
    }
};

export const login = async (email, password, tenantId) => {
    try {
        const response = await instance.post('/auth/login', { email, password }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Tenant': tenantId,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('Email ou senha inválido!', error);
        } else {
            console.error('There was an error logging in!', error);
        }
        throw error;
    }
};
