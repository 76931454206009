import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Dropdown, Icon, Popover, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { ROUTES } from '~/constants/routes.constants';
import { FaWhatsapp } from 'react-icons/fa';

const TopMenu = ({ user, isDropdownVisible, setIsDropdownVisible }) => {
  const location = useLocation();
  const isPremiumPlan = user.tenant.plan === 'premium';
  const isFreePlan = user.tenant.plan === 'free';

  return (
    <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
      <Dropdown visible={(false && location.pathname !== ROUTES["/usuarios"]) || isDropdownVisible} placement="bottomRight" onVisibleChange={(visible) => { if (false && location.pathname !== ROUTES["/usuarios"]) { return; } setIsDropdownVisible(visible); }} className="cl-menu-main-right" overlay={
        <Menu>
          <Menu.Item key="/conta">
            <Link to="/conta">
              <Icon type="user" /> Minha Conta
            </Link>
          </Menu.Item>
          {user.permissions.includes(PERMISSIONS.users.view) && (
            <Menu.Item key="/usuarios">
              <Popover content="Clique aqui para acessar o módulo Usuários" visible={false} placement="left">
                <Link to="/usuarios">
                  <Icon type="team" /> Gerenciar Usuários
                </Link>
              </Popover>
            </Menu.Item>
          )}
          {user.permissions.includes(PERMISSIONS.users.view) && (
            <Menu.Item key="/permissoes">
              <Link to="/permissoes">
                <Icon type="key" /> Gerenciar Permissões
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/whatsapp-support">
            <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5548991191788">
              <FaWhatsapp /> Suporte via Whatsapp
            </a>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="/sair">
            <Link to="/sair">
              <Icon type="logout" /> Sair
            </Link>
          </Menu.Item>
          <Menu.Divider />
          {isPremiumPlan && (
            <Menu.Item key="/">
              <center>
                <Tag color="#722ed1">Plano Premium</Tag>
              </center>
            </Menu.Item>
          )}
          {isFreePlan && (
            <Menu.Item key="/">
              <center>
                <Tag color="#1890ff">Plano Grátis</Tag>
              </center>
            </Menu.Item>
          )}
        </Menu>
      }>
        <a className="ant-dropdown-link" href="#">
          {user.name} <Icon type="down" />
        </a>
      </Dropdown>
    </div>
  );
};

export default TopMenu;
