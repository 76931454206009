const systemIndex = {
    'cod': {
        index: 'cod',
        label: 'Cód',
        group: 'Informações Básicas'
    },
    'name': {
        index: 'name',
        label: 'Razão Social',
        group: 'Informações Básicas'
    },
    'identification_number': {
        index: 'identification_number',
        label: 'CNPJ',
        group: 'Informações Básicas'
    },
    'email': {
        index: 'email',
        label: 'E-mail',
        group: 'Informações Básicas'
    },
    'phone': {
        index: 'phone',
        label: 'Telefone',
        group: 'Informações Básicas'
    },
    'city': {
        index: 'city',
        label: 'Cidade',
        group: 'Endereço'
    },
    'state': {
        index: 'state',
        label: 'Estado',
        group: 'Endereço'
    },
    'zip_code': {
        index: 'zip_code',
        label: 'CEP',
        group: 'Endereço'
    },
    'type_taxation': {
        index: 'type_taxation',
        label: 'Regime Tributário',
        group: 'Informações Básicas',
        options: [
            { value: 'Doméstica', label: 'Doméstica' },
            { value: 'Lucro Presumido', label: 'Lucro Presumido' },
            { value: 'Lucro Real', label: 'Lucro Real' },
            { value: 'MEI', label: 'MEI' },
            { value: 'Profissional Liberal', label: 'Profissional Liberal' },
            { value: 'Simples Nacional', label: 'Simples Nacional' }
        ]
    },
    'postal_code': {
        index: 'postal_code',
        label: 'Código Postal',
        group: 'Endereço'
    },
    'street': {
        index: 'street',
        label: 'Rua',
        group: 'Endereço'
    },
    'street_number': {
        index: 'street_number',
        label: 'Número da Rua',
        group: 'Endereço'
    },
    'neighborhood': {
        index: 'neighborhood',
        label: 'Bairro',
        group: 'Endereço'
    },
    'complement': {
        index: 'complement',
        label: 'Complemento',
        group: 'Endereço'
    },
    'billing': {
        index: 'billing',
        label: 'Faturamento',
        group: 'Informações Financeiras'
    },
    'nfs_input': {
        index: 'nfs_input',
        label: 'NFs Entrada',
        group: 'Informações Financeiras'
    },
    'nfs_output': {
        index: 'nfs_output',
        label: 'NFs Saída',
        group: 'Informações Financeiras'
    },
    'accounting_postings': {
        index: 'accounting_postings',
        label: 'Lançamentos Contábeis',
        group: 'Informações Financeiras'
    },
    'n_staffs': {
        index: 'n_staffs',
        label: 'Número de Funcionários',
        group: 'Outras Informações'
    },
    'honorary_agreed': {
        index: 'honorary_agreed',
        label: 'Honorário',
        group: 'Informações Financeiras'
    },
    'last_accounting_name': {
        index: 'last_accounting_name',
        label: 'Antiga contabilidade',
        group: 'Outras Informações'
    },
    'gov_login': {
        index: 'gov_login',
        label: 'Login do Governo',
        group: 'Outras Informações'
    },
    'gov_password': {
        index: 'gov_password',
        label: 'Senha do Governo',
        group: 'Outras Informações'
    },
    'registered_at': {
        index: 'registered_at',
        label: 'Data do cadastro',
        group: 'Outras Informações'
    },
};

export { systemIndex };
