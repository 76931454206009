import { AccountStorage } from '~/storages/account';
import { LocalCacheHandler } from './local-cache-handler';

export const handleLogout = () => {
  LocalCacheHandler.clear()
    .then(() => {
      AccountStorage.remove();
      localStorage.removeItem('impersonatedUser');
      document.cookie =
        'Contlabs:ApiToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'Contlabs:Domain=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    })
    .catch((error) => {
      console.log(error);
    });
};
