import React from 'react';
import { Form, Icon } from 'antd';
import { getSignupData } from '../utils/localStorage';
import {
    Header,
    TextTitle,
    Title,
    Subtitle,
    Content,
    StyledInput,
    Footer,
    StyledButton,
} from '../styled';

const UserForm = (
    { form: { getFieldDecorator, validateFields }, setSignupData, setProgress }
) => {
    
    const handleSubmit = (e) => {
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                const existingData = getSignupData();
                const updatedData = { ...existingData, ...values };
                setSignupData(updatedData);
                setProgress(80);
            }
        });
    };

    const initialSignupData = getSignupData();

    return (
        <>
            <Header>
                <TextTitle>
                    <Title>Cadastre-se gratuitamente</Title>
                    <Subtitle>Comece a utilizar em segundos</Subtitle>
                </TextTitle>
            </Header>
            <Content style={{ width: '100%' }}>
                <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Form.Item style={{ width: '100%' }}>
                        {getFieldDecorator('company_name', {
                            initialValue: initialSignupData.company_name,
                            rules: [{ required: true, message: 'Por favor, insira o nome da empresa!' }],
                        })(
                            <StyledInput
                                type="text"
                                placeholder="Nome da Empresa"
                                prefix={<Icon type="bank" />}
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item style={{ width: '100%' }}>
                        {getFieldDecorator('name', {
                            initialValue: initialSignupData.name,
                            rules: [{ required: true, message: 'Por favor, insira seu nome completo!' }],
                        })(
                            <StyledInput type="text" placeholder="Nome completo" prefix={<Icon type="user" />} style={{ width: '100%' }} />
                        )}
                    </Form.Item>
                    <Form.Item style={{ width: '100%' }}>
                        {getFieldDecorator('email', {
                            initialValue: initialSignupData.email,
                            rules: [{ required: true, message: 'Por favor, insira seu email!' }],
                        })(
                            <StyledInput type="email" placeholder="Email" prefix={<Icon type="mail" />} style={{ width: '100%' }} />
                        )}
                    </Form.Item>
                    <Form.Item style={{ width: '100%' }}>
                        {getFieldDecorator('whatsapp', {
                            initialValue: initialSignupData.whatsapp,
                            rules: [{ required: true, message: 'Por favor, insira seu número de WhatsApp!' }],
                        })(
                            <StyledInput
                                type="text"
                                placeholder="Número de WhatsApp"
                                prefix={<Icon type="phone" />}
                                style={{ width: '100%' }}
                                maxLength={15}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const formattedValue = value
                                        .replace(/\D/g, '')
                                        .replace(/^(\d{2})(\d)/g, '($1) $2')
                                        .replace(/(\d{4,5})(\d{4})$/, '$1-$2');
                                    e.target.value = formattedValue;
                                }}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Content>
            <Footer>
                <StyledButton type="primary" onClick={handleSubmit}>
                    Continuar <Icon type="arrow-right" />
                </StyledButton>
            </Footer>
        </>
    )
};

export default Form.create({ name: 'onboarding' })(UserForm);