import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';

const ChangePasswordForm = ({ form, changePassword }) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [currentPassword, setCurrentPassword] = useState({});
  const { getFieldDecorator } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPassword({});
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        changePassword(values)
          .then(response => {
            form.resetFields();
          })
          .catch(response => {
            setCurrentPassword({
              validateStatus: 'error',
              help: response.error.response.data.errors.current_password[0]
            });
          });
      }
    });
  };

  const handleConfirmBlur = (e) => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('As senhas não conferem!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['password_confirm'], { force: true });
    }
    callback();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 4,
      },
    },
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item {...formItemLayout} {...currentPassword} label="Senha Atual">
        {getFieldDecorator('current_password', {
          rules: [{ required: true, message: 'Por favor informe sua senha atual!' }],
        })(<Input type="password" />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Nova Senha">
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: 'Por favor informe uma senha!' },
            { validator: validateToNextPassword },
          ],
        })(<Input type="password" />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Confirmar Senha">
        {getFieldDecorator('password_confirm', {
          rules: [
            { required: true, message: 'Por favor repita a senha!' },
            { validator: compareToFirstPassword },
          ],
        })(<Input type="password" onBlur={handleConfirmBlur} />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">Salvar</Button>
      </Form.Item>
    </Form>
  );
};

const mapDispatchProps = dispatch => {
  return {
    changePassword: (data) => dispatch({
      type: 'CHANGE_PASSWORD',
      payload: {
        request: {
          method: 'patch',
          url: '/user/password',
          data: data
        }
      }
    })
  };
};

export default connect(null, mapDispatchProps)(Form.create()(ChangePasswordForm));