import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Input, Icon, Modal } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uid from 'uid';
import styled from 'styled-components';
import { DrawerFooter } from '~/components/UI/Drawer';
import { useDispatch } from 'react-redux';
import * as settingActions from '~/containers/Settings/actions';

const ActivityWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ActivityItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
`;

const SocialSellingDrawer = Form.create()(({ visible, onClose, form, selectedCadence }) => {
    const [cadenceName, setCadenceName] = useState(null);
    const [cadenceData, setCadenceData] = useState([]);
    const [scriptModalVisible, setScriptModalVisible] = useState(false);
    const [currentScriptIndex, setCurrentScriptIndex] = useState(null);
    const [scriptText, setScriptText] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedCadence) {
            const d = JSON.parse(selectedCadence.value);
            setCadenceName(d.name);
            setCadenceData(d.data);
        } else {
            setCadenceName(null);
            setCadenceData([]);
            form.resetFields();
        }
    }, [selectedCadence]);

    const handleSave = () => {
        form.validateFields((err, values) => {
            if (!err) {
                const newCadence = {
                    name: values.name,
                    data: values.data,
                };
                const data = JSON.stringify(newCadence);
                const key = 'socialSelling.cadence';

                if (selectedCadence && selectedCadence.id !== undefined) {
                    dispatch(settingActions.updateSetting({ data: { key, value: data }, id: selectedCadence.id }));
                } else {
                    dispatch(settingActions.createSetting({ data: { key, value: data } }));
                }
                onClose();
            }
        });
    };

    const addAtividade = () => {
        const newData = [...cadenceData, { uid: uid(), status: 'pending' }];
        setCadenceData(newData);
        form.setFieldsValue({ data: newData });
    };

    const deleteAtividade = (index) => {
        const newData = cadenceData.filter((_, i) => i !== index);
        setCadenceData(newData);
        form.setFieldsValue({ data: newData });
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const updatedCadenceData = Array.from(cadenceData);
        const [reorderedItem] = updatedCadenceData.splice(result.source.index, 1);
        updatedCadenceData.splice(result.destination.index, 0, reorderedItem);

        setCadenceData(updatedCadenceData);
        form.setFieldsValue({ data: updatedCadenceData });
    };

    const handleSaveScript = () => {
        const newData = [...cadenceData];
        newData[currentScriptIndex].script = scriptText;
        setCadenceData(newData);
        form.setFieldsValue({ data: newData });
        setScriptModalVisible(false);
        setScriptText('');
    };

    const openScriptModal = (index) => {
        setCurrentScriptIndex(index);
        setScriptText(cadenceData[index].script || '');
        setScriptModalVisible(true);
    };

    return (
        <Drawer
            title={cadenceName ? "Editar Cadência" : "Criar Cadência"}
            width={900}
            visible={visible}
            onClose={onClose}
        >
            <Form layout="vertical">
                <Form.Item label="Nome da cadência">
                    {form.getFieldDecorator('name', {
                        initialValue: cadenceName,
                        rules: [{ required: true, message: 'Por favor, insira o nome da cadência!' }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Atividades">
                    {form.getFieldDecorator('data', {
                        initialValue: cadenceData,
                    })(
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="cadenceData">
                                {(provided) => (
                                    <ActivityWrapper ref={provided.innerRef} {...provided.droppableProps}>
                                        {cadenceData.map((item, index) => (
                                            <Draggable key={item.uid} draggableId={item.uid.toString()} index={index}>
                                                {(provided) => (
                                                    <ActivityItem
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Form.Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                            {form.getFieldDecorator(`day_${index}`, {
                                                                initialValue: item.day,
                                                                rules: [{ required: true, message: 'Por favor, insira um número de dia' }],
                                                            })(
                                                                <Input
                                                                    addonBefore="No dia"
                                                                    type="number"
                                                                    placeholder="Dia"
                                                                    onChange={(e) => {
                                                                        const data = Array.isArray(form.getFieldValue('data')) ? form.getFieldValue('data') : [];
                                                                        if (!data[index]) {
                                                                            data[index] = {};
                                                                        }
                                                                        data[index].day = parseInt(e.target.value);
                                                                        form.setFieldsValue({ data });
                                                                    }}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                            {form.getFieldDecorator(`type_${index}`, {
                                                                initialValue: item.type,
                                                                rules: [{ required: true, message: 'Por favor, insira um tipo' }],
                                                            })(
                                                                <Input
                                                                    placeholder="Atividade"
                                                                    onChange={(e) => {
                                                                        const data = Array.isArray(form.getFieldValue('data')) ? form.getFieldValue('data') : [];
                                                                        if (!data[index]) {
                                                                            data[index] = {};
                                                                        }
                                                                        data[index].type = e.target.value;
                                                                        form.setFieldsValue({ data });
                                                                    }}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <Button type="default" style={{ color: item.script ? 'black' : 'red' }} onClick={() => openScriptModal(index)}>
                                                            {item.script ? 'Script: '+item.script.substring(0, 10) + '...' : 'Definir script'}
                                                        </Button>
                                                        <Button type="danger" onClick={() => deleteAtividade(index)}>
                                                            <Icon type="delete" />
                                                        </Button>
                                                    </ActivityItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ActivityWrapper>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </Form.Item>
                <Form.Item style={{ marginTop: 16 }}>
                    <Button type="dashed" onClick={addAtividade} block>
                        Adicionar Atividade
                    </Button>
                </Form.Item>
            </Form>
            <DrawerFooter>
                <Button onClick={onClose} style={{ marginRight: 8 }}>Cancelar</Button>
                <Button onClick={handleSave} type="primary">Salvar</Button>
            </DrawerFooter>
            <Modal
                title="Definir Script"
                visible={scriptModalVisible}
                onOk={handleSaveScript}
                onCancel={() => setScriptModalVisible(false)}
            >
                <Input.TextArea
                    rows={10}
                    value={scriptText}
                    onChange={(e) => setScriptText(e.target.value)}
                />
            </Modal>
        </Drawer>
    );
});

export default SocialSellingDrawer;