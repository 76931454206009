import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
`;

const MessageIcon = styled(Icon)`
    font-size: 48px;
    color: #7f00ff;
    margin-bottom: 20px;
`;

const MessageText = styled.p`
    font-size: 18px;
    color: #333;
`;

const MobileMessage = () => {
    return (
        <MessageContainer>
            <MessageIcon type="info-circle" />
            <MessageText>
                Continue no computador para acessar o Contlabs. Enviamos o link de acesso para o seu email.
            </MessageText>
        </MessageContainer>
    );
};

export default MobileMessage;
