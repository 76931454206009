import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import * as serviceWorker from './serviceWorker';
import axiosMiddleware from 'redux-axios-middleware';
import { client, options } from './components/Instance';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import 'moment/locale/pt-br';
import * as todoApp from './store/reducers';
import 'antd/dist/antd.css';
import './components/UI/IziToast/style.css';
import 'swiper/swiper-bundle.css'
import './components/UI/Layout/customAntStyles.css';

// Sentry configuration
// if (process.env.NODE_ENV !== 'development') {
//   Sentry.init({
//     dsn: 'https://3d348b0247b1b84884312d99942e37ca@o4507178518970368.ingest.sentry.io/4507218736709632',
//     integrations: [
//       Sentry.captureConsoleIntegration(),
//       // browserTracingIntegration is not needed to be explicitly added in v7.x
//     ],
//     tracesSampleRate: 1.0,
//     tracingOrigins: [/^https:\/\/ajcontabil\.contlabs\.com\.br/], // Use tracingOrigins instead of tracePropagationTargets
//   });
// }

moment.locale('pt-br');

export const ApplicationVersion = 'v1.38.1';

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const store = createStore(
    combineReducers(todoApp), 
    composeEnhancers(applyMiddleware(axiosMiddleware(client, options))), 
);

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={pt_BR}>
            <Routes />
        </ConfigProvider>
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();