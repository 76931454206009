import { 
    LOAD_CALENDAR_TASKS_MONTH, 
    CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST, 
    CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID,
    UPDATE_TASK_CUSTOMERS,
    UPDATE_TASK_STATS
} from './actions';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const getExecutedTasksCount = (customers, currentMonth = null) => {
    return customers
        .map((customer) => currentMonth ? customer.months[currentMonth] : customer)
        .filter((customer) => customer.executed_at)
        .length;
};

const getPriorityTasksCount = (customers, currentMonth) => {
    return customers
        .map((customer) => customer.months[currentMonth])
        .filter((customer) => customer.priority)
        .length;
};

const calendarTasks = (state = {}, action) => {
    let newState;
    switch (action.type) {
        case CACHE_ACTIONS.calendarTasks:
            newState = action.payload;
            break;

        case LOAD_CALENDAR_TASKS_MONTH.INIT: {
            const departmentId = action.id;
            const date = action.date.format('YYYY/MM');
            newState = {
                ...state,
                [departmentId]: {
                    ...state[departmentId],
                    [date]: { loading: true, data: [] }
                }
            };
            break;
        }

        case LOAD_CALENDAR_TASKS_MONTH.SUCCESS: {
            const departmentId = action.meta.previousAction.id;
            const date = action.meta.previousAction.date.format('YYYY/MM');
            newState = {
                ...state,
                [departmentId]: {
                    ...state[departmentId],
                    [date]: { loading: false, data: action.payload.data }
                }
            };
            break;
        }

        case CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST.SUCCESS: {
            const { customers, taskId } = action.payload;
            const departmentId = action.meta.previousAction.id;
            const date = action.meta.previousAction.date.format('YYYY/MM');

            if (!customers) return state;

            const executedListTasks = getExecutedTasksCount(customers);

            newState = {
                ...state,
                [departmentId]: {
                    ...state[departmentId],
                    [date]: {
                        ...state[departmentId][date],
                        data: state[departmentId][date].data.map((currentTask) => 
                            currentTask.id !== taskId ? currentTask : {
                                ...currentTask,
                                executed_tasks_count: executedListTasks,
                                pendent_tasks_count: currentTask.tasks_count - executedListTasks
                            }
                        )
                    }
                }
            };
            break;
        }

        case CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID.SUCCESS: {
            const { customers, currentMonth, taskId } = action.payload;
            const departmentId = action.meta.previousAction.id;
            const date = action.meta.previousAction.date.format('YYYY/MM');

            const executedGridTasks = getExecutedTasksCount(customers, currentMonth);
            const priorityTasksCount = getPriorityTasksCount(customers, currentMonth);

            newState = {
                ...state,
                [departmentId]: {
                    ...state[departmentId],
                    [date]: {
                        ...state[departmentId][date],
                        data: state[departmentId][date].data.map((currentTask) => 
                            currentTask.id !== taskId ? currentTask : {
                                ...currentTask,
                                executed_tasks_count: executedGridTasks,
                                pendent_tasks_count: currentTask.tasks_count - executedGridTasks,
                                priority_tasks_count: priorityTasksCount
                            }
                        )
                    }
                }
            };
            break;
        }

        case UPDATE_TASK_CUSTOMERS.SUCCESS: {
            const { departmentId, date, taskId, customers } = action.payload;
            const formattedDate = date.format('YYYY/MM');

            console.log('UPDATE_TASK_CUSTOMERS.SUCCESS', action.payload);
            console.log('state', state);
            
            // Make sure the department and date exist in state
            if (!state[departmentId] || !state[departmentId][formattedDate]) {
                return state;
            }
            
            // Update the specific task's customers
            newState = {
                ...state,
                [departmentId]: {
                    ...state[departmentId],
                    [formattedDate]: {
                        ...state[departmentId][formattedDate],
                        data: state[departmentId][formattedDate].data.map(task => 
                            task.id === taskId 
                                ? { ...task, customers } 
                                : task
                        )
                    }
                }
            };
            break;
        }

        case UPDATE_TASK_STATS.SUCCESS: {
            const { departmentId, date, taskId, stats } = action.payload;
            const formattedDate = date.format('YYYY/MM');
            
            // Make sure the department and date exist in state
            if (!state[departmentId] || !state[departmentId][formattedDate]) {
                return state;
            }

            // Update the specific task's statistics
            newState = {
                ...state,
                [departmentId]: {
                    ...state[departmentId],
                    [formattedDate]: {
                        ...state[departmentId][formattedDate],
                        data: state[departmentId][formattedDate].data.map(task => 
                            task.id === taskId 
                                ? { ...task, ...stats } 
                                : task
                        )
                    }
                }
            };
            break;
        }

        default:
            return state;
    }

    const updateCache = [
        LOAD_CALENDAR_TASKS_MONTH.SUCCESS,
        CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST.SUCCESS,
        CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID.SUCCESS,
        UPDATE_TASK_CUSTOMERS.SUCCESS,
        UPDATE_TASK_STATS.SUCCESS
    ].includes(action.type);

    if (updateCache) {
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.calendarTasks,
            data: newState
        });
    }

    return newState;
};

export default calendarTasks;