import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, TextField, ThemeProvider, CircularProgress, Snackbar } from '@material-ui/core';

import logo from './logo-color.png';

import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { materialUITheme } from '~/themes/material-ui.theme';
import { MaterialUIFormControl } from '~/components/UI/Material/FormControl';
import { AccountStorage } from '~/storages/account';
import { loginWithAuth, login, checkEmail } from './api';
import { Container, Form, Logo, LoadingContainer, ForgotPasswordLink } from './styles';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [isCheckingEmail, setIsCheckingEmail] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const auth = params.get('auth');
        const message = params.get('m');
        if (message) {
            setSuccessMessage(message);
        }
        if (auth) {
            setIsAuthenticating(true);
            loginWithAuth(auth)
                .then(response => {
                    if (response.status === 'success') {
                        AccountStorage.set({ token: response.api_token, user: response.user });
                        window.location.href = '/';
                    } else {
                        setErrorMessage(response.message || DEFAULT_MESSAGES.LOGIN_ERROR);
                        setIsAuthenticating(false);
                    }
                })
                .catch(error => {
                    setErrorMessage(DEFAULT_MESSAGES.LOGIN_ERROR);
                    setIsAuthenticating(false);
                });
        }
    }, [history]);

    const handleEmailBlur = async () => {
        setIsCheckingEmail(true);
        try {
            const data = await checkEmail(email);
            if (data.length === 0) {
                setEmailError('Email inválido ou não cadastrado');
                setSelectedTenant('');
                setTenants([]);
            } else if (data.length === 1) {
                setSelectedTenant(data[0].tenant_id);
                setEmailError('');
            } else {
                setTenants(data);
                setEmailError('');
            }
        } catch (error) {
            setErrorMessage(DEFAULT_MESSAGES.LOGIN_ERROR);
        } finally {
            setIsCheckingEmail(false);
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            setErrorMessage('Por favor, preencha o email e a senha.');
            return;
        }
        setIsAuthenticating(true);
        try {
            const data = await login(email, password, selectedTenant);
            AccountStorage.set({ token: data.api_token, user: data.user });
            console.log('data', data);
            window.location.href = '/';
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage('Email ou senha inválido!');
            } else {
                setErrorMessage(DEFAULT_MESSAGES.LOGIN_ERROR);
            }
        } finally {
            setIsAuthenticating(false);
        }
    };

    const handleCloseSnackbar = () => {
        setErrorMessage('');
        setSuccessMessage('');
    };

    if (isAuthenticating) {
        return (
            <ThemeProvider theme={materialUITheme}>
                <Container>
                    <LoadingContainer>
                        <CircularProgress />
                        <p style={{marginTop: '5px'}}>Autenticando...</p>
                    </LoadingContainer>
                </Container>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={materialUITheme}>
            <Container>
                <Logo src={logo} alt="Logo" />
                <Form onSubmit={handleLogin}>
                    <MaterialUIFormControl>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.trim())}
                            onBlur={handleEmailBlur}
                            fullWidth
                            error={!!emailError}
                            helperText={emailError}
                        />
                    </MaterialUIFormControl>
                    {tenants.length > 1 && (
                        <MaterialUIFormControl>
                            <TextField
                                select
                                label="Empresa"
                                value={selectedTenant}
                                onChange={(e) => setSelectedTenant(e.target.value)}
                                fullWidth
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="" disabled>
                                    Selecione a empresa
                                </option>
                                {tenants.map((tenant) => (
                                    <option key={tenant.tenant_id} value={tenant.tenant_id}>
                                        {tenant.tenant_name}
                                    </option>
                                ))}
                            </TextField>
                        </MaterialUIFormControl>
                    )}
                    <MaterialUIFormControl>
                        <TextField
                            label="Senha"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                        />
                    </MaterialUIFormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!selectedTenant || isCheckingEmail || isAuthenticating}
                    >
                        {isCheckingEmail ? 'VERIFICANDO...' : (isAuthenticating ? 'ENTRANDO...' : 'ENTRAR')}
                    </Button>
                </Form>
                <ForgotPasswordLink to="/esqueci-a-senha">Esqueceu a senha?</ForgotPasswordLink>
                <Snackbar
                    open={!!errorMessage || !!successMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    ContentProps={{
                        style: { backgroundColor: errorMessage ? 'red' : 'green' }
                    }}
                    message={errorMessage || successMessage}
                />
            </Container>
        </ThemeProvider>
    );
};

export default LoginPage;
