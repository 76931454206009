import React from 'react';
import { Input, Button, Card } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

import logo from './logo.png';

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap"');

    body {
        font-family: Fire Sans, sans-serif;
    }
`;

export const Topbar = styled.div`
    position: relative;
    height: 70px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background-color: #7f00ff;
        background-image: linear-gradient(90deg, #7f00ff 0%, #e100ff 100%);
        width: ${(props) => props.progress}%;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        button {
            display: none;
        }
    }

    transform: translateX(20px);
    animation: fadeInRight 0.5s ease-in-out forwards;

    @keyframes fadeInRight {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: translateX(20px);
    animation: fadeInRight 0.5s ease-in-out forwards;

    @keyframes fadeInRight {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(20px);
    animation: fadeInRight 0.5s ease-in-out forwards;

    @keyframes fadeInRight {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;

export const TextTitle = styled.div`
    text-align: left;
`;

export const StyledCard = styled(Card)`
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    font-family: Fire Sans, sans-serif;
`;

export const Title = styled.h1`
    text-align: left;
    margin-bottom: 5px;
    font-size: 30px;
    font-family: Fire Sans, sans-serif;
    font-weight: 600;
    line-height: 1;
`;

export const Subtitle = styled.p`
    text-align: left;
    font-family: Fire Sans, sans-serif;
    font-size: 16px;
    color: #666;
    font-weight: 400;
`;

export const StyledInput = styled(Input)`
    height: 50px !important;
    font-family: Fire Sans, sans-serif;
`;

export const StyledButton = styled(Button)`
    height: 50px !important;
    background-color: #7f00ff !important;
    border-color: #7f00ff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    font-family: Fire Sans, sans-serif;
    font-weight: 600 !important;
    font-size: 18px !important;
    &:hover {
        background-color: #7f00ff !important;
        border-color: #7f00ff !important;
    }
    &:focus {
        background-color: #7f00ff !important;
        border-color: #7f00ff !important;
    }
    &:active {
        background-color: #7f00ff !important;
        border-color: #7f00ff !important;
    }
`;