import update from 'immutability-helper';
import { get } from 'lodash';
import { LocalCacheHandler } from '~/utils/local-cache-handler';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const DEFAULT_STATE = {
  list: [],
  types: [],
  isLoading: true
};

const updateDocumentList = (state, updatedFile) => update(state, {
  list: {
    $set: state.list.map((file) => (file.id !== updatedFile.id ? file : { ...file, ...updatedFile })),
  },
});

const reducer = (state = DEFAULT_STATE, action) => {
  let newState;
  switch (action.type) {
    case CACHE_ACTIONS.documents:
      newState = action.payload;
      break;

    case "LOAD_DOCUMENTS_SUCCESS":
      newState = update(state, {
        list: { $set: action.payload.data.data },
        isLoading: { $set: false },
      });
      break;

    case 'IMPERSONATE_ACCESS_SUCCESS':
      return state;

    case 'CHANGE_DOCUMENT_REVISED_SUCCESS': {
      const fileId = action.meta.previousAction.payload.id;
      const updatedFile = {
        id: fileId,
        revised_at: action.payload.data.data.revised_at,
        revised_by: action.payload.data.data.revised_by,
      };
      newState = updateDocumentList(state, updatedFile);
      break;
    }

    case 'ARCHIVE_DOCUMENT_SUCCESS':
      newState = updateDocumentList(state, action.payload.data.document);
      break;

    case 'LOAD_DOCUMENT_TYPES_SUCCESS':
    case 'SAVE_DOCUMENT_TYPES_SUCCESS':
      newState = update(state, {
        types: { $set: get(action, 'payload.data.types') },
      });
      break;

    case 'SEND_DOCUMENT_EMAILS_SUCCESS': {
      const updatedId = action.meta.previousAction.payload.id;
      const updatedFile = { id: updatedId, email_status: 'pendent' };
      newState = updateDocumentList(state, updatedFile);
      break;
    }

    default:
      newState = state;
  }

  const updateCache = [
    CACHE_ACTIONS.documents,
    "LOAD_DOCUMENTS_SUCCESS",
    'CHANGE_DOCUMENT_REVISED_SUCCESS',
    'ARCHIVE_DOCUMENT_SUCCESS',
    'LOAD_DOCUMENT_TYPES_SUCCESS',
    'SAVE_DOCUMENT_TYPES_SUCCESS',
    'SEND_DOCUMENT_EMAILS_SUCCESS'
  ].includes(action.type);

  if (updateCache) {
    LocalCacheHandler.save({
      pathname: CACHE_ACTIONS.documents,
      data: newState
    });
  }

  return newState;
};

export default reducer;
