import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
});

export const requestPasswordReset = async (email, tenantId) => {
    try {
        const response = await instance.post('/auth/recover', { email }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Tenant': tenantId,
            },
        });
        return response.data;
    } catch (error) {
        console.error('There was an error requesting the password reset!', error);
        throw error;
    }
};

export const resetPassword = async (token, password, tenantId) => {
    try {
        const response = await instance.post('/auth/newpassword', { token, newPassword: password }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Tenant': tenantId,
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (error) {
        const errorMessage = (error.response && error.response.data && error.response.data.message) || 'There was an error resetting the password!';
        console.error(errorMessage, error);
        throw new Error(errorMessage);
    }
};
