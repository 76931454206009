import { Icon, Progress, Tag } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Roboto';
  user-select: none;
  margin: 0 0 8px 0;
  border-radius: 5px 5px 1px 1px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background: #efefef;
  padding-top: 8px;
`;

export const LeftTopContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  position: absolute;
  left: 0;
  top: 0;
`;

export const RightTopContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  position: absolute;
  right: 5px;
  top: 0;
`;

export const SourceIconContainer = styled.div`
  background-color: #595959;
  padding: 1px 8px;
  border-radius: 6.25px 0px;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const CalendarIcon = styled(Icon).attrs({
  type: 'calendar',
  style: {
    color: 'black',
  },
})``;

export const Date = styled.span`
  font-size: 12px;
`;

export const CopyButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  position: absolute;
  padding: 0;
  right: 8px;
  top: 8px;
`;

export const CopyIcon = styled(Icon).attrs({
  type: 'copy',
  style: {
    color: 'black',
  },
})``;

export const Content = styled.div`
  margin: 0px 8px;
  height: 80px;
`;

export const MainContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

export const LeadInfo = styled.div``;

export const LeadName = styled.div`
  font-size: 16px;
  display: block;
  margin-top: 5px;
`;

export const Value = styled.span`
  color: #118632;
  float: right;
  margin-top: 4px;
  font-size: 12px;
`;

export const RTName = styled.span`
  font-size: 12px;
  color: #595959;
  display: block;
`;

export const CadenceContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;