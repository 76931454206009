import React, { useEffect, useState, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import {
  clearAllDepartmentsCalendarState,
  loadAllDepartmentsCalendarTasks,
} from "./_domains/calendarTasks/actions";
import { loadCertificates } from "./components/Certificates/actions";
import { loadConventions } from "./components/Conventions/actions";
import { loadProcurations } from "./components/Procurations/actions";
import { loadTasks } from "./components/RegisterTasks/actions";
import { loadUser, loadUsers } from "./components/Users/actions";
import { ROUTES } from "./constants/routes.constants";
import { loadChecklistTasks } from './containers/Checklist/actions';
import { loadCustomers } from "./containers/Customer/actions";
import { loadDocumentTypes } from "./containers/Documents/actions";
import { loadLinkCategories, loadLinks } from "./containers/Links/actions";
import { loadPermissionsGroups } from "./containers/Permissions/actions";
import { loadEmailsLogs } from "./containers/EmailsLogs/actions";
import { loadSettings } from "./containers/Settings/actions";
import { AccountStorage } from './storages/account';
import { handleLogout } from './utils/handle-logout';
import { CACHE_ACTIONS } from './constants/cache-actions.constants';
import { loadPayments } from "~/screens/Financial/MonthlyPayment/actions";
import { loadSolicitations } from "~/_domains/solicitations/actions";
import { data as defaultOnboardingData } from './_domains/onboarding/StartHereGraphic/guide';
import { updateOnboardingStep } from './_domains/onboarding/actions';

export const DataContext = createContext();

export const AppContext = ({ children, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state);
  const onboardingData = user.onboarding_status || defaultOnboardingData;
  const [unlockedMenus, setUnlockedMenus] = useState([]);

  useEffect(() => {
    if (user.id) {
      const unlockedMenusDone = user.onboarding_status
        .filter(task => task.status === 'done')
        .flatMap(task => task.unlockMenus || []);
      setUnlockedMenus(unlockedMenusDone);
    }
  }, [user]);

  async function loadInitialCache() {
    
    const cacheActionKeys = Object.keys(CACHE_ACTIONS);

    await Promise.all(cacheActionKeys.map(async (key) => {
      const actionName = CACHE_ACTIONS[key];
      const pathname = CACHE_ACTIONS[key];

      await LocalCacheHandler.load({
        actionName,
        dispatch,
        pathname,
      });
    }));

  }

  // function refreshEach5Minutes() {
  //   setInterval(() => {
  //     dispatch(loadSolicitations());
  //   }, 600000); // 10 minutes
  // }

  // useEffect(() => {
  //   refreshEach5Minutes();
  // }, []);

  function getLoaders() {
    const loadersToCall = [];
    const loaders = [
      loadUser,
      loadSettings,
      loadPermissionsGroups,
      loadAllDepartmentsCalendarTasks,
      loadCustomers,
      loadSolicitations,
      loadCertificates,
      loadProcurations,
      loadConventions,
      loadUsers,
      loadLinks,
      loadLinkCategories,
      loadDocumentTypes,
      loadChecklistTasks,
      loadTasks,
      loadEmailsLogs,
      loadPayments
    ];

    const { pathname } = location;

    loaders.forEach((loader) => {
      const customersWillAlreadyBeCalled =
        loader.name === "loadCustomers" && [ROUTES["/clientes"], ROUTES['/lembretes']].includes(pathname);
      const permissionsGroupsWillAlreadyBeCalled =
        loader.name === "loadPermissionsGroups" &&
        pathname === ROUTES["/checklist"];
      const conventionsWillAlreadyBeCalled =
        loader.name === "loadConventions" && pathname === ROUTES["/convencoes"];
      const certificadosWillAlreadyBeCalled =
        loader.name === "loadCertificates" &&
        pathname === ROUTES["/certificados"];
      const procurationsWillAlreadyBeCalled =
        loader.name === "loadProcurations" &&
        pathname === ROUTES["/lembretes"];
      const linksWillAlreadyBeCalled =
        loader.name === "loadLinks" && pathname === ROUTES["/links"];
      const linkCategoriesWillAlreadyBeCalled =
        loader.name === "loadLinkCategories" && pathname === ROUTES["/links"];
      const tasksWillAlreadyBeCalled =
        loader.name === "loadTasks" && pathname === ROUTES["/tarefas"];

      if (
        !customersWillAlreadyBeCalled &&
        !permissionsGroupsWillAlreadyBeCalled &&
        !conventionsWillAlreadyBeCalled &&
        !certificadosWillAlreadyBeCalled &&
        !procurationsWillAlreadyBeCalled &&
        !linksWillAlreadyBeCalled &&
        !linkCategoriesWillAlreadyBeCalled &&
        !tasksWillAlreadyBeCalled
      ) {
        loadersToCall.push(dispatch(loader()));
      }
    });

    return loadersToCall;
  }

  async function loadInitialData() {
    await loadInitialCache();

    try {
      await Promise.all(getLoaders());
    } catch (error) {
      if (
        error.error &&
        error.error.response &&
        (error.error.response.status === 401 || error.error.response.status === 400)
      ) {
        handleLogout();
        history.replace("/login");
      }
    }
  }

  useEffect(() => {
    if (AccountStorage.get().token) {
      loadInitialData();
    } else {
      handleLogout();
      history.replace("/login");
    }

    return () => dispatch(clearAllDepartmentsCalendarState());
  }, []);

  function setOnboardingTaskAsDone(taskId) {
    if(onboardingData.length === 0) return;
    const updatedData = onboardingData.map(task =>
      task.id === taskId ? { ...task, status: 'done' } : task
    );
    dispatch(updateOnboardingStep(updatedData));
  }

  function setAllOnboardingTasksAsDone() {
    if(onboardingData.length === 0) return;
    const updatedData = onboardingData.map(task =>
      task.status === 'pending' ? { ...task, status: 'done' } : task
    );
    dispatch(updateOnboardingStep(updatedData));
  }

  function setOnboardingTaskAsClicked(taskId) {
    if(onboardingData.length === 0) return;
    const updatedData = onboardingData.map(task =>
      task.id === taskId ? { ...task, clicked: true } : task
    );
    dispatch(updateOnboardingStep(updatedData));
  }

  function resetOnboardingTasks() {
    if(onboardingData.length === 0) return;
    const updatedData = onboardingData.map(task => ({ ...task, status: 'pending' }));
    dispatch(updateOnboardingStep(updatedData));
  }

  return (
    <DataContext.Provider value={{ setOnboardingTaskAsDone, setAllOnboardingTasksAsDone, setOnboardingTaskAsClicked, resetOnboardingTasks, unlockedMenus, onboardingData }}>
      <div className={`pageloader ${!user.id ? "is-active" : ""}`}></div>
      {children}
    </DataContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};