import { Form, Input, Radio } from 'antd';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  margin: 0 -16px;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
`;

export const SearchInput = styled(Input.Search)`
  max-width: 480px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  gap: 6px;
`;

export const RadioButtons = styled(Radio.Group)``;

const activeButtonStyles = css`
  border-color: #a0d911 !important;
  color: #a0d911 !important;
  box-shadow: -1px 0 0 0 #a0d911 !important;
`;

const pendingButtonStyles = css`
  border-color: #ffa940 !important;
  color: #ffa940 !important;
  box-shadow: -1px 0 0 0 #ffa940 !important;
`;

const archivedButtonStyles = css`
  border-color: #f5222d !important;
  color: #f5222d !important;
  box-shadow: -1px 0 0 0 #f5222d !important;
`;

export const ActiveRadioButton = styled(Radio.Button)`
  ${({ isActive }) => isActive && activeButtonStyles}
`;

export const PendingRadioButton = styled(Radio.Button)`
  ${({ isActive }) => isActive && pendingButtonStyles}
`;

export const ArchivedRadioButton = styled(Radio.Button)`
  ${({ isActive }) => isActive && archivedButtonStyles}
`;

export const ArchiveModalContainer = styled.div``;

export const ArchiveModalSubTitle = styled.span``;

export const ArchiveModalFormItem = styled(Form.Item).attrs({
  style: {
    marginBottom: 0,
    marginTop: 10
  }
})``;

export const DeliveryMethodsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  > span {
    margin: 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Card = styled.div`
  flex: 1;
  background: ${({ background }) => background || '#fff'};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 0 8px;
  position: relative;
  cursor: pointer;
  border: ${({ isActive }) => (isActive ? '2px solid #1890ff' : '2px solid transparent')};
  transition: border 0.3s;
`;

export const CardTitle = styled.h3`
  position: absolute;
  top: 8px;
  right: 16px;
  margin: 0;
  font-size: 14px;
  color: #333;
`;

export const CardCount = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: auto;
`;

export const CardIcon = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;