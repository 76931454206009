import { Button, Upload, Card } from "antd";
import styled from "styled-components";

export const UploadStyled = styled(Upload)`
  .ant-upload {
    width: 100% !important;
  }
`;

export const DocumentViewerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding-top: 20px;
`;

export const AddDocumentButton = styled(Button)`
  &.has-error {
    border-color: #f5222d !important;
  }
`;

export const RequiredDocumentErrorMessage = styled.div`
  opacity: 0;
  color: #f5222d;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: 2px;

  &.has-error {
    opacity: 1;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleAndMonthPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MonthPickerWrapper = styled.div`
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const CustomSkeleton = styled.div`
  .ant-skeleton-content .ant-skeleton-paragraph {
    border: 1px solid #e6e6e6;
  }

  .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
    height: 50px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph li:not(:first-child) {
    height: 30px;
    margin-top: 24px;
  }
`;

export const gridStyle1 = {
    width: '70%',
    padding: '8px',
    textAlign: 'left',
};

export const gridStyle2 = {
    width: '30%',
    textAlign: 'center',
    padding: '8px',
};

export const strong = {
    fontWeight: '700'
};

export const TaskCard = styled(Card)`
    margin-top: 16px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    .ant-card-head {
        background-color: ${props => props.bgColor} !important;
        color: ${props => props.color};
        font-weight: bold;
    }
    .ant-card-head-title button {
        color: ${props => props.color};
        font-weight: bold;
    }
`;