import { Button, Drawer, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { DocumentsTable } from '../../Tables/DocumentsTable';
import DocumentUpload from '../DocumentUpload';
import {
  BackButton,
  BottonsContainer,
  Footer,
  NewDocumentButton,
} from './styles';
import { DeliveryMethodButtons } from '../../DeliveryMethodButtons';

const DocumentsListComponent = (props) => {
  const [documentDrawer, setDocumentDrawer] = useState({ isOpen: false, data: null });
  const [isSavingDocument, setIsSavingDocument] = useState(false);
  const [files, setFiles] = useState([]);

  const closeDocumentDrawer = () => {
    setDocumentDrawer({ isOpen: false, data: null });
  };

  const openDocumentDrawer = (data = null) => {
    setDocumentDrawer({ isOpen: true, data });
  };

  useEffect(() => {
    const { visible } = props;

    if (visible) {
      setFiles([]);
    }
  }, [props.visible]);

  const onCreateDocument = (document) => {
    setFiles([...files, document]);
  };

  const onUpdateDocument = (document) => {
    setFiles(files.map((existingDocument) => {
      return existingDocument.id !== document.id ? existingDocument : document;
    }));
  };

  const saveFiles = async (taskRecordId, formData) => {
    const { saveFiles, loadTaskList } = props;

    try {
      await saveFiles(taskRecordId, formData);
      await loadTaskList();
      setFiles([]);
    } finally {
      setIsSavingDocument(false);
    }
  };

  const handleSubmit = async () => {
    const { selectedTaskRecord, task } = props;

    const formData = new FormData();

    files.forEach((currentFile) => {
      formData.append('files[]', currentFile.file);

      const { file, ...onlyFilesInfo } = currentFile;

      formData.append('files_info[]', JSON.stringify(onlyFilesInfo));
    });

    formData.append(
      'task_record_info',
      JSON.stringify({
        task_id: task.id,
        customer_id: selectedTaskRecord.customer.id,
        task_due_at: task.due_date,
      })
    );

    setIsSavingDocument(true);

    if (!!selectedTaskRecord.executed_at) {
      await saveFiles(selectedTaskRecord.task_record_id, formData);
    } else {
      Modal.confirm({
        title: 'Deseja marcar essa tarefa como executada?',
        content: 'Você poderá marcar como não executada depois.',
        okText: 'Sim',
        cancelText: 'Não',
        onOk: async () => {
          formData.append('mark_as_executed', 1);
          await saveFiles(selectedTaskRecord.task_record_id, formData);
        },
        onCancel: async () => {
          formData.append('mark_as_executed', 0);
          await saveFiles(selectedTaskRecord.task_record_id, formData);
        },
      });
    }
  };

  const { onClose, visible, selectedTaskRecord, task } = props;
  const taskRecordFiles = selectedTaskRecord ? selectedTaskRecord.files : [];
  const allFiles = [...taskRecordFiles, ...files];

  return (
    <Drawer
      width={900}
      destroyOnClose
      title="Documentos"
      placement="right"
      onClose={onClose}
      visible={visible}
      maskClosable={false}
    >
      {selectedTaskRecord && (
        <>
          <DocumentsTable
            files={allFiles}
            selectedTaskRecord={selectedTaskRecord}
            openDocumentDrawer={openDocumentDrawer}
          />
          {taskRecordFiles.length > 0 && (
            <BottonsContainer>
              <DeliveryMethodButtons
                filesInState={files}
                data={selectedTaskRecord}
                updateCustomers={props.updateCustomers}
              />
            </BottonsContainer>
          )}
        </>
      )}
      <NewDocumentButton onClick={() => openDocumentDrawer()} type="primary">
        Adicionar
      </NewDocumentButton>
      <Footer>
        <BackButton onClick={onClose}>Voltar</BackButton>
        <Button
          onClick={handleSubmit}
          type="primary"
          disabled={files.length === 0}
          loading={isSavingDocument}
        >
          Salvar
        </Button>
      </Footer>
      <DocumentUpload
        visible={documentDrawer.isOpen}
        data={documentDrawer.data}
        onClose={closeDocumentDrawer}
        onCreateDocument={onCreateDocument}
        onUpdateDocument={onUpdateDocument}
        task={task}
      />
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    saveFiles: (taskRecordId, data) =>
      dispatch({
        type: 'CALENDAR_TASK_SAVE_FILES',
        payload: {
          request: {
            method: 'post',
            url: `/calendar/task_record/${taskRecordId}/files`,
            data,
          },
        },
        id: taskRecordId,
      }),
  };
};

export const DocumentsList = connect(
  mapStateToProps,
  mapDispatchProps
)(DocumentsListComponent);
