import moment from 'moment';

export const getChartOptions = (data, months, inAmounts, outAmounts) => ({
  chart: {
    type: 'line',
    stacked: false,
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  tooltip: {
    enabled: true,
    custom: function(props) {
      const currentInAmount = inAmounts[props.dataPointIndex];
      const currentOutAmount = outAmounts[props.dataPointIndex];
      const title = props.w.globals.categoryLabels[props.dataPointIndex];
      const content = props.w.config.series.map((seriesItem, index) => {
        const value = seriesItem.data[props.dataPointIndex];
        return `
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${index + 1}; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: ${seriesItem.color}"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">${seriesItem.name}:</span>
                <span class="apexcharts-tooltip-text-y-value">${value}</span>
                ${index === 0 ? `<span class="apexcharts-tooltip-text-y-value" style="color: #52c41a">+ ${currentInAmount}</span>` : ''}
                ${index === 1 ? `<span class="apexcharts-tooltip-text-y-value" style="color: #cf1322">- ${currentOutAmount}</span>` : ''}
              </div>
            </div>
          </div>
        `;
      }).join('');
      return `
        <div class="apexcharts-tooltip-title" style="font-size: 12px; font-family: Helvetica, Arial, sans-serif">
          ${title}
        </div>
        ${content}
      `;
    },
  },
  dataLabels: {
    enabled: true,
    formatter: (value, { seriesIndex }) => seriesIndex === 3 ? `${value}%` : value,
  },
  stroke: { width: [1, 1, 4, 4] },
  xaxis: {
    categories: months.map(month => {
      const date = moment(`${month}-01`);
      const formatted = date.format('MMM/YY');
      return formatted;
    }),
  },
  yaxis: [
    {
      show: true,
      labels: {
        formatter: value => (!Number.isInteger(value) ? 0 : value),
      },
    },
    {
      show: false,
      seriesName: 'Entrada de clientes',
      decimalsInFloat: 0,
    },
    {
      opposite: true,
      show: true,
      decimalsInFloat: 0,
    },
    {
      show: false,
      max: 100,
    },
  ],
});
