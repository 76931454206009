import { PERMISSIONS } from "~/constants/permissions.constants";
import { parents } from "./permissions-parents";

export const permissionsTree = [
  // Dashboard
  {
    title: parents.DASHBOARD.title,
    key: "Dashboard_PARENT",
    plans: ["starter", "premium"],
    children: [
      {
        title: "Gráficos",
        key: "Gráficos_PARENT",
        plans: ["starter", "premium"],
        children: [
          {
            title: "Mensalidade x Recebido",
            key: PERMISSIONS.graphs.monthlyPayments.view,
            plans: ["starter", "premium"],
          },
          {
            title: "Quantidade de Clientes",
            key: PERMISSIONS.graphs.customersCount.view,
            plans: ["starter", "premium"],
          },
          {
            title: "Clientes por tipo",
            key: PERMISSIONS.graphs.customersByType.view,
            plans: ["starter", "premium"],
          },
          {
            title: "Serviços extras / Tarefas",
            key: PERMISSIONS.graphs.dashTasks.view,
            plans: ["premium"],
          },
          {
            title: "Serviços extras / Financeiro",
            key: PERMISSIONS.graphs.extraPayments.view,
            plans: ["premium"],
          },
          {
            title: "Tarefas do mês",
            key: PERMISSIONS.graphs.calendarTasks.view,
            plans: ["starter", "premium"],
          },
          { 
            title: "Certificados", 
            key: PERMISSIONS.graphs.certificates.view ,
            plans: ["premium"],
          },
          { 
            title: "Leads", 
            key: PERMISSIONS.graphs.leads.view,
            plans: ["premium"],
          },
        ],
      },
    ],
  },
  // Clientes
  {
    title: parents.CLIENTES.title,
    key: "Clientes_PARENT",
    plans: ["starter", "premium"],
    children: [
      { 
        title: "Visualizar", 
        key: PERMISSIONS.customers.view, 
        plans: ["starter", "premium"] 
      },
      { 
        title: "Criar", 
        key: PERMISSIONS.customers.create,
        plans: ["starter", "premium"],
      },
      { 
        title: "Editar", 
        key: PERMISSIONS.customers.edit,
        plans: ["starter", "premium"],
      },
      { 
        title: "Remover", 
        key: PERMISSIONS.customers.delete,
        plans: ["starter", "premium"],
      },
    ],
  },
  {
    title: parents.SOLICITATIONS.title,
    key: PERMISSIONS.solicitations.view,
    plans: ["premium"],
  },
  // Tarefas
  {
    title: parents.TAREFAS.title,
    key: "Tarefas_PARENT",
    plans: ["starter", "premium"],
    children: [
      { 
        title: "Cadastro de tarefas", 
        key: PERMISSIONS.calendarTasks.create,
        plans: ["starter", "premium"],
      },
      {
        title: "Checklist",
        key: "Checklist_PARENT",
        plans: ["premium"],
        children: [
          { 
            title: "Visualizar", 
            key: PERMISSIONS.checklist.view,
            plans: ["premium"],
          },
          {
            title: "Colunas",
            key: "Colunas_PARENT",
            plans: ["premium"],
            children: [
              {
                title: "Legalização",
                key: PERMISSIONS.checklist.columns.legalization,
                plans: ["starter", "premium"],
              },
              {
                title: "Administrativo",
                key: PERMISSIONS.checklist.columns.administrative,
                plans: ["starter", "premium"],
              },
              {
                title: "Contábil",
                key: PERMISSIONS.checklist.columns.accounting,
                plans: ["starter", "premium"],
              },
              { 
                title: "Fiscal", 
                key: PERMISSIONS.checklist.columns.fiscal,
                plans: ["starter", "premium"],
              },
              { 
                title: "Pessoal", 
                key: PERMISSIONS.checklist.columns.personal,
                plans: ["starter", "premium"],
              },
              { 
                title: "Sucesso do cliente", 
                key: PERMISSIONS.checklist.columns.cs,
                plans: ["starter", "premium"],
              },
            ],
          },
        ],
      },
      { 
        title: "Convenções coletiva", 
        key: PERMISSIONS.conventions.view,
        plans: ["premium"],
      },
      { 
        title: "Tarefas do mês", 
        key: PERMISSIONS.calendarTasks.view,
        plans: ["starter", "premium"],
      },
      {
        title: "IRPF",
        key: "IRPF_PARENT",
        plans: ["premium"],
        children: [
          { 
            title: "Visualizar", 
            key: PERMISSIONS.irpf.view,
            plans: ["premium"],
          },
          { 
            title: "Sumário", 
            key: PERMISSIONS.irpf.summary.view,
            plans: ["premium"],
          },
        ],
      },
    ],
  },
  // Financeiro
  {
    title: parents.FINANCEIRO.title,
    key: "Financeiro_PARENT",
    plans: ["starter", "premium"],
    children: [
      {
        title: "Mensalidades",
        key: "Mensalidades_PARENT",
        plans: ["starter", "premium"],
        children: [
          {
            title: "Visualizar",
            key: PERMISSIONS.financial.monthlyPayments.view,
            plans: ["starter", "premium"],
          },
          { 
            title: "Editar", 
            key: PERMISSIONS.financial.monthlyPayments.edit,
            plans: ["starter", "premium"],
          },
          {
            title: "Remover",
            key: PERMISSIONS.financial.monthlyPayments.delete,
            plans: ["starter", "premium"],
          },
        ],
      },
      {
        title: "Serviços Extras",
        key: "Serviços Extras_PARENT",
        plans: ["premium"],
        children: [
          { 
            title: "Visualizar", 
            key: PERMISSIONS.extraServices.view,
            plans: ["premium"],
          },
          { 
            title: "Editar", 
            key: PERMISSIONS.extraServices.edit,
            plans: ["premium"],
          },
          { 
            title: "Remover", 
            key: PERMISSIONS.extraServices.delete,
            plans: ["premium"],
          }
        ],
      },
      {
        title: "Comissões",
        key: "Comissões_PARENT",
        plans: ["premium"],
        children: [
          { 
            title: "Visualizar", 
            key: PERMISSIONS.refunds.view,
            plans: ["premium"],
          },
          { 
            title: "Editar", 
            key: PERMISSIONS.refunds.edit,
            plans: ["premium"],
           },
          { 
            title: "Remover", 
            key: PERMISSIONS.refunds.delete,
            plans: ["premium"],
          }
        ],
      },
    ],
  },
  // Dep. Societário
  {
    title: parents.SOCIETARIO.title,
    key: "Dep. Societário_PARENT",
    plans: ["premium"],
    children: [
      {
        title: "Tarefas",
        key: "Tarefas_Societario_PARENT",
        plans: ["premium"],
        children: [
          { 
            title: "Visualizar", 
            key: PERMISSIONS.corporate.view,
            plans: ["premium"],
          },
          { 
            title: "Criar", 
            key: PERMISSIONS.corporate.create,
            plans: ["premium"],
          },
          {
            title: "Criar contas a pagar",
            key: PERMISSIONS.corporate.createBillsToPay,
            plans: ["premium"],
          },
          {
            title: "Visualizar departamento financeiro",
            key: PERMISSIONS.corporate.financial.view,
            plans: ["premium"],
          },
        ],
      },
      { 
        title: "Certificados digitais", 
        key: PERMISSIONS.certificates.view,
        plans: ["premium"],
      },
      { 
        title: "Lembretes", 
        key: PERMISSIONS.procurations.view,
        plans: ["premium"],
      },
      {
        title: "Malha Fiscal",
        key: PERMISSIONS.irpf.malhaFina.view,
        plans: ["premium"],
      },
    ],
  },
  // Itens Enviados
  {
    title: parents.ITENS_ENVIADOS.title,
    key: "Itens Enviados_PARENT",
    plans: ["starter", "premium"],
    children: [
      { 
        title: "Documentos Enviados", 
        key: PERMISSIONS.documents.view,
        plans: ["starter", "premium"],
      },
      { 
        title: "E-mails enviados", 
        key: PERMISSIONS.sentEmails.view,
        plans: ["starter", "premium"],
      },
    ],
  },
  // Leads
  {
    title: parents.LEADS.title,
    key: PERMISSIONS.leads.view,
    plans: ["premium"],
  },
  // Leads RT
  {
    title: parents.LEADSRT.title,
    key: PERMISSIONS.leadsRT.view,
    plans: ["premium"],
  },
  // Prospecção
  {
    title: parents.PROSPECTIONS.title,
    key: "Prospecções_PARENT",
    plans: ["premium"],
    children: [
      {
        title: "Prospecção Ativa",
        key: PERMISSIONS.prospecting.view,
        plans: ["premium"],
      },
      {
        title: "Prospecção Passiva",
        key: PERMISSIONS.leads.view,
        plans: ["premium"],
      },
      {
        title: "Social Selling",
        key: PERMISSIONS.socialSelling.view,
        plans: ["premium"],
      },
      {
        title: "Indicação premiada",
        key: PERMISSIONS.referralProgram.view,
        plans: ["premium"],
      }
    ],
  },
  // Usuários
  {
    title: parents.USUARIOS.title,
    key: PERMISSIONS.users.view,
    plans: ["starter", "premium"],
  },
  // Links
  {
    title: parents.LINKS.title,
    key: PERMISSIONS.links.view,
    plans: ["premium"],
  },
  // Relatórios
  {
    title: parents.RELATORIOS.title,
    key: "Relatorios_PARENT",
    plans: ["premium"],
    children: [
      { 
        title: "Inadimplentes", 
        key: PERMISSIONS.reports.defaulters,
        plans: ["premium"],
      },
      { 
        title: "Prazos", 
        key: PERMISSIONS.documentsDeadline.view,
        plans: ["premium"],
      },
      { 
        title: "Controle de honorários", 
        key: PERMISSIONS.honoraries.view,
        plans: ["premium"],
      },
      { 
        title: "KPIs", 
        key: PERMISSIONS.feedbacks.view,
        plans: ["premium"],
      },
    ],
  },
  // Marketing
  {
    title: parents.BOARDS.title,
    key: PERMISSIONS.boards.view,
    plans: ["premium"],
    isBeta: true,
  },
  {
    title: parents.PLUGINS.title,
    key: PERMISSIONS.plugins.view,
    plans: ["premium"],
  },
  // Base de conhecimento
  {
    title: parents.BASE_DE_CONHECIMENTO.title,
    key: PERMISSIONS.knowledgeBase.view,
    plans: ["premium"],
  }
];
