import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Switch,
  Table,
  Tooltip,
  Icon,
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { department } from '~/components/RegisterTasks/Utils';
import { limitCharacterLength } from '~/utils/formatters';
import { DocumentViewerModal } from '~/_domains/calendarTasks/List/Modals/DocumentViewerModal';
import InputCurrency from '~/components/UI/InputCurrency';
import { TitleWrapper, ColumnTitleWrapper } from './styles';

const { Column } = Table;
const { Option } = Select;

export function UploadedFilesTable({
  tasks,
  setTasks,
  parentTask,
  customersOfParentTask,
  isHandlingDocuments
}) {
  const [currentTasks, setCurrentTasks] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [documentViewerModal, setDocumentViewerModal] = useState({
    isOpen: false,
    data: null,
  });

  const { competence_date, custom_fields, document_types } = parentTask;

  useEffect(() => {
    setCustomers(customersOfParentTask);
  }, [customersOfParentTask]);

  useEffect(() => {
    if (tasks.length > 0) {
      setCurrentTasks(tasks);
      const tasksThatHasCustomerId = tasks.filter(
        ({ customer_id }) => !!customer_id
      );
      const customersIds = tasksThatHasCustomerId.map(
        ({ customer_id }) => customer_id
      );
      if (tasksThatHasCustomerId.length > 0) {
        setCustomers((customersInState) => {
          return customersInState.map((customer) => {
            if (!customersIds.includes(customer.customer.id)) {
              return customer;
            }

            const currentTask = tasksThatHasCustomerId.find(
              ({ customer_id }) => customer_id === customer.customer.id
            );

            if (!currentTask) {
              return customer;
            }

            return {
              ...customer,
              already_selected_by: currentTask.id,
            };
          });
        });
      }
    }
  }, [tasks]);

  function openDocumentViewerModal(data) {
    setDocumentViewerModal({
      isOpen: true,
      data,
    });
  }

  function closeDocumentViewerModal() {
    setDocumentViewerModal({
      isOpen: false,
      data: null,
    });
  }

  function updateTask({ taskId, updatedFields }) {
    setTasks((tasksInState) =>
      tasksInState.map((task) => {
        if (task.id !== taskId) return task;

        return {
          ...task,
          ...updatedFields,
        };
      })
    );
  }

  function deleteTask(taskId) {
    Modal.confirm({
      title: 'Você realmente deseja excluir essa tarefa?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        setTasks((tasksInState) =>
          tasksInState.filter((type) => {
            return type.id !== taskId;
          })
        );
        removeAlreadySelectedBy(taskId);
      },
    });
  }

  function replicate(fieldName){
    setTasks((tasksInState) =>
      tasksInState.map((task) => {
        return {
          ...task,
          [fieldName]: tasksInState[0][fieldName],
        };
      })
    )
  }

  function handleIsExecuted(taskId, is_executed) {
    updateTask({
      taskId,
      updatedFields: {
        is_executed,
      },
    });
  }

  function handleCustomerOfParentTask(
    taskId,
    customerOfParentTaskId,
    taskRecordId,
    customerAlreadyExists
  ) {
    updateTask({
      taskId,
      updatedFields: {
        customer_id: customerOfParentTaskId,
        task_record_id: taskRecordId,
        is_only_document: customerAlreadyExists,
      },
    });
  }

  function handleDocumentType(taskId, type) {
    updateTask({
      taskId,
      updatedFields: {
        type,
        description: `${type} ${competence_date}`,
      },
    });
  }

  function handleDueAt(taskId, due_at) {
    updateTask({
      taskId,
      updatedFields: {
        due_at,
      },
    });
  }

  function handleAmount(taskId, amount) {
    updateTask({
      taskId,
      updatedFields: {
        amount,
      },
    });
  }

  function handleCustomField(taskId, customFieldKey, customFieldValue) {
    setTasks((tasksInState) =>
      tasksInState.map((task) => {
        if (task.id !== taskId) return task;

        return {
          ...task,
          custom_fields: {
            ...task.custom_fields,
            [customFieldKey]: customFieldValue,
          },
        };
      })
    );
  }

  function handleSelectCustomer(selectedCustomerId, id) {
    const customerAlreadyExists = currentTasks.some(
      ({ customer_id }) => customer_id === selectedCustomerId
    );

    if (!selectedCustomerId) {
      if (document_types.length === 1) {
        removeAlreadySelectedBy(id);
      }
      return handleCustomerOfParentTask(
        id,
        undefined,
        undefined,
        customerAlreadyExists
      );
    }

    const customerOfParentTask = customers.find(({ customer }) => {
      return customer.id === selectedCustomerId;
    });

    const taskRecordId = customerOfParentTask
      ? customerOfParentTask.task_record_id
      : undefined;

    handleCustomerOfParentTask(
      id,
      selectedCustomerId,
      taskRecordId,
      customerAlreadyExists
    );

    if (document_types.length === 1) {
      setAlreadySelectedBy(customerOfParentTask, id);
    }
  }

  function removeAlreadySelectedBy(id) {
    setCustomers((customersInState) => {
      return customersInState.map((customer) => {
        if (customer.already_selected_by !== id) {
          return customer;
        }

        delete customer.already_selected_by;

        return customer;
      });
    });
  }

  function setAlreadySelectedBy(customerOfParentTask, id) {
    setCustomers((customersInState) => {
      return customersInState.map((customer) => {
        if (customer.id !== customerOfParentTask.id) {
          return customer;
        }

        return {
          ...customer,
          already_selected_by: id,
        };
      });
    });
  }

  return (
    <>
      <Table
        rowKey="id"
        dataSource={currentTasks}
        bordered
        scroll={{ x: true, y: 'calc(100vh - 500px)' }}
        pagination={false}
        loading={isHandlingDocuments}
      >
        <Column
          title="Arquivo"
          align="center"
          fixed="left"
          dataIndex="file"
          key="file"
          width={220}
          render={(file) => (
            <Tooltip title={file.name}>
              <Button
                onClick={() => openDocumentViewerModal(file.originFileObj)}
                type="link"
              >
                {limitCharacterLength(file.name, 15)}
              </Button>
            </Tooltip>
          )}
        />
        <Column
          title="Executado"
          align="center"
          dataIndex="is_executed"
          key="is_executed"
          width={120}
          render={(isExecuted, { id, is_only_document }) => {
            if (is_only_document) return null;
            return (
              <Switch
                checked={isExecuted}
                onChange={(isChecked) => handleIsExecuted(id, isChecked)}
              />
            );
          }}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Cliente</TitleWrapper>
            </ColumnTitleWrapper>
          }
          dataIndex="customer_id"
          key="customer_id"
          width={400}
          render={(customerId, { id }) => (
            <Select
              style={{ width: 360 }}
              value={customerId}
              placeholder="Selecione um cliente"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(selectedCustomerId) =>
                handleSelectCustomer(selectedCustomerId, id)
              }
            >
              {customers &&
                customers
                  .filter(({ already_selected_by }) =>
                    document_types.length === 1
                      ? !already_selected_by || already_selected_by === id
                      : true
                  )
                  .map(({ customer }) => {
                    const existingCustomer = currentTasks.some(
                      ({ customer_id }) => customer_id === customer.id
                    );
                    const optionColor =
                      existingCustomer && document_types.length > 1
                        ? 'green'
                        : 'inherit';

                    return (
                      <Option
                        style={{ color: optionColor }}
                        key={customer.id}
                        value={customer.id}
                      >
                        {customer.cod
                        ? `${customer.cod} - ${customer.name}`
                        : customer.name}
                      </Option>
                    );
                  })}
            </Select>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Departamento</TitleWrapper>
              <Button size="small" onClick={() => replicate('deparment')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="department"
          key="department"
          align="center"
          width={130}
          render={(departmentId) => department(departmentId)}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Tipo de documento</TitleWrapper>
              <Button size="small" onClick={() => replicate('type')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="type"
          key="type"
          align="center"
          width={300}
          render={(selectedType, { id }) => (
            <Select
              style={{ width: 240 }}
              placeholder="Selecione um tipo"
              value={selectedType}
              onChange={(value) => handleDocumentType(id, value)}
            >
              {document_types &&
                document_types.map(({ id, description }) => (
                  <Option key={id} value={description}>
                    {description}
                  </Option>
                ))}
            </Select>
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Descrição</TitleWrapper>
            </ColumnTitleWrapper>
          }
          dataIndex="description"
          key="description"
          width={200}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Mês de competência</TitleWrapper>
            </ColumnTitleWrapper>
          }
          dataIndex="competency_at"
          key="competency_at"
          align="center"
          width={180}
          render={(competencyAt) => moment(competencyAt).format('MM/YYYY')}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Data de vencimento</TitleWrapper>
              <Button size="small" onClick={() => replicate('due_at')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          width={220}
          dataIndex="due_at"
          align="center"
          key="due_at"
          render={(dueAt, { id }) => (
            <DatePicker
              style={{ width: 140 }}
              format="DD/MM/YYYY"
              value={dueAt ? moment(dueAt) : undefined}
              onChange={(date) =>
                handleDueAt(id, moment(date).format('YYYY-MM-DD'))
              }
            />
          )}
        />
        <Column
          title={
            <ColumnTitleWrapper>
              <TitleWrapper>Valor</TitleWrapper>
              <Button size="small" onClick={() => replicate('amount')}>
                <Icon type="sync" /> Replicar
              </Button>
            </ColumnTitleWrapper>
          }
          dataIndex="amount"
          key="amount"
          align="center"
          width={170}
          render={(amount, { id }) => (
            <InputCurrency
              style={{ width: 130 }}
              value={amount}
              onChangeEvent={(event, maskedValue, floatValue) => {
                handleAmount(id, floatValue);
              }}
            />
          )}
        />
        {custom_fields &&
          Object.entries(custom_fields).map(([key, value]) => {
            const [title] = value;

            return (
              <Column
                dataIndex={`custom_fields.${key}`}
                title={
                  <ColumnTitleWrapper>
                    <TitleWrapper>{title}</TitleWrapper>
                    <Button size="small" onClick={() => replicate(`custom_fields.${key}`)}>
                      <Icon type="sync" /> Replicar
                    </Button>
                  </ColumnTitleWrapper>
                }
                key={key}
                align="center"
                width={200}
                render={(fieldValue, { id, is_only_document }) => {
                  if (is_only_document) return null;
                  return (
                    <Input
                      style={{ width: 140 }}
                      value={fieldValue}
                      onChange={(event) =>
                        handleCustomField(id, key, event.target.value)
                      }
                    />
                  );
                }}
              />
            );
          })}
        <Column
          dataIndex="id"
          key="delete"
          align="center"
          width={80}
          fixed="right"
          render={(id) => (
            <Button
              type="danger"
              icon="delete"
              onClick={() => deleteTask(id)}
            />
          )}
        />
      </Table>
      <DocumentViewerModal
        isOpen={documentViewerModal.isOpen}
        closeModal={closeDocumentViewerModal}
        data={documentViewerModal.data}
        mask
      />
    </>
  );
}
