import React from 'react';
import { Row, Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Task from './Task'; // Updated import path
import {
  TaskGroupHeader,
  TaskGroupTitle,
  TaskGroupCount,
  ToggleIcon,
  TaskGroupContainer
} from './styles';

/**
 * TaskGroup component - displays a collapsible group of tasks with header
 */
const TaskGroup = ({ 
  title, 
  color, 
  bgColor, 
  count, 
  tasks, 
  isExpanded, 
  onToggle,
  openList,
  openGrid,
  openTasksInBatchDrawer
}) => {
  return (
    <>
      <TaskGroupHeader 
        color={color}
        onClick={onToggle}
      >
        <ToggleIcon 
          type="caret-right" 
          color={color} 
          expanded={isExpanded} 
        />
        <TaskGroupTitle color={color}>{title}</TaskGroupTitle>
        <TaskGroupCount bgColor={bgColor} color={color}>
          {count}
        </TaskGroupCount>
        <Tooltip title="Clique para expandir/recolher">
          <Icon 
            type={isExpanded ? "up" : "down"} 
            style={{ marginLeft: 'auto', color: color }} 
          />
        </Tooltip>
      </TaskGroupHeader>
      <TaskGroupContainer expanded={isExpanded}>
        <Row gutter={16}>
          {isExpanded && (
            tasks.map((task) => (
              <Task
                openList={() => openList(task.id)}
                openGrid={() => openGrid(task)}
                data={task}
                key={task.id}
                openTasksInBatchDrawer={openTasksInBatchDrawer}
              />
            ))
          )}
        </Row>
      </TaskGroupContainer>
    </>
  );
};

TaskGroup.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  tasks: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  openList: PropTypes.func.isRequired,
  openGrid: PropTypes.func.isRequired,
  openTasksInBatchDrawer: PropTypes.func.isRequired
};

export default TaskGroup;
