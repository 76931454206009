import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Layout, Typography, Switch, Button } from 'antd';
import { FaUser } from 'react-icons/fa';
import { frequency } from '../helpers';
import CustomerList from '../components/CustomerList';
import TaskTable from '../components/TaskTable';
import { DrawerFooter } from '~_shared/components/Drawer';
import { save, fetch } from '../actions';
import { useAppContext } from '~/AppContext';

const { Sider, Content } = Layout;
const { Text } = Typography;

const DrawerBulk = ({ autoOpen = false }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(autoOpen);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [customerTasks, setCustomerTasks] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [taskSearchQuery, setTaskSearchQuery] = useState(''); 
  const [allSelected, setAllSelected] = useState(false); 
  const [contentOpacity, setContentOpacity] = useState(1);
  const tasks = useSelector(state => state.tasks.list);
  const customers = useSelector(state => state.customers.list);
  const customerTasksReducer = useSelector(state => state.customerTasks);
  const { setAllOnboardingTasksAsDone } = useAppContext();

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  useEffect(() => {
    if (customerTasksReducer.data) {
      setCustomerTasks(customerTasksReducer.data);
    }
  }
  , [customerTasksReducer]);

  const filteredTasks = useMemo(
    () => tasks.filter(task =>
      (selectedCategory === 0 || parseInt(task.department) === selectedCategory) &&
      task.name.toLowerCase().includes(taskSearchQuery.toLowerCase())
    ),
    [tasks, selectedCategory, taskSearchQuery]
  );

  const onClose = () => setVisible(false);

  const handleTaskToggle = useCallback((taskId, checked) => {
    if (!selectedCustomer) return;
    const custId = selectedCustomer.id;
    setCustomerTasks(prev => {
      const prevTasks = prev[custId] || [];
      if (checked) {
        if (!prevTasks.includes(taskId)) {
          return { ...prev, [custId]: [...prevTasks, taskId] };
        }
      } else {
        return { ...prev, [custId]: prevTasks.filter(id => id !== taskId) };
      }
      return prev;
    });
  }, [selectedCustomer]);

  const handleToggleAll = useCallback((checked) => {
    if (!selectedCustomer) return;
    const custId = selectedCustomer.id;
    if (checked) {
      setCustomerTasks(prev => ({
        ...prev,
        [custId]: [...new Set([...(prev[custId] || []), ...filteredTasks.map(task => task.id)])]
      }));
    } else {
      setCustomerTasks(prev => ({
        ...prev,
        [custId]: (prev[custId] || []).filter(id => !filteredTasks.some(task => task.id === id))
      }));
    }
    setAllSelected(checked);
  }, [selectedCustomer, filteredTasks]);

  useEffect(() => {
    if (selectedCustomer) {
      setContentOpacity(0);
      setTimeout(() => setContentOpacity(1), 300);
    }
  }, [selectedCustomer]);

  const handleSave = () => {
    console.log('Salvar button clicked');
    console.log('Customer Tasks:', customerTasks);
    dispatch(save({data: customerTasks}));
    setAllOnboardingTasksAsDone('p6q7r8')
  };

  const taskColumns = [
    {
      title: "",
      key: "toggle",
      render: (text, record) => (
        <Switch
          onChange={checked => handleTaskToggle(record.id, checked)}
          checked={selectedCustomer && customerTasks[selectedCustomer.id]
            ? customerTasks[selectedCustomer.id].includes(record.id)
            : false}
        />
      )
    },
    { title: "Nome", dataIndex: "name", key: "name" },
    { 
      title: "Quando?", 
      key: "frequency", 
      render: (_, record) => frequency(record.frequency, record)
    },
  ];

  return (
    <>
      <Drawer
        title="Clientes x Tarefas"
        placement="right"
        onClose={onClose}
        visible={visible}
        width="90%"
        bodyStyle={{ padding: 0 }}
      >
        <Layout>
          <Sider 
            width={300} 
            style={{ background: '#fff', padding: '0px', height: 'calc(100vh - 50px)', overflowY: 'auto' }}
          >
            <CustomerList 
              customers={customers}
              searchQuery={searchQuery}
              onSearchChange={e => setSearchQuery(e.target.value)}
              selectedCustomer={selectedCustomer}
              onCustomerSelect={customer => {
                setSelectedCustomer(customer);
                setAllSelected(false);
              }}
              customerTasks={customerTasks}
            />
          </Sider>
          <Content style={{ padding: '0px', height: 'calc(100vh - 50px)', overflowY: 'auto', opacity: contentOpacity, transition: 'opacity 0.3s' }}>
            {selectedCustomer ? (
              <TaskTable 
                taskSearchQuery={taskSearchQuery}
                onTaskSearchChange={e => setTaskSearchQuery(e.target.value)}
                selectedCategory={selectedCategory}
                onCategoryChange={(cat) => { setSelectedCategory(cat); setAllSelected(false); }}
                filteredTasks={filteredTasks}
                taskColumns={taskColumns}
                allSelected={allSelected}
                onToggleAll={handleToggleAll}
                selectedCustomer={selectedCustomer}
                customerTasks={customerTasks}
              />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <FaUser style={{ fontSize: '48px', color: 'var(--primaryColor)', marginBottom: '16px' }} />
                <Text style={{ fontSize: '18px' }}>Comece selecionando um cliente</Text>
              </div>
            )}
          </Content>
        </Layout>
        <DrawerFooter>
          <Button onClick={onClose} style={{ marginRight: 8 }}>Cancelar</Button>
          <Button onClick={handleSave} type="primary">Salvar</Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export default DrawerBulk;
