import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Dropdown, Menu, Radio, Input } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import Layout from '~/components/UI/Layout/Layout';
import Grid from '~/_domains/calendarTasks/Grid';
import List from '~/_domains/calendarTasks/List/List';
import { department } from '~/components/RegisterTasks/Utils';

import locale from '~/components/UI/DatePicker/Locale';
import { connect } from 'react-redux';
import { SporadictDocumentUpload } from '~/_domains/calendarTasks/Drawers/SporadicDocumentUpload';
import { SporadicDocumentUploadInBatch } from '~/_domains/calendarTasks/Drawers/SporadicDocumentUploadInBatch';
import Tasks from './components/Tasks'; // Updated import path
import { ModuleHeader } from '~/components/ModuleHeader';
import { PendingDocumentsDrawer } from '~/containers/Documents/components/PendingDocumentsDrawer';
import * as calendarTasksActions from '~/_domains/calendarTasks/actions';
import { FaTh, FaList } from 'react-icons/fa';

const { MonthPicker } = DatePicker;
const { Search } = Input;

const TASKS_VISUALIZATION_MODE = 'tasks_visualization_mode';

const ScreensCalendarTask = (props) => {
    const [date, setDate] = useState(moment());
    const [grid, setGrid] = useState(null);
    const [list, setList] = useState(null);
    const [actived, setActived] = useState([0, 1, 2]);
    const [documentDrawer, setDocumentDrawer] = useState({ isOpen: false });
    const [pendingDocumentsDrawer, setPendingDocumentsDrawer] = useState({ isOpen: false });
    const [documentDrawerInBatch, setDocumentDrawerInBatch] = useState({ isOpen: false });
    const [visualizationMode, setVisualizationMode] = useState(localStorage.getItem(TASKS_VISUALIZATION_MODE) || 'box');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTasks, setFilteredTasks] = useState([]);

    const toggleDrawer = (drawerSetter, isOpen) => drawerSetter({ isOpen });
    const handleSearchChange = (e) => setSearchTerm(e.target.value);
    const handleDateChange = (value) => setDate(value);
    const handleVisualizationModeChange = ({ target }) => {
        setVisualizationMode(target.value);
        localStorage.setItem(TASKS_VISUALIZATION_MODE, target.value);
    };
    const handleActivedChange = (e) => {
        const value = e.target.value;
        setActived(actived.includes(value) ? actived.filter(n => n !== value) : [...actived, value]);
    };

    useEffect(() => {
        props.loadCalendarTasks(props.match.params.id, date);
    }, [date, props.match.params.id, props.loadCalendarTasks]);

    useEffect(() => {
        const departmentId = props.match.params.id;
        const formattedDate = date.format('YYYY/MM');
        const tasks = (props.calendarTasks[departmentId] && 
                      props.calendarTasks[departmentId][formattedDate] && 
                      props.calendarTasks[departmentId][formattedDate].data) || [];

        const filtered = tasks.filter((task) => {
            const isCompleted = task.pendent_tasks_count === 0;
            const isPending = task.pendent_tasks_count > 0;
            const from = moment(task.due_date);
            const to = moment();
            const isOverdue = to.diff(from, 'days') > 0 && isPending;

            // Filter logic:
            // 0 = Atrasadas (Overdue) - task is pending and past due date
            // 1 = Pendentes (Pending) - task is pending but not past due date
            // 2 = Concluidas (Completed) - all tasks are executed

            // Only apply active filters in list view mode
            if (visualizationMode === 'list') {
                if (!actived.includes(2) && isCompleted) return false;
                if (!actived.includes(0) && isOverdue) return false;
                if (!actived.includes(1) && isPending && !isOverdue) return false;
            }

            return task.name.toLowerCase().includes(searchTerm.toLowerCase());
        });

        setFilteredTasks(filtered);
    }, [searchTerm, actived, props.calendarTasks, date, props.match.params.id, visualizationMode]);

    return (
        <Layout>
            <div style={{ margin: `0px -16px`, padding: '15px', background: '#fff', marginBottom: `10px` }}>
                <ModuleHeader
                    breadcrumbs={
                        props.match.params.id === '1'
                            ? ['Dep. Societário', 'Tarefas']
                            : ['Tarefas', 'Tarefas do mês', department(props.match.params.id)]
                    }
                    title={
                        <>
                            {props.match.params.id === '1' ? 'Tarefas' : 'Tarefas do mês'}
                            <Search
                                placeholder="Buscar tarefas"
                                onChange={handleSearchChange}
                                style={{ width: 200, marginLeft: 10 }}
                            />
                        </>
                    }
                    actions={
                        <>
                            <MonthPicker 
                                defaultValue={moment(date, 'MM/YYYY')} 
                                format={'MM/YYYY'}
                                locale={locale}
                                onChange={handleDateChange}
                                allowClear={false}
                                disabled={props.isTasksContextLoading}
                            />
                            
                            {/* Only show Radio.Group filter in list view mode */}
                            {visualizationMode === 'list' && (
                                <Radio.Group value={actived} onChange={handleActivedChange}>
                                    <Radio.Button value={0} style={actived.includes(0) ? {color: `#f5222d`} : {}}>Atrasadas</Radio.Button>
                                    <Radio.Button value={1} style={actived.includes(1) ? {color: `#d4b106`} : {}}>Pendentes</Radio.Button>
                                    <Radio.Button value={2} style={actived.includes(2) ? {color: `#a0d911`} : {}}>Concluidas</Radio.Button>
                                </Radio.Group>
                            )}
                            
                            <Radio.Group value={visualizationMode} onChange={handleVisualizationModeChange}>
                                <Radio.Button value="box"><FaTh /></Radio.Button>
                                <Radio.Button value="list"><FaList /></Radio.Button>
                            </Radio.Group>
                            <Dropdown
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key="1" onClick={() => toggleDrawer(setDocumentDrawer, true)}>Individual</Menu.Item>
                                        <Menu.Item key="2" onClick={() => toggleDrawer(setDocumentDrawerInBatch, true)}>Em lote</Menu.Item>
                                        <Menu.Item key="3" onClick={() => toggleDrawer(setPendingDocumentsDrawer, true)}>Pendentes</Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button type="primary">Adicionar documento</Button>
                            </Dropdown>
                        </>
                    }
                />
            </div>
            <Tasks
                {...props}
                date={date}
                openGrid={setGrid}
                openList={setList}
                filter={actived}
                isListOpen={list !== null}
                visualizationMode={visualizationMode}
                filteredTasks={filteredTasks}
            />
            <Grid 
                {...props}
                visible={grid !== null}
                onClose={() => setGrid(null)}
                data={grid}
                date={date}
            />
            <List 
                date={date}
                visible={list !== null}
                onClose={() => setList(null)}
                id={list}
                departmentId={props.match.params.id}
            />
            <SporadictDocumentUpload
                isOpen={documentDrawer.isOpen}
                onClose={() => toggleDrawer(setDocumentDrawer, false)}
                selectedDepartmentId={props.match.params.id}
            />
            <SporadicDocumentUploadInBatch
                isVisible={documentDrawerInBatch.isOpen}
                onClose={() => toggleDrawer(setDocumentDrawerInBatch, false)}
                selectedDepartmentId={props.match.params.id}
            />
            <PendingDocumentsDrawer
                isVisible={pendingDocumentsDrawer.isOpen}
                onClose={() => toggleDrawer(setPendingDocumentsDrawer, false)}
            />
        </Layout>
    );
}

const mapStateToProps = (state, ownProps) => {
    const departmentId = ownProps.match.params.id;
    const formattedDate = ownProps.date ? ownProps.date.format('YYYY/MM') : moment().format('YYYY/MM');
    
    // Check if we have data for this department and date
    const departmentData = state.calendarTasks[departmentId];
    const dateData = departmentData && departmentData[formattedDate];
    
    // Get loading state and task data
    const isLoading = dateData ? dateData.loading : false;
    const tasks = (dateData && dateData.data) ? dateData.data : [];
    
    // Find specific task if ID is provided
    let task = null;
    if (ownProps.id && tasks.length > 0) {
        task = tasks.find(t => t.id === ownProps.id);
    }

    return {
        calendarTasks: state.calendarTasks,
        isCalendarTasksLoading: isLoading,
        task,
        tasksFromState: state.tasks.list
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadCalendarTasks: (id, date) => dispatch(calendarTasksActions.loadCalendarTasks(id, date)),
    updateTasksCardsFromList: (customers, taskId) => dispatch(calendarTasksActions.updateTasksCardsFromList(customers, taskId)),
    updateTaskStats: (departmentId, date, taskId, stats) => 
        dispatch(calendarTasksActions.updateTaskStats(departmentId, date, taskId, stats)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreensCalendarTask);
