export const route = {
  onboarding: "/onboarding",
  dashboard: "/",
  customers: "/clientes",
  tarefas: {
    cadastro: '/tarefas',
    start: '/tarefas/start',
  },
  checklist: '/checklist',
  convencoes: '/convencoes',
  irpf: '/IRPF',
  configuracoes: {
    pessoais: '/conta',
    empresa: '/conta/empresa',
    usuarios: '/usuarios', 
  },
  calendario: {
    administrativo: '/calendario/2/administrativo',
    contabil: '/calendario/3/contabil',
    fiscal: '/calendario/4/fiscal',
    pessoal: '/calendario/5/pessoal',
    auditoria: '/calendario/6/auditoria',
    legalizacao: '/calendario/1/legalizacao',
    geral: '/calendario/:id/:department',
  },
  financeiro: {
    mensalidades: '/financeiro/mensalidades',
    extras: '/financeiro/extras',
    comissoes: '/financeiro/comissoes',
  },
  societario: {
    painel: '/societario/painel',
    certificados: '/societario/certificados',
    lembretes: '/societario/lembretes',
  },
  malhaFina: {
    federal: '/malha-fina/federal',
    estadual: '/malha-fina/estadual',
    municipal: '/malha-fina/municipal',
    fgts: '/malha-fina/FGTS',
    irpf: '/malha-fina/IRPF',
    geral: '/malha-fina/:department',
  },
  relatorios: {
    inadimplentes: '/relatorios/inadimplentes',
    prazos: '/relatorios/prazos',
    honorarios: '/relatorios/honorarios',
    failedEmails: '/relatorios/emails/erros',
    feedbacks: '/relatorios/kpis',
  },
  itensEnviados: {
    documentos: '/items-enviados/documentos',
    emails: '/items-enviados/emails',
    emailsFailed: '/items-enviados/emails/erros',
  },
  cadastro: '/cadastro',
  esqueciASenha: '/esqueci-a-senha',
  esqueciASenhaToken: '/esqueci-a-senha/:token',
  convidado: '/convidado/:tenant_id/:invitation',
  leadsForms: '/leads/forms/:tenant_id/:id',
  pluginsForms: '/f/:tenant_id/:token',
  formsIndication: '/i/:id/:indicated_by_id?',
  solicitacoes: '/solicitacoes',
  documentos: '/documentos',
  crm: {
    leads: '/crm/leads',
    socialSelling: '/crm/social-selling',
    prospeccao: '/crm/prospeccao',
    prospeccaoTable: '/crm/prospeccao/table',
    referralProgram: '/crm/indicacao-premiada',
  },
  v2LeadsInssObra: '/v2/leads/inss_obra',
  links: '/links',
  boards: '/boards',
  plugins: {
    geral: '/plugins',
    forms: '/plugins/forms',
  },
  baseDeConhecimento: '/base-de-conhecimento',
  referralProgram: '/referral-program',
  sair: '/sair',
  login: '/login',
  conta: '/conta',
  permissoes: '/permissoes',
  lembretes: '/lembretes',
  financeiroExtras: '/financeiro/extras',
  financeiroMensalidades: '/financeiro/mensalidades',
  financeiroComissoes: '/financeiro/comissoes',
  crmLeads: '/crm/leads',
  recuperacaoTributaria: '/recuperacao-tributaria',
  crmProspeccao: '/crm/prospeccao',
  crmSocialSelling: '/crm/social-selling',
  relatoriosInadimplentes: '/relatorios/inadimplentes',
  relatoriosPrazos: '/relatorios/prazos',
  relatoriosHonorarios: '/relatorios/honorarios',
  relatoriosEmails: '/relatorios/emails',
  relatoriosKpis: '/relatorios/kpis',
};