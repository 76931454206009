import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Form, Input, Icon, Divider, Spin, message } from 'antd';
import { Button, Title } from './Styled';
import logo from './images/logo.png';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { AccountStorage } from '~/storages/account';

const InvitedForm = ({
    invitation,
    form,
    tenant_id
}) => {
    const [status, setStatus] = useState('validating');
    const [spin, setSpin] = useState('Validando convite..');
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        validate(invitation);
    }, [invitation]);

    const validate = (invitation) => {
        Axios.post(`${process.env.REACT_APP_API}/auth/invited/validate`, {
            invitation
        }, {
            headers: {
                'X-Tenant': tenant_id
            }
        })
        .then(response => {
            setStatus('valid');
            setEmail(response.data.data);
        })
        .catch(error => {
            const { response } = error;
            if (response && (response.status === 404 || response.status >= 500)) {
                message.error(DEFAULT_MESSAGES['high-demand']);
            } else {
                setStatus('error');
                setError('Convite inválido ou expirado.');
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            setStatus('submiting');
            setSpin('Aguarde...');

            Axios.post(`${process.env.REACT_APP_API}/auth/invited/register`, {
                ...values,
                invitation
            }, {
                headers: {
                    'X-Tenant': tenant_id
                }
            })
            .then(response => {
                AccountStorage.set({ token: response.data.api_token });
                window.location = '/';
            })
            .catch(error => {
                const { response } = error;
                if (response && (response.status === 404 || response.status >= 500)) {
                    message.error(DEFAULT_MESSAGES['high-demand']);
                } else {
                    setStatus('error');
                    setError('Não foi possível concluir sua solicitação. Atualize a página e tente novamente.');
                }
            });
        });
    }

    const { getFieldDecorator } = form;

    return (
        <Spin tip={spin} size="large" spinning={(status === 'validating' || status === 'submiting')}>
            <Form style={{minHeight: `400px`}} onSubmit={handleSubmit}>
                <Title>
                    <p>
                        <img src={logo} alt="Contlabs" style={{height: `35px`}} />
                    </p>
                    <Divider />
                    {status === 'valid' && (
                    <h1>Você foi convidado!
                        <p><b>{email}</b></p>
                        <p><small>Preencha o formulário abaixo para continuar</small></p>
                    </h1>
                    )}
                    {status === 'error' && (
                    <div>
                        <h1 style={{color: `#f5222d`}}><Icon type="warning"/> {error}</h1>
                        <a href={'https://contlabs.com.br'}>Ir para a página inicial</a>
                    </div>
                    )}
                </Title>
                {status === 'valid' && (
                <div>
                    <Form.Item label="Nome">
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'O nome é obrigatório.' }]
                        })(
                            <Input 
                                type="name" 
                                size="large"
                                placeholder="Digite seu nome completo"
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Senha">
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'A senha é obrigatório.' }]
                        })(
                            <Input.Password
                                type="password" 
                                size="large"
                                placeholder="Defina uma senha"
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        )}
                    </Form.Item>
                    <Button size="large" htmlType="submit" block>
                        Acessar
                    </Button>
                </div>
                )}
            </Form>
        </Spin>
    );
}

export default Form.create()(InvitedForm);