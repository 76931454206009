import React from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Button, Tooltip, Modal } from 'antd';
import moment from 'moment';

import DocumentUpload from './Drawers/DocumentUpload';
import { getSubdomain } from '~/helpers/getSubdomain';
import * as documentsActions from '~/containers/Documents/actions';
import { DocumentsTable } from './Tables/DocumentsTable';
import {
  BottonsContainer,
  ButtonsWrapper,
  DocumentButtonWrapper,
  Footer,
  StyledDrawer,
  SwitchContainer,
  SwitchLabel,
  SwtichStyled
} from './styles';
import { AddDocumentButton, RequiredDocumentErrorMessage } from '../styles';
import { DeliveryMethodButtons } from './DeliveryMethodButtons';

class CustomFields extends React.Component {
  state = {
    documentDrawer: {
      isOpen: false,
      data: null
    },
    files: [],
    impersonateAccessLoading: null,
    isWithoutMovement: false,
    hasRequiredDocumentError: false,
    hasNotDocument: false,
    isSubmiting: false,
  };

  saveDocument = async (data, form) => {
    this.setState({ isSubmiting: true });
    try {
      if (!this.props.data.task_record_id) {
        const task = this.props.task;
        const taskData = this.props.data;

        data.append(
          'task_record_data',
          JSON.stringify({
            task_id: task.id,
            customer_id: taskData.customer.id,
            due_date: task.due_date,
          })
        );

        await this.props.saveFields(0, data);
      } else {
        await this.props.saveFields(this.props.data.task_record_id, data);
      }

      await this.props.loadTaskList();

      form.resetFields();
      this.setState({ files: [] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isSubmiting: false });
    }
  }

  handleSubmit = () => {
    const { form } = this.props;

    form.validateFields(async (err, values) => {
      const { file_upload } = this.props.task;
      const { files: filesFromData } = this.props.data;
      const {
        files: filesInState,
        hasRequiredDocumentError,
        isWithoutMovement,
        hasNotDocument
      } = this.state;

      const allFiles = [...filesFromData, ...filesInState];

      if (!hasNotDocument) {
        if ((file_upload === 1 && allFiles.length === 0) && !isWithoutMovement) {
          this.setState({ hasRequiredDocumentError: true });
          return;
        }
      }

      if (err) {
        return;
      }

      if (hasRequiredDocumentError) {
        this.clearHasRequiredDocumentError();
      }

      const data = new FormData();

      data.append('is_without_movement', isWithoutMovement ? '1' : '0');
      data.append('has_not_document', hasNotDocument ? '1' : '0');

      if (this.state.files.length > 0) {
        this.state.files.forEach((value) => {
          data.append('files[]', value.file);

          const { file, ...onlyFilesInfo } = value;
          data.append('files_info[]', JSON.stringify(onlyFilesInfo));
        });
      }

      Object.keys(values).forEach((key) => {
        data.append(key, values[key]);
      });

      if (
        !!this.props.data &&
        !!this.props.data.executed_at
        ) {
        await this.saveDocument(data, form);
      } else {
        Modal.confirm({
          title: 'Deseja marcar essa tarefa como executada?',
          content: 'Você poderá marcar como não executada depois.',
          okText: 'Sim',
          cancelText: 'Não',
          onOk: async () => {
            data.append('mark_as_executed', 1);
            await this.saveDocument(data, form);
          },
          onCancel: async () => {
            data.append('mark_as_executed', 0);
            await this.saveDocument(data, form);
          },
        });
      }
    });
  };

  impersonateAccess = (file) => {
    this.setState({ impersonateAccessLoading: file.id });
    const data = {
      customer_id: this.props.data.customer.id,
    };

    this.props
      .impersonateAccess(file.id, data)
      .then((response) => {
        const apiToken = response.payload.data.data.api_token;

        const customerAppLink = `http://${
          process.env.REACT_APP_CUSTOMER_DOMAIN
        }/?file=${file.id}&token=${apiToken}`;

        window.open(customerAppLink, '_blank').focus();
      })
      .finally(() => {
        this.setState({ impersonateAccessLoading: null });
      });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { data: prevData } = prevProps;
    const { data } = this.props; 

    if (!prevData && data) {
      this.setState({
        isWithoutMovement: data.is_without_movement,
        hasNotDocument: data.has_not_document,
       });
    }
  }

  getUserById = (id) => {
    const { users } = this.props;
    return users.find((item) => item.id === id);
  }

  getTooltipTitle = () => {
    const { data } = this.props;
    const { isWithoutMovement } = this.state;

    if (!data || !data.is_without_movement || !isWithoutMovement) return '';

    const user = this.getUserById(data.checked_as_without_movement_by);
    const checkedAsWithoutMovementAt = moment(data.checked_as_without_movement_at).format('DD/MM/YYYY HH:mm');

    if (user) {
      return `Marcado as ${checkedAsWithoutMovementAt} por ${user.name}`;
    }

    return `Marcado as ${checkedAsWithoutMovementAt}`;
  }

  closeDrawer = () => {
    const { onClose } = this.props;
    const { hasRequiredDocumentError } = this.state;
    
    if (hasRequiredDocumentError) {
      this.clearHasRequiredDocumentError();
    }

    this.setState({
      files: []
    });

    onClose();
  }

  clearHasRequiredDocumentError = () => {
    this.setState({ hasRequiredDocumentError: false });
  }

  clearFieldsErrors = () => {
    const { form } = this.props;

    const currentFields = form.getFieldsValue();

    const fieldsWithoutErrors = {};

    Object.entries(currentFields).forEach(([key, value]) => {
      fieldsWithoutErrors[key] = {
        value,
        errors: null
      }
    })

    form.setFields(fieldsWithoutErrors);
  }

  handleWithoutMovement = (isChecked) => {
    if (isChecked) {
      this.clearFieldsErrors();
      this.clearHasRequiredDocumentError();
    }

    this.setState({
      isWithoutMovement: isChecked,
    });
  }

  handleHasNotDocument = (isChecked) => {
    if (isChecked) {
      this.clearHasRequiredDocumentError();
      const { setFieldsValue } = this.props.form;
      const customFields = this.props.task.custom_fields
        ? Object.entries(this.props.task.custom_fields)
        : [];

      customFields.forEach(([fieldKey, fieldValue]) => {
        const fieldType = fieldValue[1];

        if (fieldType === 'money') {
          setFieldsValue({
            [fieldKey]: '0,00'
          })
        }
      })
    }

    this.setState({
      hasNotDocument: isChecked
    })
  }

  openDocumentDrawer = (data = null) => {
    this.setState({
      documentDrawer: {
        isOpen: true,
        data
      }
    })
  }

  closeDocumentDrawer = () => {
    this.setState({
      documentDrawer: {
        isOpen: false,
        data: null
      }
    })
  }

  onCreateDocument = (document) => {
    const { files } = this.state;

    this.setState({
      files: [...files, document],
    });
    this.clearHasRequiredDocumentError();
  };

  onUpdateDocument = (document) => {
    const { files } = this.state;

    this.setState({
      files: files.map((existingDocument) => {
        return existingDocument.id !== document.id ? existingDocument : document;
      })
    })
  }

  render() {
    const { data, task, form } = this.props;
    const {
      isWithoutMovement,
      hasNotDocument,
      documentDrawer,
      files: filesInState,
    } = this.state;

    const { getFieldDecorator } = form;

    const files = data ? [...data.files, ...filesInState] : [];

    return (
      <StyledDrawer
        title={data && data.customer.name}
        width={files.length > 0 ? 1200 : 320}
        onClose={this.closeDrawer}
        visible={data !== null}
        afterVisibleChange={(isVisible) => {
          if (!isVisible) {
            this.props.onClose()
          }
        }}
      >
        {task && (
          <Form layout="vertical">
            <Row gutter={16}>
              {task.custom_fields &&
                Object.keys(task.custom_fields).map((key) => (
                  <Col span={24} key={key}>
                    <Form.Item label={task.custom_fields[key][0]}>
                      {getFieldDecorator(key, {
                        initialValue:
                          data &&
                          data.custom_fields !== null &&
                          data.custom_fields.hasOwnProperty(key) &&
                          data.custom_fields[key] !== 'null'
                            ? data.custom_fields[key]
                            : '',
                        rules: !isWithoutMovement && !hasNotDocument ? [
                          {
                            message: 'Por favor, preencha o campo!',
                            required: true,
                          },
                        ] : [],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                ))}
            </Row>
            {task.file_upload === 1 && (
              <DocumentButtonWrapper>
                <AddDocumentButton
                  disabled={this.state.hasNotDocument}
                  className={this.state.hasRequiredDocumentError ? 'has-error' : undefined}
                  type="primary"
                  onClick={() => this.openDocumentDrawer()}
                >
                  Adicionar documento
                </AddDocumentButton>
                <RequiredDocumentErrorMessage
                  className={this.state.hasRequiredDocumentError ? 'has-error' : undefined}
                >
                  Por favor, adicione pelo menos um arquivo!
                </RequiredDocumentErrorMessage>
                <SwitchContainer>
                  <SwtichStyled
                    size="small"
                    checked={hasNotDocument}
                    onChange={this.handleHasNotDocument}
                  />
                  <SwitchLabel>Não há documentos</SwitchLabel>
                </SwitchContainer>
                {files.length > 0 && (
                  <DocumentsTable
                    files={files}
                    selectedTaskRecord={data}
                    openDocumentDrawer={this.openDocumentDrawer}
                  />
                )}
              </DocumentButtonWrapper>
            )}
            <BottonsContainer>
              {data && data.files.length > 0 && (
                <ButtonsWrapper>
                  <DeliveryMethodButtons
                    filesInState={filesInState}
                    data={data}
                    updateCustomers={this.props.updateCustomers}
                  />
                </ButtonsWrapper>
              )}
              <SwitchContainer>
                <Tooltip
                  title={this.getTooltipTitle()}
                >
                  <SwtichStyled
                    size="small"
                    checked={isWithoutMovement}
                    onChange={this.handleWithoutMovement}
                  />
                </Tooltip>
                <SwitchLabel>Empresa sem movimentação</SwitchLabel>
              </SwitchContainer>
            </BottonsContainer>
          </Form>
        )}
        <Footer>
          <Button onClick={this.closeDrawer}>Voltar</Button>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSubmiting}
          >
            Salvar
          </Button>
        </Footer>
        <DocumentUpload
          visible={documentDrawer.isOpen}
          data={documentDrawer.data}
          onClose={this.closeDocumentDrawer}
          onCreateDocument={this.onCreateDocument}
          onUpdateDocument={this.onUpdateDocument}
          task={task}
        />
      </StyledDrawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    user: state.user,
  }
}

const mapDispatchProps = (dispatch) => {
  return {
    saveFields: (record_task_id, data) =>
      dispatch({
        type: 'CALENDAR_TASK_SAVE_FIELDS',
        payload: {
          request: {
            method: 'post',
            url: `/calendar/task_record/${record_task_id}/fields`,
            data,
          },
        },
      }),
    impersonateAccess: (id, data) =>
      dispatch(documentsActions.impersonateAccess(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(CustomFields));
