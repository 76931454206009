import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import StartHereGraphic from '~/_domains/onboarding/StartHereGraphic';
import { ROUTES } from "~/constants/routes.constants";
import { PERMISSIONS } from "~/constants/permissions.constants";
import { getAllGraphPermissions } from "~/utils/permissions";
import { route } from "./routes";

export const getMenuItems = (user, links, linkCategories, failedEmailsCount, solicitationsCount, unlockedMenus) => {
  const history = useHistory();
  const isFirstRender = React.useRef(true);
  const onboardingData = user.onboarding_status || [];
  const tenant = user.tenant || {};
  const isBetaTester = tenant.is_beta_tester || false;
  const tenant_id = user.tenant.id || null;

  const done = onboardingData.filter(item => item.status === 'done').reverse();
  const progress = Math.round((done.length / onboardingData.length) * 100);

  const userPermissions = user.permissions ? user.permissions : [];
  const hasPermission = (permission) => {
    if (Array.isArray(permission)) {
      return permission.some(perm => userPermissions.includes(perm));
    }
    return userPermissions.includes(permission);
  };

  const canViewLinks = user.permissions
    ? user.permissions.includes(PERMISSIONS.links.view)
    : false;

  const activeLinks = links.filter((link) => link.show_in_menu);

  const activeLinkCategories = linkCategories
    .filter((category) => category.show_in_menu)
    .map((category) => {
      const currentLinks = links.filter(
        (link) => link.link_category_id === category.id
      );
      return {
        ...category,
        currentLinks: currentLinks,
      };
    });
    
  const menuItems = [
    {
      key: route.dashboard,
      icon: "dashboard",
      title: "Dashboard",
      link: route.dashboard,
      permission: getAllGraphPermissions(),
      disabled: false
    },
    {
      key: route.customers,
      icon: "team",
      title: "Clientes",
      link: route.customers,
      permission: PERMISSIONS.customers.view,
      disabled: !unlockedMenus.includes(route.customers),
    },
    {
      key: "tarefas",
      icon: "schedule",
      title: "Tarefas",
      disabled: (
        !unlockedMenus.includes(route.tarefas.cadastro) &&
        !unlockedMenus.includes(route.checklist)  &&
        !unlockedMenus.includes(route.convencoes)  &&
        !unlockedMenus.includes(route.irpf)  &&
        !unlockedMenus.includes(route.calendario.administrativo)  &&
        !unlockedMenus.includes(route.calendario.contabil)  &&
        !unlockedMenus.includes(route.calendario.fiscal)  &&
        !unlockedMenus.includes(route.calendario.pessoal)  &&
        !unlockedMenus.includes(route.calendario.auditoria)
      ),
      subMenu: [
        {
          key: route.tarefas.cadastro,
          title: "Cadastro de tarefas",
          link: route.tarefas.cadastro,
          permission: PERMISSIONS.calendarTasks.create,
          icon: "form",
          disabled: !unlockedMenus.includes(route.tarefas.cadastro),
        },
        {
          key: route.checklist,
          title: "Checklist",
          link: route.checklist,
          permission: PERMISSIONS.checklist.view,
          icon: "check-square",
          disabled: !unlockedMenus.includes(route.checklist),
        },
        {
          key: route.convencoes,
          title: "Convenções coletiva",
          link: route.convencoes,
          permission: PERMISSIONS.conventions.view,
          icon: "file-protect",
          disabled: true,
        },
        {
          key: "tarefas-do-mes",
          title: "Tarefas do mês",
          icon: "calendar",
          permission: PERMISSIONS.calendarTasks.view,
          disabled: (
            !unlockedMenus.includes(route.calendario.administrativo)  &&
            !unlockedMenus.includes(route.calendario.contabil)  &&
            !unlockedMenus.includes(route.calendario.fiscal)  &&
            !unlockedMenus.includes(route.calendario.pessoal)  &&
            !unlockedMenus.includes(route.calendario.auditoria)
          ),
          subMenu: [
            {
              key: route.calendario.administrativo,
              title: "Administrativo",
              link: route.calendario.administrativo,
              departmentId: 2,
              permission: PERMISSIONS.calendarTasks.view,
              icon: "solution",
              disabled: !unlockedMenus.includes(route.calendario.administrativo),
            },
            {
              key: route.calendario.contabil,
              title: "Contábil",
              link: route.calendario.contabil,
              departmentId: 3,
              permission: PERMISSIONS.calendarTasks.view,
              icon: "calculator",
              disabled: !unlockedMenus.includes(route.calendario.contabil),
            },
            {
              key: route.calendario.fiscal,
              title: "Fiscal",
              link: route.calendario.fiscal,
              departmentId: 4,
              permission: PERMISSIONS.calendarTasks.view,
              icon: "audit",
              disabled: !unlockedMenus.includes(route.calendario.fiscal),
            },
            {
              key: route.calendario.pessoal,
              title: "Pessoal",
              link: route.calendario.pessoal,
              departmentId: 5,
              permission: PERMISSIONS.calendarTasks.view,
              icon: "team",
              disabled: !unlockedMenus.includes(route.calendario.pessoal),
            },
            {
              key: route.calendario.auditoria,
              title: "Sucesso do Cliente",
              link: route.calendario.auditoria,
              departmentId: 6,
              permission: PERMISSIONS.calendarTasks.view,
              icon: "smile",
              disabled: !unlockedMenus.includes(route.calendario.auditoria),
            },
          ],
        },
        {
          key: route.irpf,
          title: "IRPF",
          link: route.irpf,
          permission: PERMISSIONS.irpf.view,
          icon: "file-done",
          disabled: true,
        },
      ],
    },
    {
      key: "/solicitacoes",
      icon: "inbox",
      title: "Solicitações",
      link: "/solicitacoes",
      permission: PERMISSIONS.solicitations.view,
      badge: solicitationsCount,
      badgeLink: '/solicitacoes',
      disabled: true,
    },
    {
      key: "financeiro",
      icon: "calculator",
      title: "Financeiro",
      permission: PERMISSIONS.financial.monthlyPayments.view,
      disabled: true,
      subMenu: [
        {
          key: route.financeiro.mensalidades,
          title: "Mensalidades",
          link: route.financeiro.mensalidades,
          permission: PERMISSIONS.financial.monthlyPayments.view,
          icon: "dollar",
          disabled: true,
        },
        {
          key: route.financeiro.extras,
          title: "Serviços extras",
          link: route.financeiro.extras,
          permission: PERMISSIONS.extraServices.view,
          icon: "plus",
          disabled: true,
        },
        {
          key: route.financeiro.comissoes,
          title: "Comissões",
          link: route.financeiro.comissoes,
          permission: PERMISSIONS.refunds.view,
          icon: "percentage",
          disabled: true,
        },
      ],
    },
    {
      key: "societario",
      icon: "file-sync",
      title: "Dep. Societário",
      disabled: true,
      subMenu: [
        {
          key: route.societario.painel,
          title: "Painel",
          link: route.societario.painel,
          permission: PERMISSIONS.corporate.view,
          icon: "dashboard",
          disabled: true,
        },
        {
          key: route.calendario.legalizacao,
          title: "Tarefas",
          link: route.calendario.legalizacao,
          departmentId: 1,
          permission: PERMISSIONS.calendarTasks.view,
          icon: "schedule",
          disabled: true,
        },
        {
          key: route.societario.certificados,
          title: "Certificados digitais",
          link: route.societario.certificados,
          permission: PERMISSIONS.certificates.view,
          icon: "safety-certificate",
          disabled: true,
        },
        {
          key: route.societario.lembretes,
          title: "Lembretes",
          link: route.societario.lembretes,
          permission: PERMISSIONS.procurations.view,
          icon: "bell",
          disabled: true,
        },
        {
          key: "malha-fiscal",
          title: "Malha fiscal",
          icon: "alert",
          disabled: true,
          subMenu: [
            {
              key: route.malhaFina.federal,
              title: "Federal",
              link: route.malhaFina.federal,
              icon: "global",
              disabled: true,
            },
            {
              key: route.malhaFina.estadual,
              title: "Estadual",
              link: route.malhaFina.estadual,
              icon: "flag",
              disabled: true,
            },
            {
              key: route.malhaFina.municipal,
              title: "Municipal",
              link: route.malhaFina.municipal,
              icon: "home",
              disabled: true,
            },
            {
              key: route.malhaFina.fgts,
              title: "FGTS",
              link: route.malhaFina.fgts,
              icon: "bank",
              disabled: true,
            },
            {
              key: route.malhaFina.irpf,
              title: "IRPF",
              link: route.malhaFina.irpf,
              icon: "file-done",
              disabled: true,
            },
          ],
          permission: PERMISSIONS.irpf.malhaFina.view,
        },
      ],
    },
    {
      key: "itensEnviados",
      icon: "cloud-upload",
      title: "Itens Enviados",
      badge: failedEmailsCount,
      badgeLink: route.itensEnviados.emailsFailed,
      disabled: (
        !unlockedMenus.includes(route.itensEnviados.documentos) &&
        !unlockedMenus.includes(route.itensEnviados.emails)
      ),
      subMenu: [
        {
          key: route.itensEnviados.documentos,
          title: "Documentos enviados",
          link: route.itensEnviados.documentos,
          permission: PERMISSIONS.documents.view,
          icon: "file",
          disabled: !unlockedMenus.includes(route.itensEnviados.documentos),
        },
        {
          key: route.itensEnviados.emails,
          title: "E-mails enviados",
          link: route.itensEnviados.emails,
          permission: PERMISSIONS.sentEmails.view,
          icon: "mail",
          disabled: !unlockedMenus.includes(route.itensEnviados.emails),
        },
      ],
    },
    ...(isBetaTester ? [{
      key: "/crm",
      icon: "project",
      title: "CRM",
      subMenu: [
        {
          key: ROUTES["/crm/prospeccao"],
          title: "Prospecção Ativa",
          link: ROUTES["/crm/prospeccao"],
          permission: PERMISSIONS.prospecting.view,
          icon: "user-add",
        },
        {
          key: ROUTES["/crm/leads"],
          title: "Prospecção Passiva",
          link: ROUTES["/crm/leads"],
          permission: PERMISSIONS.leads.view,
          icon: "usergroup-add",
        },
        {
          key: ROUTES["/crm/social-selling"],
          title: "Social Selling",
          link: ROUTES["/crm/social-selling"],
          permission: PERMISSIONS.socialSelling.view,
          icon: "share-alt",
        },
        {
          key: route.crm.referralProgram,
          title: "Indicação Premiada",
          link: route.crm.referralProgram,
          permission: PERMISSIONS.referralProgram.view,
          icon: "gift",
        },
      ],
    }] : [
      {
        key: ROUTES["/crm/leads"],
        icon: "project",
        title: "CRM",
        link: ROUTES["/crm/leads"],
        permission: PERMISSIONS.leads.view,
        disabled: true,
      },
    ]),
    ...(isBetaTester ? [{
      key: "/recuperacao-tributaria",
      icon: "reconciliation",
      title: "RT - Recuperação Tributária",
      link: "/recuperacao-tributaria",
      permission: PERMISSIONS.leadsRT.view,
      disabled: true,
    }] : []),
    ...((tenant_id === 'real') ? [{
      key: route.v2LeadsInssObra,
      icon: "reconciliation",
      title: "INSS Obra",
      link: route.v2LeadsInssObra,
      permission: PERMISSIONS.leads.view,
      disabled: true,
    }] : []),
    {
      key: "relatorios",
      icon: "book",
      title: "Relatórios",
      disabled: true,
      subMenu: [
        {
          key: route.relatorios.inadimplentes,
          title: "Inadimplentes",
          link: route.relatorios.inadimplentes,
          permission: PERMISSIONS.reports.defaulters,
          icon: "warning",
          disabled: true,
        },
        {
          key: route.relatorios.prazos,
          title: "Prazos",
          link: route.relatorios.prazos,
          permission: PERMISSIONS.documentsDeadline.view,
          icon: "clock-circle",
          disabled: true,
        },
        {
          key: route.relatorios.honorarios,
          title: "Controle de honorários",
          link: route.relatorios.honorarios,
          permission: PERMISSIONS.honoraries.view,
          icon: "dollar",
          disabled: true,
        },
        {
          key: route.relatorios.feedbacks,
          title: "KPIs",
          link: route.relatorios.feedbacks,
          permission: PERMISSIONS.feedbacks.view,
          icon: "line-chart",
          disabled: true,
        },
      ],
    },
    ...(isBetaTester ? [{
      key: "/boards",
      icon: "schedule",
      title: "Marketing",
      link: "/boards",
      permission: PERMISSIONS.boards.view,
      disabled: true,
    }] : []),
    {
      key: "/plugins",
      icon: "plus-circle",
      title: "Plugins",
      link: "/plugins",
      permission: PERMISSIONS.plugins.view,
      disabled: true,
    },
    {
      key: "/base-de-conhecimento",
      icon: "exclamation-circle",
      title: "Base de conhecimento",
      link: "/base-de-conhecimento",
      permission: PERMISSIONS.knowledgeBase.view,
      disabled: true,
    },
  ];

  if (canViewLinks) {
    if (isBetaTester) {
      menuItems.push({
        key: "links-shortcut",
        icon: "link",
        title: "Links de atalho",
        link: "/links",
        permission: PERMISSIONS.links.view,
        disabled: false,
      });
    }

    if (isBetaTester) {
      activeLinkCategories.forEach((category) => {
        if (category.currentLinks) {
          menuItems.push({
            key: `category-${category.id}`,
            icon: "link",
            title: category.name,
            subMenu: category.currentLinks.map((link) => ({
              key: `link-${link.id}`,
              title: link.title,
              link: link.address,
              iconImg: <img src={`http://www.google.com/s2/favicons?domain=${link.address}`} alt="Ícone do site" width={14} height={14} style={{ marginRight: 10 }} />,
              disabled: false,
            })),
            disabled: false,
          });
        }
      });
    }

    if(isBetaTester){
      activeLinks.forEach((link) => {
        menuItems.push({
          key: `link-${link.id}`,
          title: link.title,
          link: link.address,
          iconImg: <img src={`http://www.google.com/s2/favicons?domain=${link.address}`} alt="Ícone do site" width={14} height={14} style={{ marginRight: 10 }} />,
          disabled: false,
        });
      });
    }
  }

  if (onboardingData.length > 0) {
    menuItems.unshift({
      key: route.onboarding,
      icon: "rocket",
      title: "Comece agora",
      link: route.onboarding,
      component: <StartHereGraphic progress={progress} />,
      disabled: false,
    });
  } else {
    menuItems.forEach(item => {
      item.disabled = false;
      if (item.subMenu) {
        item.subMenu.forEach(subItem => {
          subItem.disabled = false;
          if(subItem.subMenu){
            subItem.subMenu.forEach(subSubItem => {
              subSubItem.disabled = false;
            });
          }
        });
      }
    });
  }

  // remove menu items that user does not have permission
  let filteredMenuItems = menuItems.filter(item => {
    if (item.permission) {
      return hasPermission(item.permission);
    }
    if (item.subMenu) {
      item.subMenu = item.subMenu.filter(subItem => {
        if (subItem.permission) {
          return hasPermission(subItem.permission);
        }
        return true;
      });
    }
    return true;
  });

  // if item has no link and no subMenu, remove it
  filteredMenuItems = filteredMenuItems.filter(item => item.link || (item.subMenu && item.subMenu.length > 0));

  // if progress is 100%, consider all menu items as enabled
  if (progress === 100) {
    filteredMenuItems.forEach(item => {
      item.disabled = false;
      if (item.subMenu) {
        item.subMenu.forEach(subItem => {
          subItem.disabled = false;
          if(subItem.subMenu){
            subItem.subMenu.forEach(subSubItem => {
              subSubItem.disabled = false;
            });
          }
        });
      }
    });
  }

  const activeMenuItems = filteredMenuItems.filter(item => !item.disabled);
  const disabledMenuItems = filteredMenuItems.filter(item => item.disabled);

  const sortedMenuItems = [...activeMenuItems, ...disabledMenuItems];

  useEffect(() => {
    if (isFirstRender.current && window.location.pathname === '/') {
      const dashboardItem = menuItems.find(item => item.key === "/");
      if (dashboardItem && dashboardItem.disabled) {
        history.push('/onboarding');
      }
      isFirstRender.current = false;
    }
  }, []);

  return sortedMenuItems;
};
