import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Upload, message, Tabs } from 'antd';
import { request } from '~/components/Instance';
import InputMask from '~/components/UI/InputMask';
import { getSubdomain } from '~/helpers/getSubdomain';
import { AccountStorage } from '~/storages/account';
import { useAppContext } from '~/AppContext';

const { TabPane } = Tabs;
const FormItem = Form.Item;

const SettingsCompanyForm = ({ form, company, updateCompany }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(company.logoUrl);
  const { setOnboardingTaskAsDone } = useAppContext();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        request('patch', '/company', values).then(response => {
          updateCompany({
            name: values.name,
            legal_name: values.legal_name,
            accountant: values.accountant,
            crc: values.crc,
            national_register: values.national_register,
            website: values.website,
            email: values.email,
            phone: values.phone,
            address: values.address,
            zipcode: values.zipcode,
            city: values.city,
            state: values.state
          });

          // Update the onboardingData
          setOnboardingTaskAsDone('g7h8i9');
        });
      }
    });
  };

  const uploadButton = (
    <div>
      {loading ? <Icon type="loading" /> : <Icon type="plus" />}
      <div style={{ marginTop: 8 }}>Insira a logo</div>
    </div>
  );

  const dataFiles = {
    action: `${process.env.REACT_APP_API}/auth/company/logo`,
    multiple: false,
    listType: "picture-card",
    showUploadList: false,
    headers: {
      Authorization: `Bearer ${AccountStorage.get().token}`,
      'X-Tenant': getSubdomain()
    },
    onChange(info) {
      if (info.file.status === 'uploading') {
        setLoading(true);
        setImageUrl(null);
        return;
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, imageUrl => {
          setImageUrl(imageUrl);
          setLoading(false);

          // Update the onboardingData
          setOnboardingTaskAsDone('j0k1l2');

          // Update the store with the new logo URL
          updateCompany({ logoUrl: imageUrl });
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    }
  };

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 4 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 18 } },
  };
  const tailFormItemLayout = {
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 4 } },
  };

  return (
    <>
    <FormItem >
      <Upload {...dataFiles}>
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </FormItem>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Dados" key="1">
          <Form layout="horizontal" onSubmit={handleSubmit}>
            <FormItem {...formItemLayout} label="Nome Fantasia">
              {form.getFieldDecorator('name', {
                initialValue: company.name,
                rules: [{ required: true, message: 'Por favor informe o nome fantasia!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Razão Social">
              {form.getFieldDecorator('legal_name', {
                initialValue: company.legal_name,
                rules: [{ required: true, message: 'Por favor informe a Razão Social!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Contador">
              {form.getFieldDecorator('accountant', {
                initialValue: company.accountant,
                rules: [{ required: false, message: 'Por favor informe o Contador!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="CRC">
              {form.getFieldDecorator('crc', {
                initialValue: company.crc,
                rules: [{ required: false, message: 'Por favor informe o nome do CRC!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="CNPJ">
              {form.getFieldDecorator('national_register', {
                initialValue: company.national_register,
                rules: [{ required: false, message: 'Por favor informe o CNPJ!', whitespace: true }],
              })(<InputMask mask="99.999.999/9999-99" formatChars={{ "9": "[0-9]" }} maskChar={null} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Site">
              {form.getFieldDecorator('website', {
                initialValue: company.website,
                rules: [{ required: false, message: 'Por favor informe o Site!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Email">
              {form.getFieldDecorator('email', {
                initialValue: company.email,
                rules: [{ required: false, message: 'Por favor informe o Email!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Telefone">
              {form.getFieldDecorator('phone', {
                initialValue: company.phone,
                rules: [{ required: false, message: 'Por favor informe o Telefone!', whitespace: true }],
              })(<InputMask mask="(99) 9999tt999?" formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }} maskChar={null} />)}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">Salvar</Button>
            </FormItem>
          </Form>
        </TabPane>
        <TabPane tab="Endereço" key="2">
          <Form layout="horizontal" onSubmit={handleSubmit}>
            <FormItem {...formItemLayout} label="Endereço">
              {form.getFieldDecorator('address', {
                initialValue: company.address,
                rules: [{ required: false, message: 'Por favor informe o Endereço!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="CEP">
              {form.getFieldDecorator('zipcode', {
                initialValue: company.zipcode,
                rules: [{ required: false, message: 'Por favor informe o CEP!', whitespace: true }],
              })(<InputMask mask="99999-999" formatChars={{ "9": "[0-9]" }} maskChar={null} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Município">
              {form.getFieldDecorator('city', {
                initialValue: company.city,
                rules: [{ required: false, message: 'Por favor informe o Município!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Estado">
              {form.getFieldDecorator('state', {
                initialValue: company.state,
                rules: [{ required: false, message: 'Por favor informe o Estado!', whitespace: true }],
              })(<Input />)}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">Salvar</Button>
            </FormItem>
          </Form>
        </TabPane>
      </Tabs>
    </>
  );
};

const mapStateToProps = state => ({
  company: state.user.tenant
});

const mapDispatchToProps = dispatch => ({
  updateCompany: (data) => dispatch({ type: 'UPDATE_COMPANY', payload: data })
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsCompanyForm));
