/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Input, Icon, Alert, Steps, Modal } from "antd";

import { copyToClipBoard } from '~/utils/copy-to-clipboard';

import { ExtensionButton } from '~/containers/Leads/Header/styles';

import { ChromeIcon } from '~/icons/ChromeIcon';

import step3 from './images/step3.png';
import step4 from './images/step4.png';
import step5 from './images/step5.png';
import step6 from './images/step6.png';

const { Step } = Steps;

const Wpp = ({ user }) => {
    const [showTutorial, setShowTutorial] = useState(false);

    const latestVersionUrl = 'https://drive.google.com/file/d/1h3ds8pUiY-grTBQhTtVbeghks_tLBkRh/view?usp=sharing';
    const showLatestVersion = false;

    return (
        <div>
            {showLatestVersion && (
            <>
                <Alert
                    message="Problemas com a extensão?"
                    description={
                        <div>Instale a versão mais <a href={latestVersionUrl} target="_blank" rel="noopener noreferrer" >recente</a>, clique <a href="#" onClick={() => setShowTutorial(true)}>aqui para visualizar o tutorial</a>.</div>
                    }
                    type="warning"
                    showIcon
                />
                <br />
            </>
            )}
            <Steps current={null} direction="vertical">
                <Step title="Passo 1" description={
                    <div>
                        <p>Para utilizar a extensão do Contlabs para WhatsApp, é necessário instalar a extensão no navegador <strong>Google Chrome</strong>.</p>
                        <p>Para instalar a extensão, clique no botão abaixo:</p>
                        <ExtensionButton
                            type="dashed"
                            href={showLatestVersion ? latestVersionUrl : 'https://chrome.google.com/webstore/detail/contlabs-para-whatsapp/ggpdkeoppcdhhoidbjimchgpbeagikmb?hl=en'}
                            target="_blank"
                            >
                            <ChromeIcon />
                            Baixar extensão
                        </ExtensionButton>
                    </div>
                } />
                <Step title="Passo 2" description={
                    <div>
                        <p>Após instalar a extensão, acesse o Whatsapp Web <a href="https://web.whatsapp.com/" target="_blank">clicando aqui</a>.</p>
                        <p>Em seguida, clique no ícone da extensão do Contlabs no canto superior direito do navegador.</p>
                    </div>
                } />
                <Step title="Passo 3" description={<div>
                    Faça login na extensão utilizando o token abaixo:
                    <div style={{ marginBottom: 16, marginTop: 16 }}>
                        <Input 
                            addonAfter={<Icon type="copy" onClick={() => copyToClipBoard(`${user.tenant.id}:${user.wpp_api_token}`)} />}
                            defaultValue={`${user.tenant.id}:${user.wpp_api_token}`} 
                            disabled
                        />
                    </div>
                </div>} />
            </Steps>

            {showTutorial && (
                <Modal
                    title="Tutorial de instalação"
                    visible={showTutorial}
                    onCancel={() => setShowTutorial(false)}
                    footer={null}
                    width={800}
                >
                    <Steps current={null} direction="vertical">
                        <Step 
                            title="Passo 1"
                            style={{ marginBottom: 16, color: '#1890ff' }}
                            description={<div>
                            <div>
                                <p>Baixe o pacote mais recente.</p>
                                <ExtensionButton
                                    type="dashed"
                                    href={latestVersionUrl}
                                    target="_blank"
                                    >
                                    <ChromeIcon />
                                    Baixar pacote mais recente
                                </ExtensionButton>
                            </div>
                        </div>} />
                        <Step title="Passo 2" description={<div>
                            <div>
                                <p>Abra o pacote (descompacte o arquivo ZIP).</p>
                            </div>
                        </div>} />
                        <Step title="Passo 3" description={
                            <div>
                                <p>Desinstale a atual versão da extensão do Contlabs do navegador.</p>
                                <img src={step3} alt="Passo 3" style={{ width: '100%' }} />
                            </div>
                        } />
                        <Step title="Passo 4" description={
                            <div>
                                <p>Clique em gerenciar extensões.</p>
                                <img src={step4} alt="Passo 4" style={{ width: '100%' }} />
                            </div>
                        } />
                        <Step title="Passo 5" description={<div>
                            <div>
                                <p>Ative o modo desenvolvedor.</p>
                                <img src={step5} alt="Passo 5" style={{ width: '100%' }} />
                            </div>
                        </div>} />
                        <Step title="Passo 6" description={<div>
                            <div>
                                <p>Carregue o novo pacote.</p>
                                <img src={step6} alt="Passo 6" style={{ width: '100%' }} />
                            </div>
                        </div>} />
                        <Step title="Passo 7" description={<div>
                            <div>
                                <p>Pronto! Agora você já pode utilizar a extensão do Contlabs para WhatsApp.</p>
                            </div>
                        </div>} />
                    </Steps>
                </Modal>
            )}
        </div>
    );
}

export default Wpp;