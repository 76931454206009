import styled from 'styled-components';
import { Icon } from 'antd';

// Styled components for task grouping
export const TaskGroupHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 6px;
  border-bottom: 2px solid ${props => props.color || '#d9d9d9'};
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const TaskGroupTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-right: 10px;
  color: ${props => props.color || 'inherit'};
`;

export const TaskGroupCount = styled.span`
  background-color: ${props => props.bgColor || '#e6f7ff'};
  color: ${props => props.color || '#1890ff'};
  border-radius: 20px;
  padding: 3px 12px;
  font-weight: 600;
  font-size: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
`;

export const ToggleIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 16px;
  color: ${props => props.color || 'inherit'};
  transition: transform 0.3s ease;
  transform: ${props => props.expanded ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

export const TaskGroupContainer = styled.div`
  transition: 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: ${props => props.expanded ? 'auto' : '0px'};
  opacity: ${props => props.expanded ? '1' : '0'};
`;

export const TasksSection = styled.div`
  margin-bottom: 24px;
  background-color: #ffffff;
  padding: 5px 16px 16px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

// Scrollable container for horizontal scrolling if needed
export const ScrollableRow = styled.div`
  overflow-x: auto;
  padding-bottom: 8px;
  
  &::-webkit-scrollbar {
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
`;

// Styled empty state container
export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background-color: #fafafa;
  border-radius: 8px;
  margin-top: 20px;
`;

// Styled scrollable container for Tasks component
export const TasksScrollContainer = styled.div`
  height: calc(100vh - 170px);
  overflow: auto;
  padding: 20px;
  background-color: #f0f2f5;
`;
