import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal, Input, Button, Popover } from 'antd';
import Moment from 'react-moment';

import { department, frequency } from './Utils';
import moment from 'moment';
import { ModuleHeader } from '../ModuleHeader';
import { HeaderContainer, HeaderLeftContent } from './styles';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;
const Search = Input.Search;

class List extends Component {
  state = {
    search: ''
  }

  onAction = (e, task) => {
    switch(e.key){
      case 'customers': 
      this.props.openCustomers(task);
      break;
      case 'edit': 
      this.props.showModal(task);
      break;
      // case 'disable': 
      //   Modal.confirm({
      //     title: 'Você realmente deseja desabilitar esta tarefa?',
      //     content: 'Tarefas desabilitadas não aparecem no calendário de tarefas. Esta ação poderá ser desfeita.',
      //     okText: 'Sim',
      //     okType: 'danger',
      //     cancelText: 'Cancelar',
      //     // onOk: () => this.props.deleteTask(task.id)
      //   });
      // break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir esta tarefa?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteTask(task.id)
        });
      break;
      default: console.log(`Action: ${e.key} is not supported`);
    }
  }

  render() {
    const { tasks, isModalVisible, isCustomersModalVisible, isLoading } = this.props;

    const { search } = this.state;

    let filteredTasks = (tasks !== null)? tasks.filter((str) => {

      if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

      return (  
      <div>
         <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
              <ModuleHeader 
                breadcrumbs={['Tarefas', 'Cadastro de tarefas']} 
                search={
                  <Popover
                    placement="right"
                    content="Pesquise pelo nome da tarefa criada"
                    visible={false && !isCustomersModalVisible}
                  >
                    <Search 
                      placeholder="Pesquisar tarefas" 
                      onChange={e => this.setState({search: e.target.value})}
                      style={{ maxWidth: 300, zIndex: false ? 999 : "auto" }}
                    />
                  </Popover>
                }
                actions={
                  <Popover placement="left" content="Clique aqui para cadastrar uma tarefa" visible={false && !isModalVisible}>
                    <Button type="primary" style={{ zIndex: false ? 999 : 'auto' }} onClick={() => this.props.showModal()}>
                        <Icon type="folder-add"/> Nova tarefa
                    </Button>
                  </Popover>
                }
              />
        </div>
        <div className={`table-clabs${false ? ' onboarding-active' : ''}`}>
          <Table 
            rowKey="id"
            loading={isLoading}
            dataSource={filteredTasks} 
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.registerTasks
            })}
            bordered={true}
            rowClassName="row-clabs"
            locale={{
              filterConfirm: 'Ok',
              filterReset: 'Resetar' ,
              emptyText: 'Nenhum registro corresponde ao filtro'
            }}
          >
            <Column
              title="Tarefa"
              dataIndex="name"
              key="name"
              sorter={(a, b) => a.name.localeCompare(b.name)}
              sortDirections={['descend', 'ascend']}
              defaultSortOrder={'ascend'}
            />
            <Column
              title="Departamento"
              dataIndex="department"
              key="department"
              filters={[{
                  text: department(1),
                  value: '1',
                }, {
                  text: department(2),
                  value: '2',
                },
                {
                  text: department(3),
                  value: '3',
                },
                {
                  text: department(4),
                  value: '4',
                },
                {
                  text: department(5),
                  value: '5',
                },
                {
                  text: department(6),
                  value: '6',
                }
              ]}
              filterMultiple={false}
              onFilter={(value, record) => record.department.indexOf(value) === 0}
              render={key => (
                department(key)
              )}
            />
            <Column
              title="Periodicidade"
              dataIndex="frequency"
              key="frequency"
              filters={[{
                text: frequency(0),
                value: 0,
              }, {
                text: frequency(1),
                value: 1,
              },
              {
                text: frequency(2),
                value: 2,
              },
              {
                text: frequency(3),
                value: 3,
              },
              {
                text: frequency(4),
                value: 4,
              }
            ]}
            filterMultiple={false}
            onFilter={(value, record) => record.frequency === value}
            render={(value) => frequency(value)}
            />
            <Column
              title="Cadastro"
              dataIndex="updated_at"
              key="updated_at"
              sorter={(a, b) => a.updated_at.localeCompare(b.updated_at)}
              render={key => (
                <Moment format="DD/MM/YYYY">
                  {key}
                </Moment>
            )}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={status => (
                  <center><Tag color="green" key={status}>{status}</Tag></center>
              )}
            />
            <Column
              dataIndex="actions"
              key="actions"
              render={(text, record, index) => {
                return (
                  <center
                    key={index}
                  >
                    <Dropdown
                      overlayStyle={{
                        zIndex: 999
                      }}
                      overlay={
                        <Menu onClick={(e) => this.onAction(e, record)}>
                          <Menu.Item key="customers">
                            <Icon type="team"></Icon> Clientes
                          </Menu.Item>
                          <Menu.Item key="edit" disabled={false}>
                            <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item key="delete" disabled={false}>
                            <Icon type="delete"></Icon> Excluir
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <a className="ant-dropdown-link" href="#">
                        Ações <Icon type="down" />
                      </a>
                    </Dropdown>
                  </center>
                );
              }}
            />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    tasks: state.tasks.list,
    isLoading: state.tasks.isLoading,
  }
}

const mapDispatchProps = dispatch => {
  return{
    deleteTask: (id) => dispatch({
      type: 'DELETE_TASK',
      payload: {
        request:{
          method: 'delete',
          url:`/task/${id}`,
        }
      },
      id: id
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(List);