import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 30px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

export const Logo = styled.img`
    width: 200px;
    margin-bottom: 20px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ForgotPasswordLink = styled(Link)`
    margin-top: 20px;
`;
