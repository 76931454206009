const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    return `${dd}/${mm}`;
};

export const frequency = (key, dates) => {
    const firstDate = formatDate(dates.first_date);
    switch (parseInt(key)) {
        case 0:
            return `Apenas em ${dates.first_date}`;
        case 1:
            return `Mensal (Dia ${new Date(dates.first_date).getDate()})`;
        case 2:
            return `Trimestral (${firstDate}, ${formatDate(dates.second_date)}, ${formatDate(dates.third_date)}, ${formatDate(dates.fourth_date)})`;
        case 3:
            return `Semestral (${firstDate} e ${formatDate(dates.second_date)})`;
        case 4:
            return `Anual (${firstDate})`;
        default:
            return '';
    }
};
