import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";

import { Layout } from '~/components/UI';
import Drawer from "./Drawer";
import * as actions from "./actions";
import { Header } from "./Header";
import { LeadColumns } from "./LeadColumns";
import { LeadsTable } from "./LeadsTable";

moment.updateLocale("pt-br", {
  weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
});

const Leads = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const columns = useSelector((state) => state.prospecting.columns);
  const isLoading = useSelector((state) => state.prospecting.isLoading);
  const user = useSelector((state) => state.user);

  const [date, setDate] = useState(moment().format("MM-YYYY"));
  const [searchQuery, setSearchQuery] = useState("");

  const showDrawer = useCallback((data) => dispatch(actions.show(data)), [dispatch]);
  const loadMoreLeads = useCallback(({ columnId, currentCount, moreCount }) => {
    dispatch(actions.loadMoreLeads({ columnId, currentCount, moreCount }));
  }, [dispatch]);
  const moveLead = useCallback((...props) => dispatch(actions.move(props)), [dispatch]);

  const displayMode = location.pathname.includes("/prospeccao/table") ? "table" : "funnel";

  const handleDisplayModeChange = useCallback((mode) => {
    const newPath = mode === "table" ? "/crm/prospeccao/table" : "/crm/prospeccao";
    history.push(newPath);
  }, [history]);

  useEffect(() => {
    dispatch(actions.loadLeads());
  }, [dispatch]);

  const filterLeads = useCallback((leads) => leads.filter((lead) =>
    Object.values(lead).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  ), [searchQuery]);

  const filteredData = useMemo(() => columns && columns.length > 0
    ? columns.reduce((acc, column) => acc.concat(filterLeads(column.data)), [])
    : [], [columns, filterLeads]);

  const filteredDataToColumns = useMemo(() => columns.map((column) => ({
    ...column,
    data: filterLeads(column.data),
  })), [columns, filterLeads]);

  return (
    <Layout>
      <Header
        date={date}
        setDate={setDate}
        displayMode={displayMode}
        setDisplayMode={handleDisplayModeChange}
        user={user}
        showDrawer={showDrawer}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      {displayMode === "funnel" && (
        <LeadColumns
          columns={filteredDataToColumns}
          moveLead={moveLead}
          date={date}
          isLoading={isLoading}
          showDrawer={showDrawer}
          loadMoreLeads={loadMoreLeads}
          user={user}
        />
      )}
      {displayMode === "table" && (
        <LeadsTable 
          showDrawer={showDrawer}
          data={filteredData}
          isLoading={isLoading}
          columns={columns}
        />
      )}
      <Drawer />
    </Layout>
  );
};

export default Leads;
