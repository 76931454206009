export const normalizeToFilter = (value) => {
  if (typeof value !== 'string') {
    return '';
  }
  return value
    .toLocaleLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};
