import * as IndexedDB from 'idb-keyval';
import { loadFromCacheAction } from '~/store/actions/load-from-cache';
import { getSubdomain } from '~/helpers/getSubdomain';

export const LocalCacheHandler = {
  load: async ({ dispatch, pathname, actionName }) => {
    const subdomain = getSubdomain();
    const cacheKey = `${subdomain}_${pathname}`;
    const cachedData = await IndexedDB.get(cacheKey);

    if(cachedData && !dispatch) {
      return cachedData;
    }

    if (cachedData) {
      dispatch(
        loadFromCacheAction({
          actionName,
          cachedData,
        })
      );
    }
  },
  save: async ({ pathname, data }) => {
    const subdomain = getSubdomain();
    const cacheKey = `${subdomain}_${pathname}`;
    await IndexedDB.update(cacheKey, () => data);
  },
  clear: async () => {
    await IndexedDB.clear();
  }
};
