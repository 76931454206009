import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip, Switch } from 'antd';
import { WhatsappIcon, phoneMask } from '~/icons/WhatsappIcon';
import moment from 'moment';
import { get } from 'lodash';
import { FaInstagram } from 'react-icons/fa';

import { removeCountryCodeFromPhone } from '~/utils/formatters';
import { copyToClipBoard } from '~/utils/copy-to-clipboard';
import { statusOptions } from '../Drawer/Cadence/helpers';

const WhatsappLink = styled.a`
  > img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

const CadenceStep = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 3px;
  background-color: ${({ status }) => {
    if (status === 'done') return 'green';
    if (status === 'canceled') return 'red';
    return 'gray';
  }};
  border-radius: 5%;
`;

const CadenceRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

export const renderCadence = (cadences) => {
    if (!cadences) return '';
    return cadences.map((row, key) => (
        <CadenceRow key={key}>
            {row.data.map((step) => (
                <Tooltip
                    key={step.uid}
                    title={`Data: ${step.date},\n Tipo: ${step.type},\n Status: ${statusOptions[step.status]}`}
                >
                    <CadenceStep 
                        status={step.status} 
                    />
                </Tooltip>
            ))}
        </CadenceRow>
    ));
};

export const table = {
    getColumns: function({columns, showDrawer, handleRevised}) {
        const handleInstagramLink = (instagramUrl) => {
            if (!instagramUrl) return '';
            const username = instagramUrl.split('/').filter(Boolean).pop();
            return (
                <Tooltip title="Abrir no Instagram">
                    <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                        {username} <FaInstagram />
                    </a>
                </Tooltip>
            );
        };

        return [
            {
                title: 'Revisado',
                dataIndex: 'additional_data.is_revised',
                key: 'additional_data.is_revised',
                className: 'text-center',
                width: 100,
                fixed: 'left',
                render: (id, data) => (
                    <center>
                        {data.isLoading ? (
                            <Icon type="loading" />
                        ) : (
                            <Switch 
                                checked={get(data, 'additional_data.is_revised', false)}
                                onChange={(checked) => handleRevised(checked, data)}
                            />
                        )}
                    </center>
                ),
            },
            {
                title: 'Cadastro',
                dataIndex: 'created_at',
                key: 'created_at',
                className: 'text-center',
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
                render: (created_at) => moment(created_at).format('DD/MM/YYYY'),
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Lead',
                dataIndex: 'name',
                key: 'name',
                width: 400,
                ellipsis: true,
            },
            {
                title: 'Instagram',
                dataIndex: 'additional_data.instagram',
                key: 'additional_data.instagram',
                width: 400,
                ellipsis: true,
                render: handleInstagramLink,
            },
            {
                title: 'Whatsapp',
                dataIndex: 'additional_data.phone',
                key: 'additional_data.phone',
                width: 200,
                ellipsis: true,
                align: 'center',
                render: (phone, customer) => {
                    if (!phone) return '';
                    const p = typeof phone === 'string' ? removeCountryCodeFromPhone(phone).replace(/\D/g, '') : ''; 
                    return (
                    <div style={{textAlign: `center`}}>
                    {p && (
                        <>
                        <Tooltip title="Abrir no Whatsapp">
                        <WhatsappLink
                            href={`https://api.whatsapp.com/send?phone=55${p}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <WhatsappIcon />
                        </WhatsappLink> 
                        </Tooltip>{' '}
                        <Tooltip title={'Clique para copiar'}>
                        <span onClick={() => copyToClipBoard(p)} style={{cursor: 'pointer'}}>
                            {phoneMask(p)}
                        </span>
                        </Tooltip>
                        </>
                    )}
                    </div>
                )},
            },
            {
                title: 'Cadência',
                dataIndex: 'additional_data.cadences',
                key: 'cadences',
                width: 300,
                ellipsis: true,
                render: renderCadence,
            },
            {
                title: 'Etapa',
                dataIndex: 'column_id',
                key: 'column_id',
                width: 200,
                ellipsis: true,
                render: (column_id) => {
                    const column = columns.find(c => c.id === column_id);
                    return column ? (
                    <span style={{ color: column.style.color }}>
                        {column.name}
                    </span>
                    ) : '';
                },
                filters: columns.map(column => ({ text: column.name, value: column.id })),
                onFilter: (value, record) => record.column_id === value,
            },
            {
                title: '',
                width: 80,
                key: 'id',
                className: 'text-center',
                render: (id, data) => (
                    <center>
                    <Button type="default" onClick={(() => showDrawer(data))}>
                        <Icon type="eye" />
                    </Button>
                    </center>
                ),
                fixed: 'right',
            }
        ];
    }
}