import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const ErrorContainer = styled.div`
    color: red;
    margin: 20px 0;
    text-align: center;
    font-size: 16px;
    animation: ${fadeIn} 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const RetryButton = styled.button`
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    display: block;
    background-color: #7f00ff;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: #7f00ff;
    }

    &:focus {
        outline: none;
        background-color: #7f00ff;
        box-shadow: 0 0 0 3px rgba(127, 0, 255, 0.5);
    }

    &:active {
        background-color: #7f00ff;
    }
`;

const ErrorMessage = ({ message, onRetry }) => {
    return (
        <ErrorContainer>
            {message}
            <RetryButton onClick={onRetry}>Tentar novamente</RetryButton>
        </ErrorContainer>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
    onRetry: PropTypes.func.isRequired,
};

export default ErrorMessage;
