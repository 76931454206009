import { createAsyncActions } from '~/utils/store';
import { request } from '~/components/Instance';

export const LOAD_CALENDAR_TASKS_MONTH = createAsyncActions("LOAD_CALENDAR_TASKS_MONTH");
export const CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST = createAsyncActions("CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST");
export const CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID = createAsyncActions("CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID");

const createRequestPayload = (method, url, data = null, showLoading = true) => ({
  request: {
    method,
    url,
    ...(data && { data }),
    ...(showLoading === false && { showLoading }),
  },
});

export const saveTasksInBatch = (taskId, data) => ({
  type: "SAVE_TASKS_IN_BATCH",
  payload: createRequestPayload("POST", `/calendar/task/${taskId}/files/batch`, data),
});

export const loadAllDepartmentsCalendarTasks = () => ({
  type: "LOAD_ALL_DEPARTMENTS_CALENDAR_TASKS",
  payload: createRequestPayload("get", `/calendar/tasks`, null, false),
});

export const clearAllDepartmentsCalendarState = () => ({
  type: "CLEAR_ALL_DEPARTMENTS_CALENDAR_STATE",
});

export const updateTasksCardsFromList = ({ customers, taskId }) => ({
  type: "CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST",
  payload: {
    customers,
    taskId,
  },
});

export const updateContextTasksCardsFromList = (customers, taskId) => ({
  type: "CONTEXT_CALENDAR_CARDS_TASKS_UPDATE_FROM_LIST",
  payload: {
    customers,
    taskId,
  },
});

export const loadCalendarTasks = (id, date) => ({
  type: LOAD_CALENDAR_TASKS_MONTH.INIT,
  payload: createRequestPayload("get", `/calendar/tasks/department/${id}/${date.endOf("month").format("YYYY-MM-DD")}`),
  id,
  date,
});

export const sendEmailWithLink = ({ taskRecordId }) => ({
  type: "SEND_EMAIL_WITH_LINK",
  payload: createRequestPayload("POST", `/calendar/task_record/${taskRecordId}/email/link`),
});

export const sendEmailWithAttachment = ({ taskRecordId }) => ({
  type: "SEND_EMAIL_WITH_ATTACHMENT",
  payload: createRequestPayload("POST", `/calendar/task_record/${taskRecordId}/email/attachment`),
});

export const generateAccessToFiles = ({ taskRecordId }) => ({
  type: "GENERATE_ACCESS_TO_FILES",
  payload: createRequestPayload("GET", `/calendar/task_record/${taskRecordId}/files/access`),
});

export const LOAD_TASK_LIST = createAsyncActions("LOAD_TASK_LIST");

export const loadTaskList = (taskId, date) => {
  const response = request("get", `/calendar/task/${taskId}/list/${date.format('YYYY-MM-DD')}`, null, false);
  return response.then(({ data }) => data);
};

export const UPDATE_TASK_CUSTOMERS = createAsyncActions("UPDATE_TASK_CUSTOMERS");

export const updateTaskCustomers = (departmentId, date, taskId, customers) => ({
  type: UPDATE_TASK_CUSTOMERS.SUCCESS,
  payload: {
    departmentId,
    date,
    taskId,
    customers
  }
});

export const UPDATE_TASK_STATS = createAsyncActions("UPDATE_TASK_STATS");

export const updateTaskStats = (departmentId, date, taskId, stats) => ({
  type: UPDATE_TASK_STATS.SUCCESS,
  payload: {
    departmentId,
    date,
    taskId,
    stats
  }
});
