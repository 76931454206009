export const cadenceOptions = {
    0: 'Selecione',
    1: 'Pesquisar',
    2: 'Email',
    3: 'Ligar',
    4: 'WhatsApp',
    5: 'Instagram',
};

export const cadenceOptionsIcons = {
    0: 'add',
    1: 'search',
    2: 'mail',
    3: 'phone',
    4: 'message',
    5: 'instagram',
}

export const statusOptions = {
    'pending': 'Pendente',
    'done': 'Concluído',
    'canceled': 'Sem sucesso',
}