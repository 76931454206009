export const tableColumns = [
  {
    title: "Nome",
    key: "name",
    dataIndex: "name",
    width: 300,
  },
  {
    title: "Periodicidade",
    key: "frequency",
    dataIndex: "frequency",
  },
  {
    title: "Clientes",
    key: "tasks_count",
    dataIndex: "tasks_count",
  },
  {
    title: "Concluídos / Pendentes",
    key: "executed_and_pendent_tasks",
    renderType: "custom_progress",
  },
  {
    title: "Vencimentos",
    key: "due_day",
    dataIndex: "due_day",
  },
  {
    title: "Percentual de conclusão",
    key: "executed_tasks_percentage",
    dataIndex: "executed_tasks_percentage",
    width: 200,
    renderType: "progress",
  },
  {
    key: "actions",
    width: 100,
    renderType: "actions",
  },
];

// You can add helper functions related to table columns here
export const getColumnWidth = (key) => {
  const column = tableColumns.find(col => col.key === key);
  return column && column.width ? column.width : 'auto';
};

export default tableColumns;
