import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputMask from "~/components/UI/InputMask";
import * as whatsappByDepartmentActions from "./actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const inputMaskProps = {
  mask: "(99) 9999tt999?",
  formatChars: { "9": "[0-9]", t: "[0-9-]", "?": "[0-9 ]" },
  maskChar: null,
  placeholder: "Digite o número de whatsapp",
};

export const WhatsappByDepartment = Form.create()(({ form }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [isSaving, setIsSaving] = useState(false);

  const { getFieldDecorator, validateFields, setFieldsValue } = form;

  useEffect(() => {
    if (!user) return;

    const { tenant } = user;

    setFieldsValue({
      legalization: tenant.legalization_phone,
      administrative: tenant.administrative_phone,
      accounting: tenant.accounting_phone,
      fiscal: tenant.fiscal_phone,
      personal: tenant.personal_phone,
      audit: tenant.audit_phone,
    });
  }, [user]);

  function handleSubmit() {
    validateFields(async (error, values) => {
      if (error) return;

      setIsSaving(true);

      try {
        await savePhonesByDepartmentRequest(values);
      } catch (error) {
        console.log(error);
      } finally {
        setIsSaving(false);
      }
    });
  }

  async function savePhonesByDepartmentRequest(params) {
    await dispatch(whatsappByDepartmentActions.savePhonesByDepartment(params));
  }

  return (
    <Form layout="horizontal">
      <Form.Item {...formItemLayout} label="Legalização">
        {getFieldDecorator("legalization")(<InputMask {...inputMaskProps} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Administrativo">
        {getFieldDecorator("administrative")(<InputMask {...inputMaskProps} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Contábil">
        {getFieldDecorator("accounting")(<InputMask {...inputMaskProps} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Fiscal">
        {getFieldDecorator("fiscal")(<InputMask {...inputMaskProps} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Pessoal">
        {getFieldDecorator("personal")(<InputMask {...inputMaskProps} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Sucesso do Cliente (CS)">
        {getFieldDecorator("audit")(<InputMask {...inputMaskProps} />)}
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 4,
          },
        }}
      >
        <Button type="primary" onClick={handleSubmit} loading={isSaving}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
});
