import { Card, Spin } from "antd";
import { find, get } from "lodash";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LeadCard } from "../LeadCard";
import moment from "moment";
import {
  Column,
  ColumnContent,
  Container,
  ExtraContainer,
  ExtraTotalBadge,
  LoadingSkeleton,
  LoadingSkeletonContainer,
} from "../styles";

export const LeadColumns = ({
  columns,
  moveLead,
  date,
  loadMoreLeads,
  isLoading,
  showDrawer,
}) => {
  // const [hasMore, setHasMore] = useState(true);

  // const loadMoreData = async (column) => {
  //   if (column.data.length === column.total) {
  //     setHasMore(false);
  //     return;
  //   }
  //   await loadMoreLeads({
  //     columnId: column.id,
  //     currentCount: column.data.length,
  //     moreCount: 50,
  //   });
  // };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      await moveLead(
        get(find(columns, { id: parseInt(source.droppableId) }), "data", []),
        get(find(columns, { id: parseInt(destination.droppableId) }), "data", []),
        source,
        destination,
        parseInt(result.draggableId)
      );
    }
  };

  const activeCollumns = columns.filter((column) => get(column, 'hidden', false) === false);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        {activeCollumns.map((column) => (
          <Column key={column.id}>
            <Droppable droppableId={`${column.id}`}>
              {(provided) => (
                <Card
                  title={column.name}
                  bodyStyle={{ padding: 0, minWidth: 300 }}
                  headStyle={column.style}
                  extra={
                    <ExtraContainer>
                      <ExtraTotalBadge
                        count={column.data.length}
                        overflowCount={9999}
                      />
                    </ExtraContainer>
                  }
                >
                  <ColumnContent ref={provided.innerRef} style={{ overflowY: 'auto' }}>
                    {isLoading
                      ? Array(10)
                          .fill(1)
                          .map((_, index) => (
                            <LoadingSkeletonContainer key={index}>
                              <LoadingSkeleton />
                            </LoadingSkeletonContainer>
                          ))
                      : column.data
                          .sort((a, b) => {
                            if (column.id === 2) {
                              const taskA = get(a, 'additional_data.nextPendingTask');
                              const taskB = get(b, 'additional_data.nextPendingTask');
                              const dateA = taskA && moment(taskA.date, 'DD/MM/YYYY').isValid() ? moment(taskA.date, 'DD/MM/YYYY').valueOf() : null;
                              const dateB = taskB && moment(taskB.date, 'DD/MM/YYYY').isValid() ? moment(taskB.date, 'DD/MM/YYYY').valueOf() : null;
                              return dateA - dateB;
                            }
                            return 0;
                          })
                          .map((item, index) => (
                            <LeadCard
                              key={index}
                              draggableId={item.id}
                              index={index}
                              item={item}
                              showDrawer={showDrawer}
                              columnId={column.id}
                            />
                          ))}
                    {provided.placeholder}
                  </ColumnContent>
                </Card>
              )}
            </Droppable>
          </Column>
        ))}
      </DragDropContext>
    </Container>
  );
};
