import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { HelperText } from '../../styles';
import { getWhatsappFileMessage } from '~/_domains/calendarTasks/helpers';
import * as calendarTasksActions from '~/_domains/calendarTasks/actions';
import { deliveryMethodNames } from '~/containers/Customer/data';
import ContentCopy from '~/helpers/contentCopy'

export const WhatsappButton = ({
  props: {
    buttonsProps,
    buttonsState,
    buttonsLoadingState,
    hasNewFiles,
    checkIfHasButtonLoading,
    setButtonsLoadingState,
    currentCustomer,
    currentTaskRecordId,
    updateDeliveryMethods,
    setButtonsState,
    defaultCustomerDeliveryMethod,
  },
}) => {
  const dispatch = useDispatch();

  async function sendToWhatsapp() {
    try {
      setButtonsLoadingState((state) => ({
        ...state,
        whatsapp: true,
      }));
      const response = await dispatch(
        calendarTasksActions.generateAccessToFiles({
          taskRecordId: currentTaskRecordId,
        })
      );
      const { files } = response.payload.data;

      let whatsappMessage = '';

      if (files.length === 1) {
        const currentFile = files[0];

        whatsappMessage = `Prezado cliente, ${
          currentCustomer.name
        }.%0aEncontra-se disponível a obrigação ${
          currentFile.description
        } com os seguintes dados:${getWhatsappFileMessage(currentFile)}`;
      } else {
        whatsappMessage = `Prezado cliente, ${
          currentCustomer.name
        }.%0aEncontram-se disponíveis obrigações com os seguintes dados: ${files
          .map((file) => getWhatsappFileMessage(file))
          .join('')}`;
      }

      const whatsappUrl = `https://api.whatsapp.com/send?phone=55${currentCustomer.phone}&text=${whatsappMessage}`;

      updateDeliveryMethods(3);
      setButtonsState((state) => ({
        ...state,
        isWhatsappButtonClicked: true,
      }));

      Modal.info({
        title: `Copie a mensagem ou clique em enviar`,
        content: <ContentCopy content={whatsappMessage}/>,
        okText: <span><AiOutlineWhatsApp/> Enviar</span>,
        okType: 'primary',
        cancelText: 'Fechar',
        okCancel: true,
        maskClosable: true,
        closable: true,
        icon: null,
        onOk: () => window.open(whatsappUrl, '_blank').focus(),
      });

    } catch (error) {
      console.log(error);
    } finally {
      setButtonsLoadingState((state) => ({
        ...state,
        whatsapp: false,
      }));
    }
  }

  function handleSendToWhatsapp() {
    if (checkIfHasButtonLoading()) return;

    if (!buttonsProps.whatsapp.isDefaultMethod) {
      return Modal.confirm({
        title: `
          O método padrão de envio de documentos escolhido por este cliente é ${deliveryMethodNames[defaultCustomerDeliveryMethod]}. 
          Tem certeza que deseja continuar com o envio via ${deliveryMethodNames[3]}?
        `,
        content: 'Esta ação não poderá ser desfeita.',
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Cancelar',
        onOk: sendToWhatsapp,
      });
    }

    sendToWhatsapp();
  }

  return (
    <Tooltip title={buttonsProps.whatsapp.tooltipTitle}>
      <Button
        onClick={handleSendToWhatsapp}
        loading={buttonsLoadingState.whatsapp}
        disabled={hasNewFiles}
        type={buttonsProps.whatsapp.isDefaultMethod ? 'primary' : 'default'}
      >
        <AiOutlineWhatsApp />
        Whatsapp
      </Button>
      {buttonsState.isWhatsappButtonClicked && (
        <HelperText>Mensagem já enviada</HelperText>
      )}
    </Tooltip>
  );
};
