import { createAsyncActions } from '~/utils/store';

export const SAVE = createAsyncActions('@@CUSTOMERS-TASKS/SAVE');
export const FETCH = createAsyncActions('@@CUSTOMERS-TASKS/FETCH');

export const save = (data) => ({
    type: SAVE.INIT,
    payload: {
        request: {
            method: "POST",
            url: "/customers/tasks",
            data,
        },
    },
});

export const fetch = ({ ...params }) => ({
    type: FETCH.INIT,
    payload: {
        request: {
            method: "GET",
            url: "/customers/tasks",
            ...params,
        },
    },
});