import { Button, Dropdown, Icon, Menu, Table, Tag, Badge } from "antd";
import moment from "moment";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import * as documentsActions from "./actions";
import AdvancedFilter from "./AdvancedFilter";
import {
  ActiveRadioButton,
  ArchivedRadioButton,
  Header,
  PendingRadioButton,
  RadioButtons,
  SearchInput,
  CardContainer,
  Card,
  CardTitle,
  CardCount,
  CardIcon
} from './styles';
import { ArchiveDocumentModal } from "./Modals/ArchiveDocumentModal";
import { EmailLogsModal } from "./Modals/EmailLogsModal";
import { convertToBRL } from "~/utils/formatters";
import { ModuleHeader } from '~/components/ModuleHeader';
import { SporadictDocumentUpload } from '~/_domains/calendarTasks/Drawers/SporadicDocumentUpload';
import { SporadicDocumentUploadInBatch } from '~/_domains/calendarTasks/Drawers/SporadicDocumentUploadInBatch';
import { PendingDocumentsDrawer } from './components/PendingDocumentsDrawer';
import { useAppContext } from "~/AppContext";
import DocumentsTable from './table';
import { FaCheck, FaTimes, FaExclamationTriangle } from 'react-icons/fa';
import styled from 'styled-components';

export const getStatusTag = (file) => {
  let selectedStatus;
  let icon;

  if (moment(file.due_at).isBefore(moment(), 'dates') && !file.viewed_at) {
    selectedStatus = { name: "Vencido", color: "#ff0a02" };
  } else if (file.viewed_at) {
    selectedStatus = { name: "Baixado", color: "#03b14e" };
  } else {
    selectedStatus = { name: "Não Baixado", color: "#0071c0" };
  }

  if (file.status === "Pago") {
    icon = <Icon type="check" />;
  } else if (file.viewed_at) {
    icon = <Icon type="download" />;
  }

  return (
    <Tag color={selectedStatus.color} style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
      {icon || null}
      {selectedStatus.name}
    </Tag>
  );
};

const CustomBadge = styled(Badge)`
  .ant-badge-count {
    background-color: black;
    color: white;
  }
`;

const Documents = (props) => {
  const [filters, setFilters] = useState({ search: "" });
  const [impersonateAccessLoading, setImpersonateAccessLoading] = useState(null);
  const [cardFilter, setCardFilter] = useState('all');
  const [archiveModal, setArchiveModal] = useState({ isOpen: false, id: null });
  const [emailLogsModal, setEmailLogsModal] = useState({ isOpen: false, id: null });
  const [documentDrawer, setDocumentDrawer] = useState({ isOpen: false });
  const [documentsInBatchDrawer, setDocumentsInBatchDrawer] = useState({ isOpen: false });
  const [pendingDocumentsDrawer, setPendingDocumentsDrawer] = useState({ isOpen: false });
  const [activeCard, setActiveCard] = useState(null);
  const { setOnboardingTaskAsDone } = useAppContext();

  const impersonateAccess = useCallback((file) => {
    setImpersonateAccessLoading(file.id);
    const data = {
      customer_id: file.customer.id,
    };

    props.impersonateAccess(file.id, data)
      .then((response) => {
        const apiToken = response.payload.data.data.api_token;

        const customerAppLink = `http://${
          process.env.REACT_APP_CUSTOMER_DOMAIN
        }/?file=${file.id}&token=${apiToken}`;

        const newWindow = window.open(customerAppLink, '_blank');
        if (newWindow) {
          newWindow.focus();
        }
        setOnboardingTaskAsDone('y5z6a7');
      })
      .finally(() => {
        setImpersonateAccessLoading(null);
      })
  }, [props, setOnboardingTaskAsDone]);

  const generateAccessToFile = useCallback(async (file) => {
    try {
      const response = await props.generateAccessToFile(file.id);
      const { file_url: url } = response.payload.data;

      const whatsappMessage = `Prezado cliente, ${
        file.customer.name
      }.%0aEncontra-se disponível a obrigação ${
        file.description
      } com os seguintes dados:%0a%0aDescrição: ${
        file.description
      }%0aApuração / Data de competência: ${moment(file.competency_at).format(
        'MM/YYYY'
      )}%0aVencimento: ${moment(file.due_at).format(
        'DD/MM/YYYY'
      )}%0aValor: ${convertToBRL(file.amount)}%0aLink: ${url}`;

      const whatsappUrl = `https://api.whatsapp.com/send?phone=55${file.customer.phone}&text=${whatsappMessage}`;

      window.open(whatsappUrl, '_blank').focus();

    } catch (error) {
      console.log(error);
    }
  }, [props]);

  const getRevisedTitle = useCallback((file) => {
    const { revised_at, revised_by } = file;
    if (revised_at && revised_by) {
      const user = props.getUser(revised_by);

      if (!user) {
        return `Revisado as ${moment(revised_at).format('DD/MM/YYYY H:mm:ss')}`;
      }

      return `Revisado por ${user.name} - ${moment(revised_at).format('DD/MM/YYYY H:mm:ss')}`;
    }
    return 'Pendente';
  }, [props]);

  const openArchiveModal = useCallback((id) => {
    setArchiveModal({ isOpen: true, id });
  }, []);

  const closeArchiveModal = useCallback(() => {
    setArchiveModal({ isOpen: false, id: null });
  }, []);

  const openEmailLogsModal = useCallback((id) => {
    setEmailLogsModal({ isOpen: true, id });
  }, []);

  const closeEmailLogsModal = useCallback(() => {
    setEmailLogsModal({ isOpen: false, id: null });
  }, []);

  const openDocumentDrawer = useCallback(() => {
    setDocumentDrawer({ isOpen: true });
  }, []);

  const closeDocumentDrawer = useCallback(() => {
    setDocumentDrawer({ isOpen: false });
  }, []);

  const openDocumentsInBatchDrawer = useCallback(() => {
    setDocumentsInBatchDrawer({ isOpen: true });
  }, []);

  const closeDocumentsInBatchDrawer = useCallback(() => {
    setDocumentsInBatchDrawer({ isOpen: false });
  }, []);

  const openPendingDocumentsDrawer = useCallback(() => {
    setPendingDocumentsDrawer({ isOpen: true });
  }, []);

  const closePendingDocumentsDrawer = useCallback(() => {
    setPendingDocumentsDrawer({ isOpen: false });
  }, []);

  const { documents, isLoading } = props;
  const { search: searchFilter } = filters;

  const filteredDocuments = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return [];
    return documents.filter((document) => {
      const customerName = document.customer.name.toLowerCase();
      const customerCod = document.customer.cod;

      if (
        customerName.includes(searchFilter) ||
        customerCod.includes(searchFilter)
      ) {
        switch (cardFilter) {
          case 'pending': {
            return document.delivery_methods.length === 0 && !document.archived_at && !document.archived_by;
          }
          case 'archived': {
            return !!document.archived_at && !!document.archived_by;
          }
          case 'overdue': {
            return moment(document.due_at).isBefore(moment(), 'dates') && !document.viewed_at && !document.archived_at && !document.archived_by;
          }
          case 'notDownloaded': {
            return !document.viewed_at && !moment(document.due_at).isBefore(moment(), 'dates') && !document.archived_at && !document.archived_by;
          }
          case 'downloaded': {
            return !!document.viewed_at && !document.archived_at && !document.archived_by;
          }
          default: {
            return !document.archived_at && !document.archived_by;
          }
        }
      }

      return false;
    });
  }, [documents, searchFilter, cardFilter]);

  const uniqueTaskNames = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return [];
    return [
      ...new Set(
        documents
          .filter((document) => !!document.task_name)
          .map((document) => document.task_name))
    ];
  }, [documents]);

  const downloadedCount = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return 0;
    return documents.filter((doc) => doc.viewed_at && !doc.archived_at && !doc.archived_by).length;
  }, [documents]);

  const notDownloadedCount = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return 0;
    return documents.filter((doc) => !doc.viewed_at && !moment(doc.due_at).isBefore(moment(), 'dates') && !doc.archived_at && !doc.archived_by).length;
  }, [documents]);

  const overdueCount = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return 0;
    return documents.filter((doc) => moment(doc.due_at).isBefore(moment(), 'dates') && !doc.viewed_at && !doc.archived_at && !doc.archived_by).length;
  }, [documents]);

  const pendingCount = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return 0;
    return documents.filter((doc) => doc.delivery_methods.length === 0 && !doc.archived_at && !doc.archived_by).length;
  }, [documents]);

  const archivedCount = useMemo(() => {
    if (!documents || !Array.isArray(documents)) return 0;
    return documents.filter((doc) => !!doc.archived_at && !!doc.archived_by).length;
  }, [documents]);

  const handleCardClick = useCallback((type) => {
    setActiveCard((prevType) => (prevType === type ? null : type));
    setFilters({ search: "" });
    setCardFilter((prevType) => (prevType === type ? 'all' : type));
  }, []);

  return (
    <div>
      <Header>
        <ModuleHeader 
          breadcrumbs={['Itens Enviados','Documentos Enviados']} 
          search={
            <>
              <SearchInput
                placeholder="Pesquisar pelo nome ou codigo do cliente"
                onChange={(e) =>
                  setFilters({
                    search: e.target.value.toLowerCase(),
                  })
                }
                style={{ width: 300 }}
              />
              <CustomBadge count={archivedCount}>
                <ArchivedRadioButton
                  isActive={cardFilter === 'archived'}
                  value="archived"
                  onClick={() => setCardFilter(cardFilter === 'archived' ? 'all' : 'archived')}
                >
                  Arquivados
                </ArchivedRadioButton>
              </CustomBadge>
            </>
          }
          actions={
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={openDocumentDrawer}>
                    Individual
                  </Menu.Item>
                  <Menu.Item key="2" onClick={openDocumentsInBatchDrawer}>
                    Em lote
                  </Menu.Item>
                  <Menu.Item key="3" onClick={openPendingDocumentsDrawer}>
                    Pendentes
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary">Adicionar documento</Button>
            </Dropdown>
          }
          advanced={<AdvancedFilter />}
        />
      </Header>
      {cardFilter !== 'archived' && (
      <CardContainer>
        <Card 
          background="#fff3cd" 
          onClick={() => handleCardClick('pending')}
          isActive={activeCard === 'pending'}
        >
          <CardIcon>
            <FaExclamationTriangle size={24} color="#ffc107" />
          </CardIcon>
          <CardTitle>Envio Pendente</CardTitle>
          <CardCount>{pendingCount}</CardCount>
        </Card>
        <Card 
          background="#ffe6e6" 
          onClick={() => handleCardClick('overdue')}
          isActive={activeCard === 'overdue'}
        >
          <CardIcon>
            <FaExclamationTriangle size={24} color="#ff0a02" />
          </CardIcon>
          <CardTitle>Vencidos</CardTitle>
          <CardCount>{overdueCount}</CardCount>
        </Card>
        <Card 
          background="#e6f0ff" 
          onClick={() => handleCardClick('notDownloaded')}
          isActive={activeCard === 'notDownloaded'}
        >
          <CardIcon>
            <FaTimes size={24} color="#0071c0" />
          </CardIcon>
          <CardTitle>Não Baixados</CardTitle>
          <CardCount>{notDownloadedCount}</CardCount>
        </Card>
        <Card 
          background="#e6f7e6" 
          onClick={() => handleCardClick('downloaded')}
          isActive={activeCard === 'downloaded'}
        >
          <CardIcon>
            <FaCheck size={24} color="#03b14e" />
          </CardIcon>
          <CardTitle>Baixados</CardTitle>
          <CardCount>{downloadedCount}</CardCount>
        </Card>
      </CardContainer>
      )}
      <DocumentsTable
        documents={filteredDocuments}
        isLoading={isLoading}
        impersonateAccess={impersonateAccess}
        openArchiveModal={openArchiveModal}
        openEmailLogsModal={openEmailLogsModal}
        generateAccessToFile={generateAccessToFile}
        getRevisedTitle={getRevisedTitle}
        visualizationType={cardFilter}
        uniqueTaskNames={uniqueTaskNames}
        setFilters={setFilters}
        setVisualizationType={setCardFilter}
        filters={filters}
        props={props}
        impersonateAccessLoading={impersonateAccessLoading}
      />
      <SporadictDocumentUpload
        isOpen={documentDrawer.isOpen}
        onClose={closeDocumentDrawer}
      />
      <SporadicDocumentUploadInBatch
        isVisible={documentsInBatchDrawer.isOpen}
        onClose={closeDocumentsInBatchDrawer}
      />
      <PendingDocumentsDrawer
        isVisible={pendingDocumentsDrawer.isOpen}
        onClose={closePendingDocumentsDrawer}
      />
      <ArchiveDocumentModal
        isOpen={archiveModal.isOpen}
        id={archiveModal.id}
        closeModal={closeArchiveModal}
        archiveDocument={props.archiveDocument}
      />
      <EmailLogsModal
        isOpen={emailLogsModal.isOpen}
        documentId={emailLogsModal.id}
        closeModal={closeEmailLogsModal}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents.list,
    isLoading: state.documents.isLoading,
    user: state.user,
    getUser: (id) => state.users.find((user) => user.id === id)
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    impersonateAccess: (id, data) => dispatch(documentsActions.impersonateAccess(id, data)),
    changeRevised: (id, data) => dispatch(documentsActions.changeRevised(id, data)),
    generateAccessToFile: (id) => dispatch(documentsActions.generateAccessToFile(id)),
    archiveDocument: (id, data) => dispatch(documentsActions.archiveDocument(id, data)),
    sendEmails: (id) => dispatch(documentsActions.sendEmails(id))
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Documents);
