import React from 'react';
import { Menu, Icon, Badge, Tooltip } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useAppContext } from '~/AppContext';

import { getMenuItems } from './menuItems';

const { SubMenu } = Menu;

const MainMenu = ({ location, isCollapsed }) => {
  const {
    user,
    links,
    linkCategories,
    failedEmailsCount,
    solicitationsCount
  } = useSelector((state) => ({
    user: state.user,
    links: state.links.list,
    linkCategories: state.links.categories,
    failedEmailsCount: state.emailsLogs.failed,
    solicitationsCount: state.solicitations.data.filter(solicitation => solicitation.status === 0).length,
  }));

  const { unlockedMenus } = useAppContext();

  const getDefaultOpenKeys = () => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length > 1) {
      return [pathSegments[1]];
    }
    return [];
  };

  const menuItems = getMenuItems(user, links, linkCategories, failedEmailsCount, solicitationsCount, unlockedMenus);

  // if current page is = "/" and its not on the menu, redirect to the first menu item
  const dashboardAvailable = menuItems.find(item => item.link === '/');
  if (location.pathname === '/' && !dashboardAvailable && menuItems.length > 0) {
    return <Redirect to={menuItems[0].link} />;
  }

  const renderMenuItems = (menuItems) => {
    return menuItems
      .sort((a, b) => a.disabled - b.disabled)
      .map((item) => {
        if (item.subMenu) {
          return (
            <SubMenu
              key={item.key}
              title={
                <span>
                  {item.iconImg ? item.iconImg : <Icon type={item.icon} style={{ color: item.disabled ? '#333' : '#fff' }} />}
                  <span style={{ color: item.disabled ? '#333' : '#fff' }}>{item.title}</span>
                  {!isCollapsed && item.component}
                  {item.badge !== undefined && item.badge > 0 &&
                    <Link to={item.badgeLink}>
                      <Badge count={item.badge} offset={[10, 0]} style={{ zoom: 0.8, marginLeft: '10px'}} />
                    </Link>
                  }
                </span>
              }
            >
              {renderMenuItems(item.subMenu)}
            </SubMenu>
          );
        }

        if(!item.link){
          console.log('Item sem link:', item);
          return null;
        }

        return (
          <Menu.Item key={item.key} disabled={item.disabled}>
            <Tooltip title={item.disabled ? "Para acessar este menu, você precisa concluir o onboarding." : ""}>
              <Link to={item.link} style={{ color: item.disabled ? '#333' : '#fff' }}>
                {item.iconImg ? item.iconImg : <Icon type={item.icon} style={{ color: item.disabled ? '#333' : '#fff' }} />}
                <span>{item.title}</span>
                {!isCollapsed && item.component}
                {item.badge !== undefined && item.badge > 0 &&
                  <Link to={item.badgeLink}>
                    <Badge count={item.badge} offset={[10, 0]} style={{ zoom: 0.8, marginLeft: '10px'}} />
                  </Link>
                }
              </Link>
            </Tooltip>
          </Menu.Item>
        );
      });
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
      defaultOpenKeys={getDefaultOpenKeys()}
    >
      {renderMenuItems(menuItems)}
    </Menu>
  );
};

export default MainMenu;
