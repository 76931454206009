import React, { useState, useContext } from 'react';
import { Card, Progress, Divider, Row, Col, Icon, Tag, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { FaRegSmile } from 'react-icons/fa';

import { Container, Header, Main } from '~/_shared/components/Layout';
import { ModuleHeader } from '~/_shared/components/ModuleHeader';
import { VideoModal } from './VideoModal';
import { Layout } from "~/components/UI";
import { useAppContext } from '~/AppContext';

const Onboarding = () => {
    const [video, setVideo] = useState(null);
    const { onboardingData, setOnboardingTaskAsDone, setAllOnboardingTasksAsDone, resetOnboardingTasks } = useAppContext();
    const history = useHistory();

    const done = onboardingData.filter(item => item.status === 'done').reverse();
    const pending = onboardingData.filter(item => item.status === 'pending');
    const progress = Math.round((done.length / onboardingData.length) * 100);

    const handleStart = (item) => {
        if (item.url) {
            setVideo(item);
        } else if (item.route) {
            history.push(item.route);
        }
    };

    const handleMarkAsDone = (item) => {
        setOnboardingTaskAsDone(item.id);
    };

    const handleMarkAllAsDone = () => {
        setAllOnboardingTasksAsDone();
    };

    const handleMarkAllAsPending = () => {
        resetOnboardingTasks();
    };

    const renderCard = (item, index, isPending) => (
        <Col span={12} key={index} style={{ marginBottom: '10px' }}>
            <Card 
                headStyle={{ borderBottom: 'none', background: isPending ? 'none' : 'rgb(180 255 190 / 6%)' }}
                bodyStyle={{ padding: '0px 25px 15px 25px', background: isPending ? 'none' : 'rgb(180 255 190 / 6%)' }}
                title={
                    <div style={{ fontSize: '16px', textAlign: 'left', maxWidth: '250px' }}>
                        <Icon type={isPending ? item.icon : 'check-circle'} style={{ color: isPending ? 'inherit' : 'green', marginRight: '8px' }} /> 
                        <span style={{ marginRight: '8px' }}>{item.title}</span>
                        <Tag color="purple">{item.time}</Tag>
                    </div>
                }
                style={{ opacity: isPending && index !== 0 ? 0.6 : 1 }}
            >
                {item.description}
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                    <Button 
                        type={isPending ? 'primary' : 'default'} 
                        style={{ 
                            backgroundColor: isPending && index === 0 ? '#722fd1' : 'inherit', 
                            borderColor: isPending && index === 0 ? '#722fd1' : 'inherit' 
                        }} 
                        disabled={isPending && index !== 0 || !isPending && item.btnBlocked} 
                        onClick={() => isPending && handleStart(item)}
                    >
                        <Icon type={isPending ? 'play-circle' : 'eye'} /> 
                        {isPending ? 'Iniciar' : 'Acessar'}
                    </Button>
                    {item.clicked && isPending && index === 0 && (
                        <Button 
                            type="default" 
                            onClick={() => handleMarkAsDone(item)}
                        >
                            <Icon type="check" /> Marcar como concluído
                        </Button>
                    )}
                </div>
            </Card>
        </Col>
    );

    return (
        <Layout>
            <Container>
                <Header>
                    <ModuleHeader 
                        breadcrumbs={['Início']} 
                        title={
                            <span style={{lineHeight: '1'}}>
                                Comece agora 
                                <small style={{fontSize: '13px', display: 'block'}}>
                                    Cada etapa desbloqueia acesso a novos recursos
                                </small>
                            </span>
                        }
                        actions={<Progress percent={progress} showInfo={false} status="success" strokeWidth={15} strokeColor="#722fd1" />}
                    />
                </Header>
                <Main>
                    {pending.length > 0 ? (
                        <Row gutter={16} type='flex' justify='space-between'>
                            {pending.map((item, index) => renderCard(item, index, true))}
                        </Row>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '20px', padding: '20px', background: 'white', border: '1px solid #e8e8e8', borderRadius: '5px' }}>
                            <FaRegSmile size={50} style={{ color: '#722fd1' }} />
                            <h2 style={{ color: 'green' }}>Parabéns, você completou todas as tarefas!</h2>
                            <p style={{ fontSize: '14px' }}>
                                Se tiver alguma dúvida no futuro, pode retornar a esta página para assistir os videos tutoriais novamente, ou contacte nosso time de suporte pelo whatsapp.
                            </p>
                        </div>
                    )}
                    {done.length > 0 && (
                        <>
                            <Divider>Concluído</Divider>
                            <Row gutter={16} type='flex' justify='space-between'>
                                {done.map((item, index) => renderCard(item, index, false))}
                            </Row>
                            <Button 
                                type="link" 
                                style={{ marginTop: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto', color: '#722fd1' }}
                                onClick={handleMarkAllAsPending}
                            >
                                Marcar todos como pendente
                            </Button>
                        </>
                    )}
                    {pending.length > 0 && (
                        <Button 
                            type="link" 
                            style={{ marginTop: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto', color: '#722fd1' }}
                            onClick={handleMarkAllAsDone}
                        >
                            Marcar pendentes como concluído
                        </Button>
                    )}
                </Main>
            </Container>
            <VideoModal 
                onClose={() => setVideo(null)}
                isVisible={!!video}
                {...video}
            />
        </Layout>
    );
};

const App = () => (
    <Onboarding />
);

export default App;