import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import { systemIndex } from './systemIndex';

export const generateTemplate = () => {
    const headers = Object.keys(systemIndex);
    const data = [headers.map(key => systemIndex[key].label)];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clientes");

    XLSX.writeFile(workbook, "importacao_de_clientes_modelo.xlsx");
};
