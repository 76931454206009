/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Icon, Menu, Popover, Progress, Table } from "antd";
import moment from "moment";
import React from "react";

import { frequency } from "~/components/RegisterTasks/Utils";
import { CustomProgress, CustomProgressWrapper } from "./styles";
import tableColumns from "./tableColumns";

const { Column } = Table;

export const TasksInList = ({
  filteredTasks,
  openList,
  openGrid,
  isListOpen,
  openTasksInBatchDrawer,
}) => {
  const getRowClassName = (record) => {
    const isExecuted = record.pendent_tasks_count === 0;
    const from = moment(record.due_date);
    const to = moment();

    if (isExecuted) return "row-green";
    if (to.diff(from, "days") > 0) return "row-red";
    return "row-orange";
  };

  const formattedTasks = filteredTasks.map((task) => {
    const isPendingZero = task.pendent_tasks_count === 0;
    
    return {
      ...task,
      due_day: task.due_date.substring(8),
      frequency: frequency(task.frequency),
      executed_tasks_percentage: isPendingZero 
        ? 100 
        : (task.tasks_count > 0
            ? Math.round((100 * task.executed_tasks_count) / task.tasks_count, 2)
            : 0),
      is_expired: moment().diff(task.due_date, "days") > 0,
      is_completed: isPendingZero
    };
  });

  const renderColumnContent = (column, record) => {
    if (column.renderType === "custom_progress") {
      const { executed_tasks_count, executed_tasks_percentage, pendent_tasks_count, is_expired, is_completed } = record;
      return (
        <CustomProgressWrapper>
          <Popover content={`${executed_tasks_count} concluídos`}>
            <CustomProgress 
              percent={executed_tasks_percentage} 
              expired={is_completed ? "false" : is_expired.toString()} 
              status={is_completed ? "success" : undefined}
            />
          </Popover>
          <Popover content={`${pendent_tasks_count} pendentes`}>
            <span>{pendent_tasks_count}</span>
          </Popover>
        </CustomProgressWrapper>
      );
    }
    
    if (column.renderType === "progress") {
      const { executed_tasks_percentage, is_completed } = record;
      return (
        <Progress 
          percent={executed_tasks_percentage} 
          format={(percent) => `${percent}%`}
          status={is_completed ? "success" : undefined}
        />
      );
    }
    
    if (column.renderType === "actions") {
      return (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => openList(record.id)}>
                <Icon type="bars" /> Visualizar tarefas em modo lista
              </Menu.Item>
              <Menu.Item onClick={() => openGrid(record.id)}>
                <Icon type="table" /> Visualizar tarefas em modo tabela
              </Menu.Item>
              {record.file_upload && (
                <Menu.Item onClick={() => openTasksInBatchDrawer({ parentTask: record })}>
                  <Icon type="upload" /> Executar tarefas em lote
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={["click"]}
        >
          <a className="ant-dropdown-link">
            Ações <Icon type="down" />
          </a>
        </Dropdown>
      );
    }
    
    return record[column.dataIndex];
  };

  return (
    <div className="table-clabs">
      <Table
        bordered
        rowKey="id"
        dataSource={formattedTasks}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: 1400 }}
        rowClassName={getRowClassName}
      >
        {tableColumns.map(column => (
          <Column 
            key={column.key}
            title={column.title}
            dataIndex={column.dataIndex}
            width={column.width}
            render={(_, record) => renderColumnContent(column, record)}
          />
        ))}
      </Table>
    </div>
  );
};
