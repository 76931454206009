import React from 'react';
import { Form, Icon } from 'antd';
import {
    Header,
    TextTitle,
    Title,
    Subtitle,
    Content,
    StyledInput,
    Footer,
    StyledButton,
} from '../styled';

const PasswordForm = (
    { form: { getFieldDecorator, validateFields }, signupData, setSignupData, setProgress }
) => {
    
    const handleSubmit = (e) => {
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                const updatedSignupData = { ...signupData, ...values };
                setSignupData(updatedSignupData);
                setProgress(100);
            }
        });
    };

    return (
        <>
            <Header>
                <TextTitle>
                    <Title>Defina sua senha</Title>
                    <Subtitle>Escolha uma senha segura para sua conta</Subtitle>
                </TextTitle>
            </Header>
            <Content style={{ width: '100%' }}>
                <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Por favor, insira a senha!' }],
                        })(
                            <StyledInput
                                type="password"
                                placeholder="Senha"
                                prefix={<Icon type="lock" />}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Content>
            <Footer>
                <StyledButton type="primary" onClick={handleSubmit}>
                    Continuar <Icon type="arrow-right" />
                </StyledButton>
            </Footer>
        </>
    )
};

export default Form.create({ name: 'onboarding' })(PasswordForm);
