import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Icon, Table, Menu, Dropdown, Tooltip } from 'antd';
import { compare } from 'natural-orderby';
import get from 'lodash/get';
import moment from 'moment';

import { request } from '~/components/Instance';

import './styles.css';

const { Column } = Table;
const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const getBackgroundColorCell = (record, index, customer) => {
  const recordDueDate = record.months[index] ? record.months[index].due_date : null;
  const respCompetAt = customer.resp_compet_at || customer.registered_at || customer.created_at;
  if (respCompetAt && moment(recordDueDate).isBefore(respCompetAt)) {
    return '#d9d9d9';
  }
  if (record.months[index]) {
    if (record.months[index].revised_at !== null) return '#52c41a';
    if (record.months[index].executed_at !== null) return '#b7eb8f';
    if (record.months[index].status === 'Vencida') return '#ff4d4f';
    return '#ffffb8';
  }
  return '#fafafa';
};

const DrawerForm = ({ data, date, visible, onClose }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const customersById = useSelector((state) => state.customersById);

  // const updateTasksCardsFromGrid = (customers, currentMonth, taskId) => {
  //   dispatch({
  //     type: 'CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID',
  //     payload: { customers, currentMonth, taskId },
  //   });
  // };

  // const updateContextTasksCardsFromGrid = (customers, currentMonth, taskId) => {
  //   dispatch({
  //     type: 'CONTEXT_CALENDAR_CARDS_TASKS_UPDATE_FROM_GRID',
  //     payload: { customers, currentMonth, taskId },
  //   });
  // };

  const loadTaskGrid = (task_id) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    request('get', `/calendar/task/${task_id}/grid/${formattedDate}`, {}, false).then((response) => {
      setLoading(false);
      const customerData = response.data.map((item) => ({
        ...item,
        customer: customersById[item.customer_id],
      }));
      setCustomers(customerData);
    });
  };

  const changeStatus = (record_task_id, status) => {
    request('patch', `/calendar/task_record/${record_task_id}/status/${status}`).then((response) => {
      setCustomers((prevCustomers) =>
        prevCustomers.map((row) =>
          row.customer_id !== response.data.data.customer_id
            ? row
            : {
                ...row,
                months: {
                  ...row.months,
                  [Object.keys(row.months).find((index) => row.months[index] && row.months[index].id === record_task_id)]: response.data.data,
                },
              }
        )
      );
    });
  };

  const createTaskRecordAndUpdateStatus = (data) => {
    request('post', '/calendar/task_record/create', data).then((response) => {
      setCustomers((prevCustomers) =>
        prevCustomers.map((currentCustomer) =>
          currentCustomer.customer_id !== response.data.data.customer_id
            ? currentCustomer
            : {
                ...currentCustomer,
                months: {
                  ...currentCustomer.months,
                  [Object.keys(currentCustomer.months).find(
                    (month) => currentCustomer.months[month] && currentCustomer.months[month].due_date === data.due_date.slice(0, 7)
                  )]: response.data.data,
                },
              }
        )
      );
    });
  };

  const changeStatusAll = (status, month) => {
    const [monthFormatted, year] = moment(date).format('MM-YYYY').split('-');
    request('patch', `/calendar/task/${data.id}/status`, { status, month, year }).then(() => {
      setLoading(true);
      loadTaskGrid(data.id);
    });
  };

  useEffect(() => {
    if (data) {
      loadTaskGrid(data.id);
    }
  }, [data, customersById]);

  const handleDrawerClose = () => {
    // const isCurrentDateEqualsSelectedDate = data.due_date.slice(0, 7) === moment().format('YYYY-MM');
    // if (isCurrentDateEqualsSelectedDate) {
    //   updateContextTasksCardsFromGrid(customers, date.month() + 1, data.id);
    // } else {
    //   updateTasksCardsFromGrid(customers, date.month() + 1, data.id);
    // }
    setLoading(true);
    setCustomers([]);
    onClose();
  };

  return (
    <div>
      {data && (
        <Drawer
          title={data.name}
          width="calc(100% - 100px)"
          placement="right"
          onClose={handleDrawerClose}
          maskClosable={false}
          visible={visible}
          style={{ height: '100%', overflow: 'auto', paddingBottom: 53 }}
        >
          <div className="table-clabs">
            <Table
              rowKey="customer_id"
              loading={loading}
              dataSource={customers}
              pagination={false}
              bordered
              rowClassName="row-clabs-grid"
              size="small"
              scroll={{ x: '1350px', y: `${window.innerHeight - 175}px` }}
            >
              <Column
                title="Cod"
                dataIndex="customer.cod"
                key="cod"
                width={90}
                sorter={(a, b) => compare()(a.customer.cod, b.customer.cod)}
                sortDirections={['descend', 'ascend']}
                defaultSortOrder="ascend"
              />
              <Column title="Cliente" dataIndex="customer.name" key="customer" width={300} ellipsis />
              {months.map((month, monthIndex) => (
                <Column
                  key={monthIndex + 1}
                  title={
                    <Dropdown
                      overlay={
                        <Menu onClick={(e) => changeStatusAll(e.key, monthIndex + 1)}>
                          <Menu.Item key="priority">
                            <Icon type="star" theme="twoTone" twoToneColor="#fadb14" /> Marcar todos como prioridade
                          </Menu.Item>
                          <Menu.Item key="uncheck_priority">
                            <Icon type="star" /> Remover prioridade de todos
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <span className="ant-dropdown-link">
                        {month} <Icon type="down" />
                      </span>
                    </Dropdown>
                  }
                  dataIndex={monthIndex + 1}
                  width={80}
                  className="grid-month"
                  render={(text, record) => {
                    const record_task = record.months[monthIndex + 1];
                    const customer = customersById[record.customer_id];
                    if (!record_task) return false;
                    const backgroundColor = getBackgroundColorCell(record, monthIndex + 1, customer);
                    const isDisabled = backgroundColor === '#d9d9d9';

                    if(isDisabled) {
                        return (
                          <Tooltip title="Periodo em que o escritório não é responsável">
                            <div
                              style={{
                              backgroundColor,
                              height: '100%',
                              width: '100%',
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              }}
                            />
                          </Tooltip>
                        );
                    }

                    return (
                      <Dropdown
                        overlay={
                          !isDisabled && (
                            <Menu
                              onClick={(e) => {
                                const recordDueDate = record_task.due_date;
                                const taskDueDay = data.due_date.split('-')[2];
                                if (!record_task.id) {
                                  createTaskRecordAndUpdateStatus({
                                    task_id: data.id,
                                    customer_id: record.customer_id,
                                    due_date: `${recordDueDate}-${taskDueDay}`,
                                    status: e.key,
                                  });
                                } else {
                                  changeStatus(record_task.id, e.key);
                                }
                              }}
                            >
                              {get(record_task, 'executed_at', null) === null && (
                                <Menu.Item key="executed">
                                  <Icon type="check" /> Marcar como executado
                                </Menu.Item>
                              )}
                              {record_task.executed_at !== null && !record_task.revised_at && (
                                <Menu.Item key="uncheck_executed">
                                  <Icon type="close" /> Desmarcar como executado
                                </Menu.Item>
                              )}
                              {!record_task.revised_at && (
                                <Menu.Item key="revised">
                                  <Icon type="check" /> Marcar como revisado
                                </Menu.Item>
                              )}
                              {record_task.revised_at !== null && (
                                <Menu.Item key="uncheck_revised">
                                  <Icon type="close" /> Desmarcar como revisado
                                </Menu.Item>
                              )}
                              <Menu.Divider />
                              {record_task.priority === 0 && (
                                <Menu.Item key="priority">
                                  <Icon type="star" theme="twoTone" twoToneColor="#fadb14" /> Marcar como prioridade
                                </Menu.Item>
                              )}
                              {record_task.priority === 1 && (
                                <Menu.Item key="uncheck_priority">
                                  <Icon type="star" /> Desmarcar como prioridade
                                </Menu.Item>
                              )}
                            </Menu>
                          )
                        }
                        trigger={['contextMenu', 'click']}
                      >
                        <div
                          style={{
                            userSelect: 'none',
                            backgroundColor,
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                        >
                          {record_task.priority === 1 && <Icon type="star" theme="twoTone" twoToneColor="#fadb14" />}
                        </div>
                      </Dropdown>
                    );
                  }}
                />
              ))}
            </Table>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default DrawerForm;
