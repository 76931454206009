import React from "react";
import { Button, DatePicker, Select, Form, Input, message } from "antd";
import moment from "moment";
import * as documentsActions from "./actions";
import { connect } from "react-redux";

const { MonthPicker } = DatePicker;
const { Option } = Select;

const AdvancedFilter = ({
  form: { getFieldDecorator, validateFields },
  loadDocumentsByFilter,
}) => {
  const handleFilter = (e) => {
    e.preventDefault();

    validateFields((err, { filter_by, from, to, min, max }) => {
      if (!from || !to) return;

      if (to.diff(from, "months") > 12) {
        return message.warning("O período não pode ser maior que 1 ano. Ex: 01/2020 - 01/2021");
      }

      loadDocumentsByFilter({
        filterBy: filter_by,
        from: from.startOf("month").format("YYYY-MM-DD"),
        to: to.endOf("month").format("YYYY-MM-DD"),
        min,
        max,
      });
    });
  };

  const renderFormItem = (label, name, initialValue, component) => (
    <Form.Item label={label}>
      {getFieldDecorator(name, { initialValue })(component)}
    </Form.Item>
  );

  const renderPeriodFormItem = () => (
    <Form.Item label="Período" style={{ marginBottom: 0 }}>
      <Form.Item style={{ marginRight: 0 }}>
        {getFieldDecorator("from", { initialValue: moment() })(
          <MonthPicker format={"MM/YYYY"} allowClear={false} />
        )}
      </Form.Item>
      <span style={{ display: "inline-block", width: "24px", textAlign: "center" }}>-</span>
      <Form.Item style={{ marginRight: 0 }}>
        {getFieldDecorator("to", { initialValue: moment() })(
          <MonthPicker format={"MM/YYYY"} allowClear={false} />
        )}
      </Form.Item>
    </Form.Item>
  );

  const renderValueFormItem = () => (
    <Form.Item label="Valor" style={{ marginBottom: 0 }}>
      <Form.Item style={{ marginRight: 0 }}>
        {getFieldDecorator("min", { initialValue: 0 })(<Input style={{ width: 100 }} />)}
      </Form.Item>
      <span style={{ display: "inline-block", width: "24px", textAlign: "center" }}>-</span>
      <Form.Item style={{ marginRight: 0 }}>
        {getFieldDecorator("max", { initialValue: 0 })(<Input style={{ width: 100 }} />)}
      </Form.Item>
    </Form.Item>
  );

  return (
    <Form
      layout="inline"
      className="ant-advanced-search-form-inline"
      onSubmit={handleFilter}
      style={{ width: "100%" }}
    >
      {renderFormItem("Filtrar por:", "filter_by", "created_at", (
        <Select>
          <Option value="competency_at">Competência</Option>
          <Option value="due_at">Vencimento</Option>
          <Option value="created_at">Cadastro</Option>
        </Select>
      ))}
      {renderPeriodFormItem()}
      {renderValueFormItem()}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrar
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapDispatchProps = (dispatch) => ({
  loadDocumentsByFilter: (filter) => dispatch(documentsActions.loadDocumentsByFilter(filter)),
});

export default connect(null, mapDispatchProps)(Form.create()(AdvancedFilter));
