import React from 'react';
import { Tag, Tooltip, Dropdown, Menu, Icon, Popover } from 'antd';
import Moment from 'react-moment';
import { compare } from 'natural-orderby';
import { get } from 'lodash';
import moment from 'moment';
import { toBRL } from '../UI/InputCurrency';
import { deliveryMethod } from '~containers/Customer/data';
import { displaySocCategory } from './helpers';

export const getCustomersTableColumns = ({
  actived,
  styleCompanyName,
  onAction,
  canEditCustomers,
  canDeleteCustomers,
  findUser,
  filterCompanyType,
}) => [
  {
    title: "Cod",
    dataIndex: "cod",
    key: "cod",
    fixed: "left",
    width: 100,
    sorter: (a, b) => compare()(a.cod, b.cod),
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'ascend',
  },
  {
    title: "Empresa",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 500,
    sorter: (a, b) => a.name && a.name.localeCompare(b.name),
    render: (text, record) => <span style={styleCompanyName(record)}>{text}</span>,
  },
  {
    width: 200,
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email && a.email.localeCompare(b.email),
    ellipsis: true,
  },
  {
    title: "CNPJ",
    dataIndex: "cnpj_formated",
    key: "cnpj_formated",
    width: 200,
    sorter: (a, b) => a.identification_number && (a.identification_number - b.identification_number),
    ellipsis: true,
  },
  {
    title: "Telefone",
    dataIndex: "phone_formated",
    key: "phone_formated",
    width: 200,
    sorter: (a, b) => a.phone && (a.phone - b.phone),
    ellipsis: true,
  },
  {
    title: "Regime Tributário",
    dataIndex: "type_taxation",
    key: "type_taxation",
    width: 200,
    sorter: (a, b) => a.type_taxation && a.type_taxation.localeCompare(b.type_taxation),
    filters: [
      { text: 'Doméstica', value: 'Doméstica' },
      { text: 'Lucro Presumido', value: 'Lucro Presumido' },
      { text: 'Lucro Real', value: 'Lucro Real' },
      { text: 'MEI', value: 'MEI' },
      { text: 'Profissional Liberal', value: 'Profissional Liberal' },
      { text: 'Simples Nacional', value: 'Simples Nacional' },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.type_taxation && record.type_taxation.indexOf(value) === 0,
  },
  {
    title: "Atividade",
    dataIndex: "company_type",
    key: "company_type",
    width: 200,
    sorter: (a, b) => a.company_type && a.company_type.localeCompare(b.company_type),
    filters: filterCompanyType,
    filterMultiple: false,
    onFilter: (value, record) => record.company_type_id === value,
  },
  {
    title: "Aquisição",
    dataIndex: "acquisition",
    key: "acquisition",
    width: 200,
    sorter: (a, b) => a.acquisition && a.acquisition.localeCompare(b.acquisition),
    filters: [
      { text: 'Nenhum', value: 'Nenhum' },
      { text: 'Indicação', value: 'Indicação' },
      { text: 'Google', value: 'Google' },
      { text: 'Facebook', value: 'Facebook' },
      { text: 'Site', value: 'Site' },
      { text: 'Outros', value: 'Outros' },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.acquisition && record.acquisition.indexOf(value) === 0,
  },
  {
    title: "Aquisição (Obs)",
    dataIndex: "indication",
    key: "indication",
    width: 200,
    sorter: (a, b) => a.indication && a.indication.localeCompare(b.indication),
  },
  {
    title: "Societário",
    dataIndex: "soc_category",
    key: "soc_category",
    width: 200,
    render: value => displaySocCategory(value),
    ellipsis: true,
  },
  {
    ellipsis: true,
    title: "Sistema de entrega",
    dataIndex: "doc_delivery_method",
    key: "doc_delivery_method",
    width: 200,
    sorter: (a, b) => {
      const aDeliveryMethodText = get(deliveryMethod, `[${a.doc_delivery_method}].text`);
      const bDeliveryMethodText = get(deliveryMethod, `[${b.doc_delivery_method}].text`);
      if (!aDeliveryMethodText) return -1;
      return aDeliveryMethodText.localeCompare(bDeliveryMethodText);
    },
    filters: deliveryMethod,
    onFilter: (value, record) => record.doc_delivery_method === value,
    render: value => value !== null && <center><Tag color={get(deliveryMethod, `[${value}].color`)}>{get(deliveryMethod, `[${value}].text`)}</Tag></center>,
  },
  {
    ellipsis: true,
    title: "Último acesso",
    dataIndex: "most_recent_app_user",
    key: "most_recent_app_user",
    width: 170,
    align: "center",
    sorter: (a, b) => {
      const aLastAccessAt = get(a, 'most_recent_app_user.last_access_at');
      const bLastAccessAt = get(b, 'most_recent_app_user.last_access_at');

      return (
        !aLastAccessAt - !bLastAccessAt ||
        +(
          new Date(bLastAccessAt).getTime() >
          new Date(aLastAccessAt).getTime()
        ) ||
        -(
          new Date(bLastAccessAt).getTime() <
          new Date(aLastAccessAt).getTime()
        )
      );
    },
    render: (mostRecentAppUser, record) => {
      const { doc_delivery_method } = record;

      if (doc_delivery_method !== 2) {
        return null;
      }

      if (!mostRecentAppUser) {
        return "Não possui acesso";
      }

      if (!mostRecentAppUser.last_access_at) {
        return "Pendente";
      }

      return (
        <center>
          <Tooltip title={`${mostRecentAppUser.name} - ${moment(mostRecentAppUser.last_access_at).format("DD/MM/YYYY LTS")}`}>
            {moment(mostRecentAppUser.last_access_at).fromNow()}
          </Tooltip>
        </center>
      )
    },
  },
  {
    title: "CEP",
    dataIndex: "postal_code_formated",
    key: "postal_code_formated",
    width: 200,
    sorter: (a, b) => a.postal_code && (a.postal_code - b.postal_code),
    ellipsis: true,
  },
  {
    title: "Rua",
    dataIndex: "street",
    key: "street",
    width: 400,
    sorter: (a, b) => a.street && a.street.localeCompare(b.street),
    ellipsis: true,
  },
  {
    title: "Nº",
    dataIndex: "street_number",
    key: "street_number",
    width: 200,
    sorter: (a, b) => a.street_number && (a.street_number - b.street_number),
    ellipsis: true,
  },
  {
    title: "Bairro",
    dataIndex: "neighborhood",
    key: "neighborhood",
    width: 200,
    sorter: (a, b) => a.neighborhood && a.neighborhood.localeCompare(b.neighborhood),
    ellipsis: true,
  },
  {
    title: "Complemento",
    dataIndex: "complement",
    key: "complement",
    width: 200,
    sorter: (a, b) => a.complement && a.complement.localeCompare(b.complement),
    ellipsis: true,
  },
  {
    title: "Cidade",
    dataIndex: "city",
    key: "city",
    width: 200,
    sorter: (a, b) => a.city && a.city.localeCompare(b.city),
    ellipsis: true,
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
    width: 200,
    sorter: (a, b) => a.state && a.state.localeCompare(b.state),
    ellipsis: true,
  },
  {
    title: "Faturamento",
    dataIndex: "billing",
    key: "billing",
    width: 200,
    render: value => toBRL(value),
    sorter: (a, b) => a.billing && (a.billing - b.billing),
    ellipsis: true,
  },
  {
    title: "NFs Entrada",
    dataIndex: "nfs_input",
    key: "nfs_input",
    width: 200,
    sorter: (a, b) => a.nfs_input && (a.nfs_input - b.nfs_input),
    ellipsis: true,
  },
  {
    title: "NFs Saida",
    dataIndex: "nfs_output",
    key: "nfs_output",
    width: 200,
    sorter: (a, b) => a.nfs_output && (a.nfs_output - b.nfs_output),
    ellipsis: true,
  },
  {
    title: "Lanç. Contábeis",
    dataIndex: "accounting_postings",
    key: "accounting_postings",
    width: 200,
    sorter: (a, b) => a.accounting_postings && (a.accounting_postings - b.accounting_postings),
    ellipsis: true,
  },
  {
    title: "Conciliação",
    dataIndex: "conciliation",
    key: "conciliation",
    width: 200,
    render: (text, record, index) => record.conciliation ? 'Sim' : '',
    sorter: (a, b) => a.conciliation && a.conciliation.localeCompare(b.conciliation),
    ellipsis: true,
  },
  {
    title: "Funcionários",
    dataIndex: "n_staffs",
    key: "n_staffs",
    width: 200,
    sorter: (a, b) => a.n_staffs && (a.n_staffs - b.n_staffs),
    ellipsis: true,
  },
  {
    title: "Tipo Funcionários",
    dataIndex: "type_staff",
    key: "type_staff",
    width: 200,
    sorter: (a, b) => a.type_staff && a.type_staff.localeCompare(b.type_staff),
    filters: [
      { text: 'Honorista', value: 'Honorista' },
      { text: 'Mensalista', value: 'Mensalista' },
      { text: 'Comissionista', value: 'Comissionista' },
      { text: 'Comissionista Puro', value: 'Comissionista Puro' },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.type_staff && record.type_staff.indexOf(value) === 0,
    ellipsis: true,
  },
  {
    title: "Honorário de Abertura",
    dataIndex: "first_honorary",
    key: "first_honorary",
    width: 200,
    render: value => toBRL(value),
    sorter: (a, b) => a.first_honorary && (a.first_honorary - b.first_honorary),
    ellipsis: true,
  },
  {
    title: "Mensalidade",
    dataIndex: "honorary",
    key: "honorary",
    width: 200,
    render: value => toBRL(value),
    sorter: (a, b) => a.honorary && (a.honorary - b.honorary),
    ellipsis: true,
  },
  {
    ellipsis: true,
    title: "Dt. Entrada",
    dataIndex: "registered_at",
    key: "registered_at",
    width: 200,
    sorter: (a, b) => a.registered_at && a.registered_at.localeCompare(b.registered_at),
    render: key => key && (
      <Moment format="DD/MM/YYYY">
        {key}
      </Moment>
    ),
  },
  !actived && {
    ellipsis: true,
    title: "Inativado Em",
    dataIndex: "inactived_at",
    key: "inactived_at",
    width: 200,
    sorter: (a, b) => a.inactived_at && a.inactived_at.localeCompare(b.inactived_at),
    render: (text, record, index) => record.inactived_at && (
      <Tooltip
        placement="bottom"
        title={
          record.inactived_by ?
            `Inativado por ${findUser(record.inactived_by).name}` :
            'Inativado automaticamente'
        }>
        <Moment format="DD/MM/YYYY">
          {record.inactived_at}
        </Moment>
      </Tooltip>
    ),
  },
  {
    dataIndex: "actions",
    key: "actions",
    fixed: "right",
    width: 150,
    render: (text, record, index) => (
      <center key={index}>
        <Dropdown
          overlayStyle={{ zIndex: 999 }}
          overlay={
            <Menu onClick={(e) => onAction(e, record)}>
              <Menu.Item key="edit" disabled={!canEditCustomers}>
                <Icon type="edit"></Icon> Editar
              </Menu.Item>
              {record.inactived_at !== null && (
                <Menu.Item key="activate" disabled={!canEditCustomers || false}>
                  <Icon type="check"></Icon> Ativar
                </Menu.Item>
              )}
              <Menu.Divider />
              {record.inactived_at === null && !record.enabled && (
                <Menu.Item key="force_activate" disabled={!canEditCustomers || false}>
                  <Icon type="check"></Icon> Forçar Ativação
                </Menu.Item>
              )}
              {record.inactived_at === null && (
                <Menu.Item key="inactivate" disabled={!canEditCustomers || false}>
                  <Icon type="delete"></Icon> Inativar
                </Menu.Item>
              )}
              {record.inactived_at !== null && (
                <Menu.Item key="delete" disabled={!canDeleteCustomers || false}>
                  <Icon type="delete"></Icon> Excluir
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <Popover
            placement="left"
            content="Clique aqui e edite este cliente"
            visible={false}
            overlayStyle={{ zIndex: 1051 }}
          >
            <a className="ant-dropdown-link" href="#">
              Ações <Icon type="down" />
            </a>
          </Popover>
        </Dropdown>
      </center>
    ),
  },
].filter(Boolean);
