import React from 'react';
import { Progress } from 'antd';

const StartHereGraphic = ({ progress }) => (
    <div style={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: '10px', top: '8px' }}>
        <Progress
            type="circle"
            percent={progress}
            width={25}
            strokeWidth={15}
            strokeColor="#4CAF50" 
            trailColor="#D3D3D3"
            format={() => <span style={{ color: 'white' }}></span>}
        />
    </div>
);

export default StartHereGraphic;
