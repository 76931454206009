import { Modal, Icon } from "antd";
import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../AppContext";

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(61, 25, 115, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 10px 10px rgba(61, 25, 115, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(61, 25, 115, 0);
  }
`;

const StyledBtn = styled.a`
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #fff;
  padding: 15px;
  width: 100%;
  font-size: 18px;
  background-color: #3d1973;
  border-radius: 4px;
  animation: ${pulse} 1.5s infinite;

  &:hover {
    background-color: #3d1973;
    color: #fff;
  }
`;

const StyledModalTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

export const VideoModal = ({
  title,
  isVisible,
  url,
  onClose,
  route,
  id,
}) => {
  const history = useHistory();
  const { onboardingData, setOnboardingTaskAsClicked } = useAppContext();

  useEffect(() => {
    if (isVisible && route) {
      setOnboardingTaskAsClicked(id);
    }
  }, [isVisible, route, id]);

  const handleRouteClick = (e) => {
    e.preventDefault();
    if (route) {
      history.push(route);
    }
  };

  return (
    <Modal
      title={<StyledModalTitle>{title}</StyledModalTitle>}
      visible={isVisible}
      onCancel={onClose}
      width={816}
      footer={false}
    >
      {url && (
        <iframe
          title={title}
          src={url}
          width="768"
          height="480"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      )}
      {route && (
        <StyledBtn href={route} onClick={handleRouteClick}>
          <Icon type="link" /> Atalho para a página mencionada
        </StyledBtn>
      )}
    </Modal>
  );
};
