import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment';
import FileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';

import { ButtonsGroup } from './styles';
import { limitCharacterLength } from '~/utils/formatters';
import { request } from '~/components/Instance';
import * as documentsActions from '~/containers/Documents/actions';
import { getWhatsappFileMessage } from '~/_domains/calendarTasks/helpers';

export const DeliveryMethodsButtonsModal = ({
  isVisible,
  onClose,
  document,
}) => {
  const dispatch = useDispatch();

  const [isDocumentSent, setIsDocumentSent] = useState(false);
  const [loadingState, setLoadingState] = useState({
    panel: false,
    protocol: false,
    whatsapp: false,
    email: false,
  });

  const title = document
    ? `Métodos de entrega - ${limitCharacterLength(document.description, 27)}`
    : '';

  function handleDocumentSent() {
    if (!isDocumentSent) {
      setIsDocumentSent(true);
    }
  }

  function handleLoadingState(deliveryMethod, value) {
    setLoadingState({
      ...loadingState,
      [deliveryMethod]: value,
    });
  }

  function handleCloseModal() {
    onClose({ isDocumentSent });
    if (isDocumentSent) {
      setIsDocumentSent(false);
    }
  }

  async function generateProtocol() {
    try {
      handleLoadingState('protocol', true);
      const { data } = await request(
        'POST',
        `documents/${document.id}/generate-protocol`,
        null,
        true,
        { responseType: 'blob' }
      );
      // TODO: Encontrar uma maneira de retornar o ID do protocolo
      FileDownload(
        data,
        `Protocolo_${moment().format('YYYY-MM-DD_hh-mm-ss')}.pdf`
      );
      handleDocumentSent();
    } catch (error) {
      console.log(error);
    } finally {
      handleLoadingState('protocol', false);
    }
  }

  async function sendToWhatsapp() {
    try {
      handleLoadingState('whatsapp', true);
      const {
        payload: { data: file },
      } = await dispatch(
        documentsActions.sendToWhatsapp({
          customerFileId: document.id,
        })
      );

      const whatsappMessage = `Prezado cliente, ${
        file.customer.name
      }.%0aEncontra-se disponível a obrigação ${
        file.description
      } com os seguintes dados:${getWhatsappFileMessage(file)}`;

      const whatsappUrl = `https://api.whatsapp.com/send?phone=55${file.customer.phone}&text=${whatsappMessage}`;

      window.open(whatsappUrl, '_blank').focus();
      handleDocumentSent();
    } catch (error) {
      console.log(error);
    } finally {
      handleLoadingState('whatsapp', false);
    }
  }

  async function sendEmailWithLink() {
    try {
      handleLoadingState('panel', true);
      await dispatch(
        documentsActions.sendEmailWithLink({
          customerFileId: document.id,
        })
      );
      handleDocumentSent();
    } catch (error) {
      console.log(error);
    } finally {
      handleLoadingState('panel', false);
    }
  }

  async function sendEmailWithAttachment() {
    try {
      handleLoadingState('email', true);
      await dispatch(
        documentsActions.sendEmailWithAttachment({
          customerFileId: document.id,
        })
      );
      handleDocumentSent();
    } catch (error) {
      console.log(error);
    } finally {
      handleLoadingState('email', false);
    }
  }

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={handleCloseModal}
      footer={false}
      maskClosable={false}
    >
      <ButtonsGroup>
        <Button loading={loadingState.panel} onClick={sendEmailWithLink}>
          Painel
        </Button>
        <Button loading={loadingState.protocol} onClick={generateProtocol}>
          Protocolo
        </Button>
        <Button loading={loadingState.whatsapp} onClick={sendToWhatsapp}>
          Whatsapp
        </Button>
        <Button loading={loadingState.email} onClick={sendEmailWithAttachment}>
          E-mail
        </Button>
      </ButtonsGroup>
    </Modal>
  );
};
