import React from 'react';
import { Col, Card, Icon, Menu, Dropdown, Tooltip } from 'antd';
import moment from 'moment';
import { CustomProgress, CustomProgressWrapper } from '../TasksInList/styles';
import { gridStyle1, gridStyle2, strong, TaskCard } from '../styles';
import { frequency } from '~/components/RegisterTasks/Utils';
import { FaCalendarAlt, FaUsers, FaClipboardList, FaCheckCircle, FaSync, FaClock, 
         FaBars, FaTable, FaUpload, FaChevronDown } from 'react-icons/fa';

const Task = ({ data, openGrid, openList, openTasksInBatchDrawer }) => {
    const getTaskCardStyle = () => {
        const isDone = data.pendent_tasks_count === 0;
        const from = moment(data.due_date);
        const to = moment();

        if (isDone) {
            return { backgroundColor: "#52c41a", color: "#ffffff" };
        } else if (to.diff(from, "days") > 0) {
            return { backgroundColor: "#f5222d", color: "#ffffff" };
        } else {
            return { backgroundColor: "#fadb14", color: "#000000" };
        }
    };

    const { backgroundColor, color } = getTaskCardStyle();
    const executedTasksPercentage = data.tasks_count > 0
        ? Math.round((100 * data.executed_tasks_count) / data.tasks_count, 2)
        : 0;
    const isExpired = moment().diff(data.due_date, "days") > 0;

    const iconStyle = { 
        marginRight: '8px',
        color: '#8c8c8c'
    };

    return (
        <Col span={6}>
            <TaskCard
                bgColor={backgroundColor}
                color={color}
                type="inner"
                title={
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item onClick={openList}>
                                    <FaBars style={{ marginRight: '8px' }} /> Visualizar tarefas em modo lista
                                </Menu.Item>
                                <Menu.Item onClick={openGrid}>
                                    <FaTable style={{ marginRight: '8px' }} /> Visualizar tarefas em modo tabela
                                </Menu.Item>
                                {data.file_upload && (
                                    <Menu.Item onClick={() => openTasksInBatchDrawer({ parentTask: data })}>
                                        <FaUpload style={{ marginRight: '8px' }} /> Executar tarefas em lote
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <div style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}>
                            <span>{data.name}</span>
                            <FaChevronDown />
                        </div>
                    </Dropdown>
                }
                extra={
                    data.priority_tasks_count > 0 && (
                        <Tooltip placement="bottom" title={`${data.priority_tasks_count} prioridades`}>
                            <Icon type="star" theme="filled" />
                        </Tooltip>
                    )
                }
            >
                <Card.Grid style={{ width: '100%', padding: 8 }}>
                    <CustomProgressWrapper>
                        <Tooltip title="Concluídos">
                            <span>{data.executed_tasks_count}</span>
                        </Tooltip>
                        <CustomProgress 
                            percent={executedTasksPercentage} 
                            expired={isExpired ? "true" : "false"} 
                            status={data.pendent_tasks_count === 0 ? "success" : "normal"}
                        />
                        <Tooltip title="Pendentes">
                            <span>{data.pendent_tasks_count}</span>
                        </Tooltip>
                    </CustomProgressWrapper>
                </Card.Grid>
                <Card.Grid style={{ ...gridStyle1, ...strong }}>
                    <FaCalendarAlt style={iconStyle} /> Competência
                </Card.Grid>
                <Card.Grid style={{ ...gridStyle2, ...strong }}>{data.competence_date}</Card.Grid>
                <Card.Grid style={gridStyle1}>
                    <FaUsers style={iconStyle} /> Clientes
                </Card.Grid>
                <Card.Grid style={gridStyle2}>{data.tasks_count}</Card.Grid>
                <Card.Grid style={gridStyle1}>
                    <FaClipboardList style={iconStyle} /> Tarefas Pendentes
                </Card.Grid>
                <Card.Grid style={gridStyle2}>{data.pendent_tasks_count}</Card.Grid>
                <Card.Grid style={gridStyle1}>
                    <FaCheckCircle style={iconStyle} /> Tarefas Concluidas
                </Card.Grid>
                <Card.Grid style={gridStyle2}>{data.executed_tasks_count}</Card.Grid>
                <Card.Grid style={gridStyle1}>
                    <FaSync style={iconStyle} /> Periodicionade
                </Card.Grid>
                <Card.Grid style={gridStyle2}>{frequency(data.frequency)}</Card.Grid>
                <Card.Grid style={gridStyle1}>
                    <FaClock style={iconStyle} /> Vencimentos
                </Card.Grid>
                <Card.Grid style={gridStyle2}>{data.due_date.substring(8)}</Card.Grid>
            </TaskCard>
        </Col>
    );
};

export default Task;
