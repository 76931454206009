import React from 'react';
import { Row, Col, Card } from 'antd';

import { Background, InvitedCard, Image } from './Styled';
import InvitedForm from './InvitedForm';

const ScreensInvited = (props) => {
  const { tenant_id, invitation } = props.match.params;
  return (
    <Background>
      <Row type="flex" justify="center" className="pt-80">
        <Col span={14}>
          <InvitedCard>
            <Row type="flex" justify="center">
              <Image span={10} />
              <Col span={14}>
                <Card style={{paddingLeft: `20px`, paddingRight: `20px`}}>
                  <InvitedForm tenant_id={tenant_id} invitation={invitation} />
                </Card>
              </Col>
            </Row>
          </InvitedCard>
          <center><p>Contlabs.com.br</p></center>
        </Col>
      </Row>
    </Background>
  );
}

export default ScreensInvited;