import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button, TextField, ThemeProvider, Snackbar } from '@material-ui/core';
import styled from 'styled-components';

import logo from './logo.png';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import { materialUITheme } from '~/themes/material-ui.theme';
import { MaterialUIFormControl } from '~/components/UI/Material/FormControl';
import { requestPasswordReset, resetPassword } from './api';
import { checkEmail } from '~/_domains/login/api';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 30px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const Logo = styled.img`
    width: 200px;
    margin-bottom: 20px;
`;

const ForgotPasswordLink = styled(Link)`
    margin-top: 20px;
`;

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [emailError, setEmailError] = useState('');
    const { token } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (token) {
            const [tenantId, actualToken] = token.split(':');
            setSelectedTenant(tenantId);
            setEmail('');
        }
    }, [token]);

    const handleRequestReset = async (event) => {
        event.preventDefault();
        if (!email) {
            setEmailError('Por favor, preencha o email.');
            return;
        }
        setLoading(true);
        try {
            const users = await checkEmail(email);
            console.log('users', users);
            if (users.length === 0) {
                setEmailError('Nenhum tenant encontrado para este email.');
            } else if (users.length === 1) {
                await requestPasswordReset(email, users[0].tenant_id);
                setSuccessMessage('Um link para redefinir sua senha foi enviado para o seu email.');
            } else {
                setTenants(users);
            }
        } catch (error) {
            setErrorMessage(DEFAULT_MESSAGES.REQUEST_PASSWORD_RESET_ERROR);
        } finally {
            setLoading(false);
        }
    };

    const handleTenantSelection = async (event) => {
        event.preventDefault();
        if (!selectedTenant) {
            setErrorMessage('Por favor, selecione um tenant.');
            return;
        }
        setLoading(true);
        try {
            await requestPasswordReset(email, selectedTenant);
            setSuccessMessage('Um link para redefinir sua senha foi enviado para o seu email.');
            setTenants([]);
        } catch (error) {
            setErrorMessage(DEFAULT_MESSAGES.REQUEST_PASSWORD_RESET_ERROR);
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        if (!password || !confirmPassword) {
            setErrorMessage('Por favor, preencha todos os campos.');
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage('As senhas não coincidem.');
            return;
        }
        setLoading(true);
        try {
            await resetPassword(token.split(':')[1], password, selectedTenant);
            setSuccessMessage('Sua senha foi redefinida com sucesso.');
            history.push('/login?m=Senha alterada com sucesso!');
        } catch (error) {
            setErrorMessage(error.message || DEFAULT_MESSAGES.RESET_PASSWORD_ERROR);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setErrorMessage('');
        setSuccessMessage('');
    };

    return (
        <ThemeProvider theme={materialUITheme}>
            <Container>
                <Logo src={logo} alt="Logo" />
                <Form onSubmit={token ? handleResetPassword : (tenants.length > 1 ? handleTenantSelection : handleRequestReset)}>
                    {!token && tenants.length === 0 && (
                        <MaterialUIFormControl>
                            <TextField
                                label="Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError('');
                                }}
                                fullWidth
                                error={!!emailError}
                                helperText={emailError}
                            />
                        </MaterialUIFormControl>
                    )}
                    {!token && tenants.length > 1 && (
                        <MaterialUIFormControl>
                            <TextField
                                select
                                label="Selecione o Tenant"
                                value={selectedTenant}
                                onChange={(e) => setSelectedTenant(e.target.value)}
                                fullWidth
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="" />
                                {tenants.map((tenant) => (
                                    <option key={tenant.tenant_id} value={tenant.tenant_id}>
                                        {tenant.tenant_name}
                                    </option>
                                ))}
                            </TextField>
                        </MaterialUIFormControl>
                    )}
                    {token && (
                        <>
                            <MaterialUIFormControl>
                                <TextField
                                    label="Nova Senha"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    fullWidth
                                />
                            </MaterialUIFormControl>
                            <MaterialUIFormControl>
                                <TextField
                                    label="Confirmar Nova Senha"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    fullWidth
                                />
                            </MaterialUIFormControl>
                        </>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                    >
                        {loading ? 'Carregando...' : (token ? 'Redefinir Senha' : 'Recuperar Senha')}
                    </Button>
                </Form>
                <ForgotPasswordLink to="/login">Voltar para Login</ForgotPasswordLink>
                <Snackbar
                    open={!!errorMessage || !!successMessage}
                    message={errorMessage || successMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
            </Container>
        </ThemeProvider>
    );
};

export default ForgotPasswordPage;
